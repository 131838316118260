import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { Select2Module } from 'ng2-select2';
import { EffectsModule } from '@ngrx/effects';
import { NgxSelectModule, INgxSelectOptions } from 'ngx-select-ex';
import { NgxMaskModule } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { serviceRequestReducer } from './store/reducers/service-request.reducer';
import { ServiceRequestEffects } from './store/effects/service-request.effect';

import { AddressSelectorComponent } from './components/address-selector/address-selector.component';
import { EquipmentsFormComponent } from './components/equipments/form/equipments.form.component';
import { EquipmentsComponent } from './components/equipments/equipments.component';
import { BookingComponent } from './components/booking/booking.component';
import { AgmCoreModule } from '@agm/core';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { SearchComponent } from './components/search/search.component';
import { DataTablesModule } from 'angular-datatables';
import { CustomModeBookingComponent } from './components/custom-mode-booking/custom-mode-booking.component';

const CustomSelectOptions: INgxSelectOptions = {
  optionValueField: 'id',
  optionTextField: 'text'
};

@NgModule({
  declarations: [
    BookingComponent,
    SearchComponent,
    AddressSelectorComponent,
    EquipmentsComponent,
    EquipmentsFormComponent,
    CustomModeBookingComponent,
  ],
  entryComponents: [
    SearchComponent,
    EquipmentsComponent,
    EquipmentsFormComponent,
    AddressSelectorComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    Select2Module,
    FormsModule,
    DataTablesModule,
    ReactiveFormsModule,
    NgxSelectModule.forRoot(CustomSelectOptions),
    NgxMaskModule.forChild(),
    StoreModule.forFeature('serviceRequest', serviceRequestReducer),
    EffectsModule.forFeature([ServiceRequestEffects]),
    AgmCoreModule.forRoot({ apiKey: 'AIzaSyCV9jnDSFNYrVPOdqVukVUU3WpALVBTTx8', libraries: ['places'] }),
  ]
})
export class ServiceRequestModule { }