import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import serviceConfig from './service-config';
import { Observable } from 'rxjs';
import { selectAuthState } from '../core/auth/_selectors/auth.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '../core/reducers';

@Injectable({
  providedIn: 'root'
})
export class GenericRequestsService {

  uri = serviceConfig.baseUrl;
  headers: HttpHeaders = new HttpHeaders()
  alturi: any = serviceConfig.altBaseUrl;


  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.store.select(selectAuthState).subscribe((result) => {
      this.headers = new HttpHeaders()
        .append('Authorization', "Bearer " + result.authToken)
        .append('Content-Type', 'application/json')
        .append('accept', '*/*')
        .append("Access-Control-Allow-Origin", "*")
        .append("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept")
        .append('accept', 'text/plain');
    })
  }


  getOptions(endPoint): any {
    let url = this.uri + endPoint;
    return this.http.get(url, { headers: this.headers });
  }

  getList(endPoint): any {
    let url = this.alturi + endPoint;
    return this.http.get(url, { headers: this.headers });
  }
}
