import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpErrorResponse  } from "@angular/common/http";
import {VoucherModel} from '../../models/voucher'
import serviceConfig from '../service-config';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAuthState } from '../../core/auth/_selectors/auth.selectors';
import { AppState } from '../../core/reducers/index';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class VoucherService {

  uri = serviceConfig.baseUrl + serviceConfig.endPoints.voucher;
  headers = new HttpHeaders()
  altHeaders: HttpHeaders;
  userId;
  clientName;
  isInternal:boolean = false
  customerId:'';

  constructor(private http:HttpClient, private store: Store<AppState>) { 
    this.store.select(selectAuthState).subscribe((result) => {  
      this.headers = new HttpHeaders().set('Authorization', "Bearer "+result.authToken);
      
      var decoded = jwt_decode(result.authToken); 
      var currentRoles= decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      var internal=currentRoles.indexOf("Client");
      if(internal == -1) this.isInternal = true;
      //real userid.
      this.userId=decoded['https://mardom.com/clientId'];
      var decodediD = jwt_decode(result.idToken); 
      
      this.clientName=decodediD['name'];
      this.customerId=decodediD['sub'];

      this.altHeaders =new HttpHeaders()
                    .append('Authorization', "Bearer "+result.authToken)  
                    .append('Content-Type', 'application/json')
                    .append('accept','*/*')
                    .append('Allow', '*')
                    .append('Access-Control-Allow-Origin', '*')
                    .append('Access-Control-Allow-Methods', 'POST, GET, PUT, DELETE, OPTIONS')
                    .append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept, Referer, User-Agent')
                    .append('accept','text/plain');
                    
    });
  }

  addVoucher(voucherModel:VoucherModel){
    voucherModel.user_id=this.userId;
    voucherModel.client_name=this.clientName;
    voucherModel.customer_id=this.customerId;

    return this.http.post(`${this.uri}`, voucherModel, { headers: this.altHeaders });
  }

  updateVoucher(id:number ,voucherModel:VoucherModel){
    this.http.put(`${this.uri}/${id}`, voucherModel, { headers: this.altHeaders }).subscribe(res => console.log('Done'));
  }
  getVoucher():Observable<any> {
    let url = `${this.uri}?userId=${this.userId}`;
    if(this.isInternal){
      url +=  `&isAdmin=true`
    }
    return this
      .http
      .get<any>(url,
       { headers: this.altHeaders });

  }
  getVoucherByDate(from:string,to:string,status:string,billId:string,reference:string,isAdmin:boolean):Observable<any> {
    var customer='';

    if(!isAdmin){
      customer=this.userId;
    }
    else{customer='0';}

    var url=serviceConfig.baseUrl+ serviceConfig.endPoints.BillVoucher;
    return this
      .http
      .get<any>(`${url}?from=${from}&to=${to} 23:59:59&status=${status}&billId=${billId}&userId=${customer}&reference=${reference}`,
       { headers: this.altHeaders });
  }  
}
