export class TransportModel{

    id: number;
    transport_type: number;
    pickup_date: string;
    return_date: string;
    equipment_type: string;
    reference_no: string;
    dimensions: string;
    origen: string;
    origen_coordinates: string;
    destiny: string;
    destiny_coordinates: string;
    require_stop: boolean;
    quantity: number;
    delivery_time: string;
    cargo_weight: number;
    comment: string;
    contact_name: string;
    contact_phone: string;
    contact_email: string;
    user_id: string;
    created_date: string;
    status: number;
    assigned_user: string;
    mardom_company_id: number;
    request_service: number;}

  