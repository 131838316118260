import { Injectable } from '@angular/core';
import { Country } from '../../models/Country'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import serviceConfig from '../service-config';
import { IndustryType } from '../../models/IndustryType'
import { PersonTitle } from '../../models/personTitle'

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  uri = serviceConfig.altBaseUrl;
  uriLogisticW = serviceConfig.logisticWarehouseBaseUrl;
  constructor(private http: HttpClient) {}

  getCountries(): Observable<Country[]> {
    return this.http.get<Country[]>(
      this.uri + serviceConfig.endPoints.country
    )
  }

  verifyRNC(no): Observable<Country[]> {
    return this.http.get<Country[]>(
      'https://api.digital.gob.do/v1/contributors/' + no + '/info/basic'
    )
  }

  sendEmail(email) {
    //const  url = this.uri + '/logistic-warehouse/Home/SendAuthEmail?emailId=' + email //FOR PROD ENV
    const url = `${this.uriLogisticW}/Home/SendAuthEmail?emailId=${email}` //FOR UAT ENV

    return this.http.get<any[]>(url)
  }

  sendSuccessfullRegistrationEmail(payload) {
    // const url = this.uri + '/logistic-warehouse/Home/SendSuccessfullRegistrationEmail?email='+payload.email +'&username=' + payload.name
    // +'&emailAccount=' + payload.emailAccount+'&companyName=' + payload.companyName; //FOR PROD ENV

    const url =`${this.uriLogisticW}/Home/SendSuccessfullRegistrationEmail?email=${payload.email}&username=${payload.name}
    &emailAccount=${payload.emailAccount}&companyName=${payload.companyName}`; //FOR UAT ENV

    return this.http.get<any[]>(url)
  }

  checkEmailExit(email) {
    //const url = this.uri + '/logistic-warehouse/Home/ValidateEmail?EmailId=' + email; //FOR PROD ENV
    const url =  `${this.uriLogisticW}/Home/ValidateEmail?EmailId=${email}`; //FOR UAT ENV

    return this.http.get<any[]>(url)
  }

  //**********Country* */
  getIndustryTypes(): Observable<IndustryType[]> {
    return this.http.get<IndustryType[]>(
      this.uri + serviceConfig.endPoints.industryType
    )
  }

  //**person */
  getPersonTitle(): Observable<PersonTitle[]> {
    return this.http.get<PersonTitle[]>(
      this.uri + serviceConfig.endPoints.personTitle
    )
  }

  loginApi(email,pass){
    return this.http.get<any>(
      this.uri + serviceConfig.endPoints.loginurl +'?username='+email+'&password='+pass
    )
  }

}
