import { Action } from '@ngrx/store';
import { User } from '../_models/user.model';

export enum AuthActionTypes {
  BeginLogin = '[Begin Login] Action',
  Login = '[Login] Action',
  Logout = '[Logout] Action',
  Register = '[Register] Action',
  RefreshTokens = '[Refresh Tokens] Action',
  UserRequested = '[Request User] Action',
  UserLoaded = '[Load User] Auth API'
}

export class Login implements Action {
  readonly type = AuthActionTypes.Login;
  constructor(public payload: { accessToken: string; idToken: string }) {}
}

export class ChangeAccessToken implements Action {
  readonly type = AuthActionTypes.Login;
  constructor(public payload: { accessToken: string }) {}
}

export class Logout implements Action {
  readonly type = AuthActionTypes.Logout;
}

export class Register implements Action {
  readonly type = AuthActionTypes.Register;
  constructor(public payload: { authToken: string }) {}
}

export class UserRequested implements Action {
  readonly type = AuthActionTypes.UserRequested;
}

export class UserLoaded implements Action {
  readonly type = AuthActionTypes.UserLoaded;
  constructor(public payload: { user: User }) {}
}

export class BeginLogin implements Action {
  readonly type = AuthActionTypes.BeginLogin;
  constructor(public payload: any) {}
}
export class RefreshTokens implements Action {
  readonly type = AuthActionTypes.RefreshTokens;
  constructor(public payload: any) {}
}

export type AuthActions =
  | Login
  | BeginLogin
  | Logout
  | Register
  | UserRequested
  | UserLoaded
  | RefreshTokens;
