import { OrderDetailModel } from './orderdetail';

export class OrderModel{
    //MardomGoWeb
    id:number;
    numero_pedido :string;///askednumber
    expedition_type:string;
    release_from :string;
    release_up :string;
    delivery_date :string;
    date_issued :string;
    user_id:string;
    created_date:string;
    assigned_user:string;
    Status:string;
    regimen:string;
    memo: string;
    detail: OrderDetailModel[];

    //wis fields
    ReleaseFrom:string;
    ReleaseUp:string;
    Delivery:string;
    Regime:string;
    AskedNumber:string;
    CompanyCode:string;
    AgentCode:string;
    ReferenceNumber:string;
    Commentary:string;
    Transformation:string;
    Products:OrderDetailModel[];
  
}