export class ChangeStatusModel {
  requestId: number;
  orderId: number;
  referenceId: number;
  billId: number;
  message: string;
  serviceType: string;
  estatusId: number;
  userId: string;
  resposibleName: string;
  changedUser: string;
  changedUserName: string;
}
