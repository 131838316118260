import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GenericRequestsService } from '../../../../../services/generic-requests.service';
import { ITransportEquipment } from '../../../../../models/transport-equipment';
import { IEquipment } from '../../../../../models/transport-request/equipment';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
import { getTMSEquipments, getTMSEquipmentTypes, getTMSFreightLocations } from '../../../store/selectors/service-request.selector';
import * as TransportRequestActions from '../../../store/actions/transport-request.actions';
import { IEquipmentType } from '../../../../../models/transport-request/equipment-type';
import { IFreightLocation } from '../../../../../models/transport-request/freight-location';

@Component({
  selector: 'app-equipments-form',
  templateUrl: './equipments.form.component.html',
  styles: [`
    .form-group > .text-danger {
      font-size: 12PX;
    }
  `]
})
export class EquipmentsFormComponent implements OnInit {

  @Output() onEquipmentEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() equipment: ITransportEquipment;
  @Input() index: number;

  requiredFields: string[] = []

  temperatureUnit: string = 'F';
  weightUnit: string = 'KG';
  locationList: any[] = [];
  equipmentList: any[] = [];
  equipmenTypetList: any[] = [];
  equipmentTypes: any[];

  equipments$: Observable<IEquipment[]>;
  equipmentTypes$: Observable<IEquipmentType[]>;
  locations$: Observable<IFreightLocation[]>;

  constructor(private baseService: GenericRequestsService, private store: Store<AppState>) { }

  async ngOnInit() { 
    console.log(this.index)
    this.setInitState();
    await this.getInitialData();
  }

  singleSelectOptions = {
    width: '100%',
    multiple: false,
    placeholder: 'Selecccionar...'
  };

  private setInitState() {
    if(!this.equipment.equipment){
      this.equipment.equipment = '#';
      this.equipment.unitOfMeasurement = 'CM';
    }
  }

  selectedItem(value) {
    this.equipment.destination = value;
  }
  selectedItemOrigin(value) {
    this.equipment.origin = value;
  }

  getEquipmentTypesByEquipment() {
    const dropdownList = this.equipmentTypes.filter(item => {
      return item.equipment === this.equipment.equipment;
    });

    this.equipmenTypetList = dropdownList.length > 0 ? dropdownList : this.equipmentTypes;
  }

  validateField(field: string): Boolean {
    return this.requiredFields.indexOf(field) > -1;
  }

  async getInitialData() {
    this.equipments$ = this.store.select(getTMSEquipments);
    this.equipmentTypes$ = this.store.select(getTMSEquipmentTypes);
    this.locations$ = this.store.select(getTMSFreightLocations);

    this.equipments$.subscribe(
      res => {
        if(res.length > 0)
          this.equipmentList = res;
        else
          this.store.dispatch(TransportRequestActions.getEquipments());
    });

    this.equipmentTypes$.subscribe(
      res => {
        if(res.length > 0) {
          this.equipmentTypes = [...res];
          this.equipmenTypetList = res;
        }else
        this.store.dispatch(TransportRequestActions.getEquipmentTypes());
      }
    );

    this.locations$.subscribe(
      res => {
        if(res.length > 0){         
          this.locationList = res;
        }else
          this.store.dispatch(TransportRequestActions.getFreightLocations());
      }
    )
  }

  changeTemperatureUnit() { this.temperatureUnit = this.temperatureUnit == 'F' ? 'C' : 'F'; }
  changeWeightUnit() { this.weightUnit = this.weightUnit == 'KG' ? 'LB' : 'KG'; }

  add() {
    this.equipment.weightUnit = this.weightUnit;
    this.equipment.temperatureUnit = this.temperatureUnit;
    this.requiredFields = [];

    if (this.equipment.equipment == '#') this.requiredFields.push('Equipment');
    if (this.equipment.equipmentType == undefined || this.equipment.equipmentType == '#') this.requiredFields.push('EquipmentType');
    if (this.equipment.origin == '#') this.requiredFields.push('Origin');
    if (this.equipment.destination == '#') this.requiredFields.push('Destination');

    if (this.requiredFields.length > 0) return;

    this.onEquipmentEmitter.emit(this.equipment);
  }

}