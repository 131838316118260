import { Injectable } from '@angular/core';

enum environments {
    QA = 0,
    UAT = 1,
    PROD = 2
}

@Injectable({
    providedIn: 'root'
})
export class LogisticWarehouseService {
    private ApiDomain: string;
    private ApiUrl: string;
    private ApiUrlFull: string;
    private ApiBaseUrl: string;
    private ApiReferenceName: string;

    private ServerUrls: Array<string>;
    private ApiBaseUrls: Array<string>;
    private ReferencesNames: Array<string>;

    constructor(environment: environments) {
      this.ServerUrls = ["https://go.qa.mardom.com", "https://go.uat.mardom.com", "https://go.mardom.com"];
      this.ApiBaseUrls = ["api", "", "api"];
      this.ReferencesNames = ["logistic-warehouse", "lgwh", "logistic-warehouse"];

      this.configService(environment);
    }

    private configService(environment: number){
        environment = environment >= 0? environment : 1;
        if(environment in environments) {
            this.configApiURL(environment);
        }
    }

    private configApiURL(environment: number): void {
        this.setApiDomain(this.ServerUrls[environment]);
        this.setApiReferenceName(this.ReferencesNames[environment]);
        this.setApiBaseUrl(this.ApiBaseUrls[environment]);

        let url =  (this.ApiBaseUrl != "")? `${this.ApiDomain}/${this.ApiBaseUrl}` : this.ApiDomain;
        this.setApiUrl(url);

        url += `/${this.ApiReferenceName}`;
        this.setApiUrlFull(url);
    }

    public getApiDomain(): string {
        return this.ApiDomain;
    }

    public setApiDomain(apiDomain: string): void {
        this.ApiDomain = apiDomain;
    }

    public getApiReferenceName(): string {
        return this.ApiReferenceName;
    }

    public setApiReferenceName(apiReferenceName: string): void {
        this.ApiReferenceName = apiReferenceName;
    }

    public getApiUrl(): string {
        return this.ApiUrl;
    }

    public setApiUrl(apiUrl: string): void {
        this.ApiUrl = apiUrl;
    }

    public getApiUrlFull(): string {
        return this.ApiUrlFull;
    }

    public setApiUrlFull(apiUrlFull: string): void {
        this.ApiUrlFull = apiUrlFull;
    }

    public getApiBaseUrl(): string {
        return this.ApiBaseUrl;
    }

    public setApiBaseUrl(apiBaseUrl: string): void {
        this.ApiBaseUrl = apiBaseUrl;
    }
}