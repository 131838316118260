import { Inject, Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';
import { LogService } from '../../../app/services/log/log.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../core/reducers';
import * as jwt_decode from "jwt-decode";
import { selectAuthState } from '../../core/auth/_selectors/auth.selectors';


@Injectable({
    providedIn: 'root'
})
export class PowerBILogService {
    public payload$: Observable<any>;
    public username;
    public roleName;
    public payload: any;
    public authToken;

    constructor(
        private datepipe: DatePipe,
        @Inject(DOCUMENT) private document: Document,
        private store: Store<AppState>,
        private logService: LogService,
    ) { }



    createPayload(moduleId, module, clickEvent, action, objectType, objectName, refNo, refType, subModule): any {
        this.payload$ = this.store.pipe(select(selectAuthState));
        this.payload$.subscribe(res => {
            let tokenData = jwt_decode(res.authToken)
            this.authToken = res.authToken;
            this.username = res.user.email;
            this.roleName = tokenData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'][0]

        })
        const payload = {
            Site: window.location.href,
            Module: module,
            SubModule: subModule,
            Module_ID: moduleId,
            ObjectType: objectType,
            ObjectName: objectName,
            Event: clickEvent,
            Action: action,
            ReferenceID: refNo,
            ReferenceType: refType,
            SearchByDate: null,
            SearchByStatus: null,
            ListOrderBy: null,
            BLIDKiosko: null,
            TaraIDKiosko: null,
            CedulaIDKiosko: null,
            PaymentType: null,
            AcceptedTermsConditions: null,
            RNC: null,
            NombreComercial: null,
            TransactionID: null,
            QtyOfTimes: null,
            UserRole: this.roleName,
            User: this.username,
            DateRegistered: this.datepipe.transform((new Date), 'dd-MM-yyyy'),
            TimeRegistered: this.datepipe.transform(new Date(), 'hh:mm:ss a', 'GMT-4').toUpperCase().replace(/(\d{2}:\d{2}:\d{2})\s*(A|M)\.\s*(A|M)\./g, '$1 $2$3'),
            CustomerAccountNo: null,
            Version: this.checkEnvironment(),
            BrowserType: this.getBrowserType(),
            DeviceType: this.getDeviceType(),
            OperatingSystem: this.getOperatingSystem(),
            VersionMardomGO: "1.0.0",
            ErrorTracking: null,
            LoadTimeResponsiveness: null,
            LocationCountry: null,

        };

        return this.logService.postWithPayload(payload,
            this.authToken);

    }

    // auditData(moduleId, module, clickEvent, action) {
    //     const payload = this.createPayload(moduleId, module, clickEvent, action);
    //     this.logService.postWithPayload(payload,
    //         this.authToken);
    // }

    getBrowserType(): string {
        const userAgent = navigator.userAgent.toLowerCase();
        console.log("Check Browesers:: ", userAgent);

        if (/edg/i.test(userAgent)) {
            return "Edge";
        } else if (/chrome/i.test(userAgent)) {
            return "Chrome";
        } else if (/safari/i.test(userAgent) && !/chrome/i.test(userAgent)) {
            return "Safari";
        } else if (/firefox/i.test(userAgent)) {
            return "Firefox";
        } else if (/msie|trident/i.test(userAgent)) {
            return "Internet Explorer";
        } else {
            return "Unknown";
        }
    }

    getDeviceType(): string {
        const userAgent = navigator.userAgent;

        if (userAgent.match(/Android/i) || userAgent.match(/webOS/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPad/i) || userAgent.match(/iPod/i)) {
            return 'Mobile';
        } else if (userAgent.match(/iPad/i) || userAgent.match(/Tablet/i)) {
            return 'Tablet';
        } else {
            return 'Desktop';
        }
    }

    getOperatingSystem() {
        const userAgent = this.document.defaultView.navigator.userAgent || 'Unknown';

        if (userAgent.match(/Android/i)) {
            return 'Android';
        } else if (userAgent.match(/webOS/i)) {
            return 'webOS';
        } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
            return 'iOS';
        } else if (userAgent.match(/Windows Phone/i)) {
            return 'Windows Phone';
        } else if (userAgent.match(/Windows/i)) {
            return 'Windows';
        } else if (userAgent.match(/Macintosh/i)) {
            return 'Macintosh';
        } else if (userAgent.match(/Linux/i)) {
            return 'Linux';
        } else {
            return 'Unknown';
        }
    }

    checkEnvironment() {
        const userAgent = this.document.defaultView.navigator.userAgent.toLowerCase();
        const mobileKeywords = ['iphone', 'ipad', 'android', 'windows phone', 'mobile'];
        const isMobile = mobileKeywords.some(keyword => userAgent.includes(keyword));

        if (isMobile) {
            return 'Mobile';
        } else {
            return 'Web';
        }
    }


}
