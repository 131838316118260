import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpErrorResponse  } from "@angular/common/http";

import {ServiceRequest} from '../../models/service-request'
import serviceConfig from '../service-config';
import { Observable } from 'rxjs';
import { AppState } from '../../core/reducers/index';
import { Store } from '@ngrx/store';
import { selectAuthState } from '../../core/auth/_selectors/auth.selectors';
import * as jwt_decode from 'jwt-decode';
import { ResponseResult } from '../../models/response-result.model';

@Injectable({
  providedIn: 'root'
})

export class RequestService {

  uri = serviceConfig.baseUrl + serviceConfig.endPoints.request;
  headers = new HttpHeaders()
  alturi = serviceConfig.altBaseUrl;
  altHeaders: HttpHeaders;
  clientId
  clientName
  customerId

  constructor(private http:HttpClient, private store: Store<AppState> ) { 
    this.store.select(selectAuthState).subscribe((result) => {   
      this.headers = new HttpHeaders().set('Authorization', "Bearer "+result.authToken);

      var decoded = jwt_decode(result.authToken); 
      //real userid.
      this.clientId=decoded['https://mardom.com/clientId'];
      var decodediD = jwt_decode(result.idToken); 
      this.customerId=decodediD['sub'];
      this.clientName=decodediD['name'];
      
      this.altHeaders = new HttpHeaders()
                          .append('Authorization', "Bearer "+result.authToken)  
                          .append('Content-Type', 'application/json')
                          .append('accept','*/*')
                          .append('accept','text/plain');
    });
  }

  addRequest(request: ServiceRequest){
    request.client_id=this.clientId;
    request.client_name=this.clientName;
    request.customer_id=this.customerId;

    return this.http.post(`${this.uri}`, request, { headers: this.altHeaders });
  }

  updateRequest(id:number ,request: ServiceRequest){
    return this.http.put(`${this.uri}/${id}`, request, { headers: this.altHeaders });
  }
  
  getRequestServiceList(requestid:number,from:string,to:string,status:number,customer:string,reference:string,isAdmin:boolean, page:number, requestServiceType:string) {
    
    if(!isAdmin){
      customer=this.clientId;
    }
    //else{customer='0';}
    
    return this
      .http
      .get<ResponseResult<ServiceRequest>>(`${this.uri}/?requestId=${requestid}&from=${from}&to=${to} 23:59:59&statusId=${status}&customerId=${customer}&requestServiceType=${requestServiceType}&reference=${reference}&page=${page}`, { headers: this.altHeaders });
  }

  getRequestService(requestid:number):Observable<any> {
    return this
      .http
      .get<any>(`${this.uri}/${requestid}`,
       { headers: this.altHeaders });

  }

}
