import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { currentUser, selectAllPermissions } from '../../../../core/auth';
import {TrackblsService } from '../../../../services/tracking/trackbls.service';
import {InvoiceService } from '../../../../services/invoice/invoice.service';
import {AzurefileService } from '../../../../services/files/azurefile.service';
import {ServicefileService } from '../../../../services/files/servicefile.service';
import { DataTableDirective } from 'angular-datatables';
import { FormBuilder, FormGroup ,ReactiveFormsModule } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceFileModel } from '../../../../models/servicefile';
import { VoucherModel } from '../../../../models/voucher';
import { VoucherService } from '../../../../services/invoice/voucher.service';
import { AppState } from '../../../../core/reducers';
import { registerLocaleData } from '@angular/common';
import localeDo from '@angular/common/locales/es-DO';
import { BillVoucherModel } from '../../../../models/billvoucher';
import { ToastrService } from 'ngx-toastr';
import { ResponsibleService } from '../../../../services/responsiblemanagement/responsible.service';
import * as jwt_decode from 'jwt-decode';
import { currentUserRoleIds,selectAuthState } from '../../../../core/auth/_selectors/auth.selectors';
import { SalesexecutiveService } from '../../../../services/salesexecutive/salesexecutive.service';
import { LogService } from '../../../../services/log/log.service';
import { LogDto } from '../../../../models/dto/log.dto';
import { Screen } from '../../../../models/enums/screen.enum';
import { Action } from '../../../../models/enums/action.enum';
registerLocaleData(localeDo, 'es-DO');


@Component({
  selector: 'kt-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements OnInit {

  @ViewChild('balanceSheetModal', {static: true}) balanceSheetModal: TemplateRef<any>;

  optionSelected:  string = '';

  username: string = '';

  today = new Date();

  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  dtTrigger : Subject<any> = new Subject();

  dollarExchangeRate = 0;
  lastUpdated = 'cargando...';
  invoices = null;
  vouchers = [];
  CurrentDate;
  invoice;
  voucher;
  uploadForm: FormGroup;
  voucherForm: FormGroup;
  public serviceModel: ServiceFileModel;
  voucherModel: VoucherModel;
  billVoucherModel: BillVoucherModel;
  files: any[];
  uploadedFiles: ServiceFileModel[] = [];
  excecutive: any;
  logisticServices = [];
  dtOptions: any = {};
  blDTOptions: any = {};
  adminFilter: boolean = false;
  permissions: any[];


  showInvoices = false;
  showVouchers = false;
  showLogistic = false;

  notifications = 0; 

  loading: boolean = false;
  serviceType: any = undefined;
  serviceCode: string = '';
  serviceDescription: string = '';
  payload$: any;

  constructor(private router: Router,private trackblsService:TrackblsService,
    private fb:FormBuilder,
    private invoiceService: InvoiceService,
    private voucherService:VoucherService,
    public sFileService:ServicefileService,public azFileService:AzurefileService,
    private chRef: ChangeDetectorRef,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private responsibleService: ResponsibleService,
    private store: Store<AppState>,
    private logService: LogService,
    private salesexecutiveservice : SalesexecutiveService) {
     this.excecutive = {
       executiveName: '',
       mobilePhone: '',
       email: ''
     }     
     this.store.select(selectAllPermissions).subscribe(
      res => {
        this.permissions = res        
      }
    );
  }

  hasAccess(moduleAction: string):boolean {   
    return this.permissions.findIndex(permission => permission.name === moduleAction) > -1
   }

   getPendingInvoices(){
     this.showInvoices = true;
     this.showVouchers = false;
   }

  showSuccess(message) {
    this.toastr.success(message, 'Confirmación.');
  }

  showError(message,title) {
    this.toastr.error(message, title);
  }
  showInfo(message,title) {
    this.toastr.info(message, title);
  }
  logInvoices(){
    this.payload$.subscribe(res => {
      let tokenData = jwt_decode(res.authToken)
      this.logService.postWithPayload(<LogDto>{
        ActionId : Action.AccederPantalla,
        ScreenId : Screen.AccesoConsultaFacturas,
        Json : '',
        UserName: res.user.fullname,
        Email: res.user.email,
        ClientId: tokenData['https://mardom.com/clientId'] == '' ? null : tokenData['https://mardom.com/clientId'],
        RoleName:tokenData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'][0]
      },
      res.authToken);
    })      
  }
  logVouchers(){
    this.payload$.subscribe(res => {
      let tokenData = jwt_decode(res.authToken)
      this.logService.postWithPayload(<LogDto>{
        ActionId : Action.AccederPantalla,
        ScreenId : Screen.CargaComprobantesPago,
        Json : '',
        UserName: res.user.fullname,
        Email: res.user.email,
        ClientId: tokenData['https://mardom.com/clientId'] == '' ? null : tokenData['https://mardom.com/clientId'],
        RoleName:tokenData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'][0]
      },
      res.authToken);
    })  
  }
  logSurvey(){
    this.payload$.subscribe(res => {
      let tokenData = jwt_decode(res.authToken)
      this.logService.postWithPayload(<LogDto>{
        ActionId : Action.AccederPantalla,
      ScreenId : Screen.EncuestaSatisfaccion,
      Json : '',
        UserName: res.user.fullname,
        Email: res.user.email,
        ClientId: tokenData['https://mardom.com/clientId'] == '' ? null : tokenData['https://mardom.com/clientId'],
        RoleName:tokenData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'][0]
      },
      res.authToken);
    }) 
  }
  ngOnInit() {
    this.checkUserRole();   
    this.payload$ = this.store.pipe(select(selectAuthState));
    this.store.pipe(select(currentUser)).subscribe((user) => {
      this.username = user.fullname;
      this.getsalesexecutive();      
    })   
    
    this.CurrentDate = new Date();

    this.blDTOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      ordering:  false,
      language: {
        "lengthMenu": "Mostrando _MENU_ registros por página",
        "zeroRecords": "...",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "..",
        "infoFiltered": "(Filtrado de _MAX_ registros)",
        "search":"Filtrar",
        "paginate": {
            "previous": "Anterior",
            "next": "Siguiente",
            "first": "Primera",
            "last": "Ultima"
        }
      }
    }
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      ordering:  false,
    language: {
      "lengthMenu": "Mostrando _MENU_ registros por página",
      "zeroRecords": "...",
      "info": "Mostrando página _PAGE_ de _PAGES_",
      "infoEmpty": "..",
      "infoFiltered": "(Filtrado de _MAX_ registros)",
      "search":"Filtrar:",
      "paginate": {
          "previous": "Anterior",
          "next": "Siguiente",
          "first": "Primera",
            "last": "Ultima"
      }
    }
  }
  this.uploadForm=this.fb.group({
    flUpload:''
  });  
  this.voucherForm=this.fb.group({
    serviceType:'',
    serviceId:'',
    serviceDescription:''
  });   
  }

  checkUserRole(){
    this.store.select(selectAuthState).subscribe((result) => {   
      var decoded = jwt_decode(result.authToken); 
      //real userid.
      var currentRoles= decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      var internal=currentRoles.indexOf("Client");
      
      if(!(internal>-1)){
        this.adminFilter=true;
      }
      else{
        this.adminFilter=false;
      }
     
    })
  }

  clearVoucher(){
    this.files=null;
    this.voucherForm.reset(this.voucherForm.value);
    this.voucherForm= this.fb.group({
      serviceType:'',
      serviceId:'',
      serviceDescription:''
    });
    this.serviceType = undefined
    this.serviceCode = ''
    this.serviceDescription = ''
  }

  getTrackingBls() {    
     this.trackblsService
      .getBls(5)
        .subscribe((data) => {
          
          this.logisticServices = data;
          //this.rerender();
          //this.chRef.detectChanges();
        })
  }
 
 getVouchers() {  
  this.logVouchers();
   if(!this.vouchers.length){
    this.voucherService
    .getVoucher()
      .subscribe((data) => {        
        this.vouchers = data;
        this.showVouchers = true;
        this.showInvoices = false;
        this.chRef.detectChanges();
      })
   } else {
    this.chRef.detectChanges();
   } 
  
}

logPrintInvoice(invoice){
  this.payload$.subscribe(res => {
    let tokenData = jwt_decode(res.authToken)
    this.logService.postWithPayload(<LogDto>{
      ActionId : Action.ImprimirFactura,
      ScreenId : Screen.AccesoConsultaFacturas,
      Json : JSON.stringify(invoice),
      UserName: res.user.fullname,
      Email: res.user.email,
      ClientId: tokenData['https://mardom.com/clientId'] == '' ? null : tokenData['https://mardom.com/clientId'],
      RoleName:tokenData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'][0]
    },
    res.authToken);
  })     
}

  getUploadedDocuments(model,type){
    let id=0;
    if(type=="invoice"){
      this.invoice=model;
      id=this.invoice.id;
    }else{
      this.voucher=model;
      id=this.voucher.id;
    }
    
    this.sFileService.getServiceFiles(type,id).subscribe((result) => {
      
      this.uploadedFiles=result;
      //this.rerender();
      this.chRef.detectChanges();
    });
  }
  
  

  filterTitles(item){
    var title="";
    switch(item){
      case 'booking':{
        title='Booking Exportación';
        break;
      }
      case 'gestionAduanal':{
        title='Gestión Aduanal';
        break;
      }
      case 'impresionBl':{
        title='Impresión de BL';
        break;
      }
      case 'flete':{
        title='Flete';
        break;
      }
      case 'almacenaje':{
        title='Almacenaje';
        break;
      }
      case 'transporte':{
        title='Transporte Terrestre';
        break;
      }
      case 'talleres':{
        title='Talleres Refrigeración';
        break;
      }
      default:{
        title='Otros';
        break;
      }
    }
  
    return title;
  }

  downloadDocument(sfile){    
    
    this.azFileService.downloadFile(sfile.filename).subscribe(response => {
      
      const url= window.URL.createObjectURL(response);
            
      var fileLink = document.createElement('a');
      fileLink.href = url;
      fileLink.download = sfile.file_type;
      fileLink.click();
      //window.open(url,sfile.file_type);    


    }), error => console.log('Error downloading the file'),
    () => console.info('File downloaded successfully');
  }

  getUploadedFiles(files) {        
    if(files.target){
      files = files.target.files;
    }
    this.files = files;
  }

  removeFile(index){    
    let fileArray = [];
    for(let key in this.files){
      let file = this.files[key];
      if(key != index && file instanceof File){
        fileArray.push(file);
      }
    }
    this.files = fileArray;
  }

  prepareFileUpload(invoice){
    this.invoice=invoice;
    this.uploadForm.value.flUpload='';

  }

  uploadFiles(type):boolean{     
    var canUpload:boolean=false;
    if(this.files==null ||  this.files.length==0){
      this.showError('Debe seleccionar un archivo para adjuntar.','Error al adjuntar comprobante');
      this.files=[];
      return canUpload;
    }

    if(this.files[0].size > 20000000){
      this.showError('Archivos no pueden exceder 20mb de tamaño.','Error al adjuntar comprobante');
      this.files=[];
      return canUpload;
   };

    this.azFileService.uploadFiles(this.files).subscribe((archivo) => {
      
      this.responsibleService.getRequestResponsible('voucher').subscribe((result: any) => {
        if(result.length>0)
        {
          //result[0].responsibleId.replace('auth0|',''),result[0].responsibleName
          if(type=="invoice"){
          
          this.billVoucherModel = new BillVoucherModel();
            this.billVoucherModel.recordId=this.invoice.id;
            this.billVoucherModel.billId=this.invoice.number;
            this.billVoucherModel.company=this.invoice.company;
            this.billVoucherModel.bill_date=this.invoice.dateInvoice;
            this.billVoucherModel.ncf=this.invoice.ncf;
            this.billVoucherModel.assigned_user=result[0].responsibleId.replace('auth0|','');
            this.payload$.subscribe(res => {
              let tokenData = jwt_decode(res.authToken)
              this.logService.postWithPayload(<LogDto>{
                ActionId : Action.SubirComprobantePago,
                ScreenId : Screen.CargaComprobantesPago,
                Json : JSON.stringify(this.billVoucherModel),
                UserName: res.user.fullname,
                Email: res.user.email,
                ClientId: tokenData['https://mardom.com/clientId'] == '' ? null : tokenData['https://mardom.com/clientId'],
                RoleName:tokenData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'][0]
              },
              res.authToken);
            })             
            this.invoiceService.addBillVoucher(this.billVoucherModel).subscribe((result) => {
              this.voucher=result;             
              this.showSuccess('Ha adjuntado su comprobante satisfactoriamente!');
              this.clearVoucher()              
              this.saveFiles(archivo,type,this.invoice.id);
            });
          }else{
            this.voucherModel=new VoucherModel();
            this.voucherModel.service_type=this.voucherForm.value.serviceType;
            this.voucherModel.service_id=this.voucherForm.value.serviceId;
            this.voucherModel.description=this.voucherForm.value.serviceDescription;
            this.voucherModel.Status="1";
            this.voucherModel.assigned_user=result[0].responsibleId.replace('auth0|','');
            this.payload$.subscribe(res => {
              let tokenData = jwt_decode(res.authToken)
              this.logService.postWithPayload(<LogDto>{
                ActionId : Action.SubirComprobantePago,
              ScreenId : Screen.CargaComprobantesPago,
              Json : JSON.stringify(this.voucherModel),
                UserName: res.user.fullname,
                Email: res.user.email,
                ClientId: tokenData['https://mardom.com/clientId'] == '' ? null : tokenData['https://mardom.com/clientId'],
                RoleName:tokenData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'][0]
              },
              res.authToken);
            })          
            this.voucherService.addVoucher(this.voucherModel).subscribe((result) => {              
              this.voucher=result;
              this.vouchers=[];
              this.getVouchers();
              this.showSuccess('Ha adjuntado su comprobante de pago satisfactoriamente!');
              this.clearVoucher();
              this.saveFiles(archivo,type,this.voucher.id);              
            });
      }
    }else
    {
      this.showError('Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com','Error al adjuntar comprobante');
    }
    },(error) => {
      console.log(error)
    });
      canUpload=true;
      

    }, (error) => {
      this.showError('Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com','Error al adjuntar comprobante');
      console.log(error)
    }
    )
    this.files=[];
    return canUpload;
  }

  saveFiles(result,type,serviceid){
    var canUpload:boolean=false;
    var serviceFiles: ServiceFileModel[]=[];
    for(let i in result){
      let file = result[i];
      this.serviceModel=new ServiceFileModel();

      if(type=="invoice"){
        this.serviceModel.service_type='invoice';          
        
        this.serviceModel.service_id=serviceid;
        

      }else{
        this.serviceModel.service_type='voucher';
        
        this.serviceModel.service_id=serviceid;
        
      }

      this.serviceModel.filename=file.id;
      this.serviceModel.file_type=file.name;
      serviceFiles.push(this.serviceModel);
      canUpload=true;
      
    }
    if(canUpload){
      this.sFileService.addServiceFile(serviceFiles);
    }
  }

  rerender(): void {
    if(this.dtElement){
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
        this.chRef.detectChanges();
      });
    } else {
      this.dtTrigger.next();
      this.chRef.detectChanges();
    }
    
  }

  ngAfterViewInit(): void {

    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
}

  gotToRoute(){
    if(this.optionSelected){
      this.router.navigate([this.optionSelected]);
    }
  }

  onSelectChange(event){
    if(event.nextId == 'message-tab'){
      this.router.navigate(['/message']);
    }

  }
  getsalesexecutive()
  {
    this.salesexecutiveservice.getsalesexecutive().subscribe((response) => {
      this.excecutive = response;
      this.chRef.detectChanges();
    },
      //(error) => this.showError('Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com', 'Error al cargar información'));
      (error) => (''));
  }

}
