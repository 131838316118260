import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";

import {ImportExport} from '../../models/exportimport'
import serviceConfig from '../service-config';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAuthState } from '../../core/auth/_selectors/auth.selectors';
import { AppState } from '../../core/reducers/index';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class BookingIEService {

  uri = serviceConfig.baseUrl;
  altUri = serviceConfig.altBaseUrl;
  headers = new HttpHeaders();
  altHeaders: HttpHeaders;
  windipHeaders: HttpHeaders;
  userId 
  constructor(private http:HttpClient, private store: Store<AppState>) { 
    this.store.select(selectAuthState).subscribe((result) => {  
      this.headers = new HttpHeaders().set('Authorization', "Bearer "+result.authToken);
      var decoded = jwt_decode(result.authToken); 
      this.userId=decoded['https://mardom.com/clientId'];
      
      this.altHeaders =new HttpHeaders()
                    .append('Authorization', "Bearer "+result.authToken)  
                    .append('accept','*/*')
                    .append('Access-Control-Allow-Methods', 'POST, GET, PUT, DELETE, OPTIONS')
                    .append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept, Referer, User-Agent')
                    .append('accept','text/plain');
      this.windipHeaders =new HttpHeaders()
                    .append('accept','*/*')
                    .append('Access-Control-Allow-Methods', 'POST, GET, PUT, DELETE, OPTIONS')
                    .append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept, Referer, User-Agent')
                    .append('accept','text/plain');
    });
  }

  addImportExport(importexport: ImportExport){
    let url = this.uri + serviceConfig.endPoints.booking;
    this.http.post(url, importexport, { headers: this.altHeaders }).subscribe(res => console.log('Done'));
  }

  updateImportExport(id:number ,importexport: ImportExport){
    let url = this.uri + serviceConfig.endPoints.booking;
    this.http.put(`${url}/${id}`, importexport, { headers: this.altHeaders }).subscribe(res => console.log('Done'));
  }

  getOptions(endPoint,api?:string){    
    if(api==undefined)
      api='old';

    let url =(api=="old"? this.uri:this.altUri) + endPoint;

    return this.http.get(url, { headers: this.altHeaders});
  }

  getImportExport(requestId:number):Observable<any> {
    let url = this.uri + serviceConfig.endPoints.booking;
    return this
      .http
      .get<any>(`${url}?RequestId=${requestId}`,
       { headers: this.altHeaders });

  }

  uploadFiles(files){
    let url = serviceConfig.filesUrl+serviceConfig.endPoints.azureUpload;
    let formData = new FormData();
    for(let i in files){
      let file = files[i];
      if( file instanceof File){
        formData.append('files[]', file, file.name);
      }
    }     
    return this.http.post(url,formData, { headers:this.altHeaders } );
  }

  getCostumRequestStatus(fileNumber: string):Observable<any> {
    let url = serviceConfig.customsFilesUrl + serviceConfig.endPoints.fileNumber;
    return this
      .http
      .get<any>(`${url}?noexpedienteaduanal=${fileNumber}`,
       { headers: this.windipHeaders });

  }

  getEquOptions(endPoint){    
    let url =this.altUri + endPoint;
    return this.http.get(url, { headers: this.altHeaders});
  }
}
