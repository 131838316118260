export const Constants = {
   PORTLIST_WITHDO: "DO",
   PORTLIST_WITHRD: "RD",
   Download_Selected: "downloadSelected",
   ContainerType_Selected: "containerTypeSelected",
   LoadType_Selected: "loadTypeSelected",
   MerchandiseType_Selected: "merchandiseTypeSelected",
   PackagingType_Selected: "packagingTypeSelected",
   CodPelegrosidad_Selected: "codPelegrosidadSelected",
   ClientSelected_Type: "clientSelectedType",
   City_Selected: "citySelected",
   Agency_Selected: "agencySelected",
   DUPLICATE_MANIFEST_ID: "dublicateManifetId",
   EDIT_MANIFEST_ID: "editManifetId",
   MANIFEST_ID : "manifestId",
   BL_ID: "blId",
   AUTH_0:"auth0|",
   BL_Details:"bldetails",
   CONTROLS:"controls",
   CONTAINER_TYPE:"ContainerType",
   CONTAINER_NAME:"ContainerName",
   LOAD_TYPE:"LoadType",
   TYPE_MERCHANDISE:"TypeofMerchandise",
   NAME_MERCHANDISE:"MerchandiseName",
   PACKAGING_NAME:"PackagingName",
   PACKING_TYPE:"PackingType",
   DANGEROUS_LOAD:"DangerousLoad",
   DANGEROUS_NAME:"DangerName",
   ASSOCIATED_CLIENT_TYPE:"AssociatedClientType",
   CITY:"city",
   ASSOCIATE_CITY:"AssociateCity",
   RNC:"RNC",
   TELEPHONE:"Telephone",
   EMAIL:"Email",
   DIRECTION:"Direction",
   POSTAL:"postal",
   PROVINCE:"Province",
   CONSIGNEE:"Consignee",
   CONSIGNEEID:"ConsigneeId",
   CONSOLIDATOR:"Consolidator",
   REGISTER_CODE:"RegisterCode",
   DATE:"Date",
   USER:"User",
   SHIP:"Ship",
   TRAVEL_NO:"TravelNo",
   PORT:"Port",
   PORT_OF_ORIGIN:"PortofOrigin",
   AGENCY:"Agency",

   NOTIFY_TO:"NotifyTo",

   ACTIVE:"Active",
   DATE_CREACION:"DateCreacion",
   REGISTRATION_CODE:"RegistrationCode",
   ASSOCIATED_MANIFEST_NO:"AssociatedManifestNo",
   CONTAINER:"Container",
   STAMP:"Stamp",
   LOADER:"loader",
   DAMAGED:"Damaged",
   COMMENTARY:"Commentary",
   CODE_DETAIL:"CodeDetail",
   ASSOCIATE_REGISTER_CODE:"AssociateRegisterCode",
   SON_ASSOCIATE:"SonAssociate",
   WEIGHT:"Weight",
   QUANTITY:"Quantity",
   MERCHANDISE_DESCRIPTION:"MerchandiseDescription",
   LOCATION:"Location",
   CONSOLIDADOR_ID:"ConsolidadorId",
   DOCKING_DATE:"DockingDate",
   XML_FILE:"XMLfile",
   DESCARGADO:"Descargado",
   CONTENEDOR_DESCARGADO:"Carga Disponible para Verificación",
   SOLICITUD:"Solicitud",
   SOLICITUD_DE_VERIFICACIÓN:"Solicitud de Verificación",
   VERIFICADO:"Verificado",
   CARGA_VERIFICADA:"Carga Verificada",
   FACTURADO:"DesParaPago",
   CARGA_FACTURADO:"Carga Disponible para Pago",
   CARGA_PAGADA:"Carga Pagada",
   DESPACHADO:"Despachado",
   CARGA_DESPACHADA:"Carga Despachada",
   PAGADO:"Pagado",
   NAME:"name",
   PROCESANDO:"Procesando",
   CONTENEDOR_DESCARGAD:"Contenedor Descargado",
   ENPROCESO:"EnProceso",
   SOLDESPACHO:"SolDespacho",
   ENPRODESPACHO:"EnProDespacho",
   EN_PROCESO_DE_VERIFICACION:"En Proceso de Verificación",
   SOLICITUD_DE_DESPACHO:"Solicitud de Despacho",
   EN_PROCESO_DE_DESPACHO:"En Proceso de Despacho",
  }