import { Component, Injectable } from '@angular/core';
import {  
  CanDeactivate, Router
} from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of} from 'rxjs';
import { LogDto } from '../../../models/dto/log.dto';
import { Action } from '../../../models/enums/action.enum';
import { Screen } from '../../../models/enums/screen.enum';
import { LogService } from '../../../services/log/log.service';
import { BookingComponent } from '../../../components/service-request/components/booking/booking.component';
import { AlertService } from '../../services/alert.service';

@Injectable({
  providedIn: 'root'
})
export class FormGuard implements CanDeactivate<BookingComponent> {
  constructor(
    private alertService: AlertService,
    private logService: LogService,
    private router: Router,
  ){}

  async canDeactivate( component: BookingComponent ): Promise<boolean> {
    if (component.editableId > 0){
      let action = this.getActions(component);

      this.logService.post(<LogDto>{
        ActionId : action,
        ScreenId : Screen.ConsultaSolicitudes,
        Json : '',
      });
    }
    return true;
  }

 

  private getActions(component: BookingComponent) {
    let action;    

    if (component.optionSelected.export && component.optionSelected.costums && component.optionSelected.transport)
      action = Action.AbortarCreacionSolicitudBookingExportacionMixto;

    if (component.optionSelected.export && component.optionSelected.costums)
      action = Action.AbortarCreacionSolicitudBookingExportacionAduana;

    if (component.optionSelected.export && component.optionSelected.transport)
      action = Action.AbortarCreacionSolicitudBookingExportacionTransporte;

    if (component.optionSelected.import && component.optionSelected.costums && component.optionSelected.transport)
      action = Action.AbortarCreacionSolicitudBookingImportacionMixto

    if (component.optionSelected.import && component.optionSelected.costums)
      action = Action.AbortarCreacionSolicitudBookingImportacionAduana

    if (component.optionSelected.import && component.optionSelected.transport)
      action = Action.AbortarCreacionSolicitudBookingImportacionTransporte


    if (component.optionSelected.costums && component.optionSelected.transport)
      action = Action.AbortarCreacionSolicitudServiciosLogisticosTransporte
    
    return action;
  }
}
