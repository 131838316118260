import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpErrorResponse  } from "@angular/common/http";

import {ReceptionReferenceModel} from '../../models/receptionreference'
import {ReceptionDetailModel} from '../../models/receptiondetail'
import serviceConfig from '../service-config';
import { Observable } from 'rxjs';
import { AppState } from '../../core/reducers/index';
import { Store } from '@ngrx/store';
import { selectAuthState } from '../../core/auth/_selectors/auth.selectors';

@Injectable({
  providedIn: 'root'
})
export class ReceptionreferenceService {

  uri = serviceConfig.baseUrl + serviceConfig.endPoints.receptionReference;
  alturi = serviceConfig.altBaseUrl;
  alturi2 = serviceConfig.altBaseUrl2;
  uriLogisticW = serviceConfig.logisticWarehouseBaseUrl;
  altHeaders: HttpHeaders;
  headers: HttpHeaders;
  user_id:'';

  constructor(private http:HttpClient, private store: Store<AppState>) { 
    this.store.select(selectAuthState).subscribe((result) => {   
      
      this.altHeaders = new HttpHeaders()
                    .append('Authorization', "Bearer "+result.authToken)  
                    .append('Content-Type', 'application/json')
                    .append('accept','*/*')
                    .append('Allow', '*')
                    .append('Access-Control-Allow-Origin', '*')
                    .append('Access-Control-Allow-Methods', 'POST, GET, PUT, DELETE, OPTIONS')
                    .append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept, Referer, User-Agent')
                    .append('accept','text/plain');


      this.headers =new HttpHeaders()
                          .append('Authorization', "Bearer "+result.authToken)  
                          .append("Access-Control-Allow-Origin", "*")
                          .append("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept")
                          .append('accept','*/*')
                          .append('accept','text/plain');
    });
  }

  addReference(reference:ReceptionReferenceModel){
    this.http.post(`${this.uri}`, reference, { headers: this.headers }).subscribe(res => console.log('Done'));;
  }

  sentReferenceWis(reference:ReceptionReferenceModel,clientId,companyName){
    //let url = this.alturi + serviceConfig.endPoints.wareHouseReference;

    //let url = this.alturi + '/logistic-warehouse/ReferenceReception/CreateReference?clientCode='+clientId+'&CompanyName='+companyName; //FOR PROD ENV
    let url = `${this.uriLogisticW}/ReferenceReception/CreateReference?clientCode=${clientId}&CompanyName=${companyName}`;  //FOR UAT ENV

    return this.http.post(`${url}`, reference, { headers: this.altHeaders });
  }
  getReferenceList(referenceId:number,from:string,to:string,status:number,customer:number,pageNumber : number, pageSize:number) {
    //let url = this.alturi + serviceConfig.endPoints.wareHouseReference; //FOR PROD ENV
    let url = this.alturi2 + serviceConfig.endPoints.wareHouseReference; //FOR UAT ENV

    return this
      .http
      .get<any[]>(`${url}/?startDate=${from}&endDate=${to+" 23:59:59"}&status=${status}&requestNumber=${referenceId}&pageNumber=${pageNumber}&pageSize=${pageSize}`,{ headers: this.altHeaders });
  }

  getReferenceDetail(referenceId:number) {
    let url = serviceConfig.baseUrl + serviceConfig.endPoints.referenceDetail;
    return this
      .http
      .get<any[]>(`${url}/?referenceId=${referenceId}`, {headers: this.altHeaders});
  }

}
