export class ContactModel {
  contact_id: number;
  name: string;
  rnc: number;
  company_phone: string;
  address: string;
  contact_name: string;
  contact_phone: string;
  email: string;
  tax_id: String;
  zipcode: string;
  contact_type: number;
}
