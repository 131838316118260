import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  ChangeDetectorRef,
  ApplicationRef,
  HostListener
} from '@angular/core';
import { formFields, fieldGroups, groupOrder ,regimenOptions} from './FormFields';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RequestService } from '../../../../services/booking/request.service';
import { ServicefileService } from '../../../../services/files/servicefile.service';
import { ServiceRequest } from '../../../../models/service-request';
import { ContactModel } from '../../../../models/contact';
import { ImportExport } from '../../../../models/exportimport';
import { CustomsModel } from '../../../../models/customs';
import { TransportModel } from '../../../../models/transport';
import { BookingIEService } from '../../../../services/booking/booking-ie.service';
import { ContactService } from '../../../../services/booking/contact.service';
import { CustomsService } from '../../../../services/booking/customs.service';
import { TransportService } from '../../../../services/booking/transport.service';
import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';
import { SharedataService } from '../address-selector/sharedata.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { ServiceFileModel } from '../../../../models/servicefile';
import { ToastrService } from 'ngx-toastr';
import { ResponsibleService } from '../../../../services/responsiblemanagement/responsible.service';
import { select, Store } from '@ngrx/store';
import * as jwt_decode from 'jwt-decode';
import { AppState } from '../../../../core/reducers/index';
import { selectAuthState } from '../../../../core/auth/_selectors/auth.selectors';
import Swal from 'sweetalert2';
import { selectAllPermissions } from '../../../../core/auth/_selectors/permission.selectors';
import { ChangeStatusModel } from '../../../../models/changestatus';
import { LogService } from '../../../../services/log/log.service';
import { Action } from '../../../../models/enums/action.enum';
import { Screen } from '../../../../models/enums/screen.enum';
import { LogDto } from '../../../../models/dto/log.dto';
import { LodashService } from '../../../../core/services/lodash.service';
import { TransportRequestModel } from '../../../../models/transport-request';
import { ITransportEquipment } from '../../../../models/transport-equipment';
@Component({
  selector: 'kt-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss']
})
export class BookingComponent implements OnInit {
  public requestModel: ServiceRequest;
  public exportimportModel: ImportExport;
  public contactModel: ContactModel;
  public customsModel: CustomsModel;
  public transportModel: TransportModel;
  public transportRequest: TransportRequestModel;
  public serviceModel: ServiceFileModel;
  public changeStatusModel: ChangeStatusModel;
  public forwarderid;
  public consignatarioid;
  public notifierid;
  public embarcadorid;
  equipments: ITransportEquipment[] = [];
  addEquipmentFlag: Boolean = false;
  canSave: boolean = true;
  editableId: number;
  disabledFields: boolean = false;
  exportMode: boolean = false;
  ImportMode: boolean = false;
  logisticMode: boolean = false;
  adminFilter: boolean = false;
  editRequest: boolean = true;
  permissions: any[];
  assigned_user: string;
  assigned_name: string;
  initialModel: any = {};
  serviceType = [];
  shipLines = [];
  oldTransportForm: Boolean = false
  oldTransportRequest: any = {};
  exportDate:any;
  tipoI= [];
  tipoE= [];

  @ViewChild('tab', { static: false }) ngTabSet: any;
  @ViewChild('mapModal', { static: true }) mapModal: TemplateRef<any>;
  EquipmentTypeData:any=[];
  timePickerOptions = [];
  tipoEquipof2nd:string;
  optionSelected = {
    import: false,
    export: false,
    costums: false,
    transport: false,
    general: true
  };

  invalidTabs = {
    generalInformation: false,
    exports: false,
    requestData: false,
    imports: false,
    transports: false
  };

  fields = {
    import: {},
    export: {},
    costums: {},
    transport: {},
    transportOld: {},
    general: {}
  };

  fieldGroupsLabels: any;
  groupOrder: any;
  location: {
    address: string;
    latitude: number;
    longitude: number;
  };

  formData: any = {};

  activeTab = 'general';
  addressFieldSelected: string = '';
  addressSelected: any = {};

  actualStatus: string = 'No disponible';
  actualStatusDate: string = 'No disponible';
  fileNumber: string;

  multipleSelectOptions = {
    width: '100%',
    multiple: true,
    placeholder: 'Selecccionar...'
  };

  singleSelectOptions = {
    width: '100%',
    multiple: false,
    placeholder: 'Selecccionar...'
  };

  files: any[];
  dbfiles: any[];

  currentStep = 0;
  payload$: any;
  loadOptions:boolean=false;
  qty_value:number;
  typeofService:number=0;
  aduanal: any;
  regimen: any;
  cusNumber: any;
  regNumber: any;

  updateSelection(option) {
    this.optionSelected[option] = !this.optionSelected[option];
    if (option == 'export' && this.optionSelected.import) {
      this.optionSelected.import = false;
    } else if (option == 'import' && this.optionSelected.export) {
      this.optionSelected.export = false;
    }
    if (option == 'costums') {
      this.formData['pesoUnit'] = 'kg';
    }
    for (let key in this.optionSelected) {
      this.renderFields(key);
    }
  }

  selectedItem(value, field) {
    this.formData[field.value] = value.value;
    for (let key in this.optionSelected) {
      this.renderFields(key);
    }
    this.chRef.detectChanges();
  }

  validateConditionFields() {
    for (let key in this.optionSelected) {
      this.renderFields(key);
    }
    this.chRef.detectChanges();
  }

  hasAccess(moduleAction: string): boolean {
    return (
      this.permissions.findIndex(
        permission => permission.name === moduleAction
      ) > -1
    );
  }

  canShowField(displayOptions, condition) {
    if (displayOptions) {
      if (displayOptions.always && !condition) {
        return true;
      } else {
        for (let key in displayOptions) {
          if (key == 'always') {
            break;
          }
          let option = displayOptions[key];
          if (this.optionSelected[key] != option) {
            return false;
          }
        }

        if (condition) {
          for (let key in condition) {
            let typeConditions = condition[key];

            if (Array.isArray(typeConditions)) {
              for (let i in typeConditions) {
                if (!this.checkConditionOperator(typeConditions[i])) {
                  return false;
                }
              }
            } else {
              return this.checkConditionOperator(typeConditions);
            }
          }
        }
        return true;
      }
    }
    return false;
  }

  checkConditionOperator(typeCondition) {
    let operator = typeCondition.operator;
    switch (operator) {
      case 'neq':
        return this.formData[typeCondition.field] != typeCondition.value;
      case 'eq':
        return this.formData[typeCondition.field] == typeCondition.value;
      case 'gt':
        return this.formData[typeCondition.field] > typeCondition.value;
      case 'lt':
        return this.formData[typeCondition.field] < typeCondition.value;
      case 'gte':
        return this.formData[typeCondition.field] >= typeCondition.value;
      case 'lte':
        return this.formData[typeCondition.field] <= typeCondition.value;
    }
  }

  constructor(
    private chRef: ChangeDetectorRef,
    public requestService: RequestService,
    public bookingService: BookingIEService,
    public contactService: ContactService,
    public customsService: CustomsService,
    public datepipe: DatePipe,
    public transportService: TransportService,
    public sFileService: ServicefileService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private store: Store<AppState>,
    private responsibleService: ResponsibleService,
    private shareDataService: SharedataService,
    private Activatedroute: ActivatedRoute,
    private router: Router,
    private checker: ApplicationRef,
    private logService: LogService,
    private lodashService: LodashService
  ) {
    this.fieldGroupsLabels = fieldGroups;
    this.groupOrder = groupOrder;
    this.shareDataService.getAddress().subscribe(address => {
      this.addressSelected = address;
    });
  }

  public get dirty(): boolean {
    let reponse = false;
    if (this.lodashService) {
      reponse = !this.lodashService.IsObjectEqual(
        this.initialModel,
        this.formData
      );
    }
    return reponse;
  }

  ngOnInit() {
    this.checkUserRole();
    this.payload$ = this.store.pipe(select(selectAuthState));
    this.editableId = Number(
      this.Activatedroute.snapshot.queryParamMap.get('id') || '0'
    );
    this.disabledFields = this.editableId != 0;

    this.store.select(selectAllPermissions).subscribe(res => {
      this.permissions = res;
    });
    if (this.editableId > 0) {
      if (this.adminFilter) {
        //this.currentStep = 3;
      }
      this.getEquipmentData();
      this.chRef.detectChanges();
      this.loadEditableFields();
      this.getUploadedDocuments();
      this.payload$.subscribe(res => {
        let tokenData = jwt_decode(res.authToken);
        this.logService.postWithPayload(
          <LogDto>{
            ActionId: Action.VerSolicitudServicio,
            ScreenId: Screen.ConsultaSolicitudes,
            Json: '',
            UserName: res.user.fullname,
            Email: res.user.email,
            ClientId:
              tokenData['https://mardom.com/clientId'] == ''
                ? null
                : tokenData['https://mardom.com/clientId'],
            RoleName:
              tokenData[
                'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
              ][0]
          },
          res.authToken
        );
      this.chRef.detectChanges();
      });
    } else {
      this.editRequest = false;
      this.payload$.subscribe(res => {
        let tokenData = jwt_decode(res.authToken);
        this.logService.postWithPayload(
          <LogDto>{
            ActionId: Action.CrearSolicitudServicio,
            ScreenId: Screen.ConsultaSolicitudes,
            Json: '',
            UserName: res.user.fullname,
            Email: res.user.email,
            ClientId:
              tokenData['https://mardom.com/clientId'] == ''
                ? null
                : tokenData['https://mardom.com/clientId'],
            RoleName:
              tokenData[
                'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
              ][0]
          },
          res.authToken
        );
      });
      this.editRequest = false;      
    }
    this.loadSelectOptions();

    this.timePickerOptions = [
      ...this.timeOptions('AM'),
      ...this.timeOptions('PM')
    ];

    const url = this.router.url;
    this.optionSelected.general = true;

    if (url.indexOf('export-type') != -1) {
      this.optionSelected.export = true;
      this.optionSelected.costums = true;
      this.optionSelected.transport = true;
      this.formData['tipoServicio'] = 2;
      this.exportMode = true;
    }

    if (url.indexOf('import-type') != -1) {
      this.optionSelected.import = true;
      this.optionSelected.costums = true;
      this.optionSelected.transport = true;
      this.formData['tipoServicio'] = 1;
      this.ImportMode = true;
    }

    if (url.indexOf('customs-type') != -1) {
      this.optionSelected.costums = true;
      this.optionSelected.transport = true;
      this.logisticMode = true;
      this.formData['pesoUnit'] = 'kg';
    }

    this.addressSelected = {
      address: 'Marítima Dominicana, Santo Domingo',
      latitude: 18.427056,
      longitude: -70.004173
    };

    this.loadDefaultValues();
    this.updateSelection('general');
    this.validateConditionFields();
    this.chRef.detectChanges();
    this.initialModel = { ...this.formData };
  }

  async getCustomStatus(fileNumber) {
    this.fileNumber = fileNumber;
    let bookingData = await this.bookingService
      .getCostumRequestStatus(fileNumber)
      .toPromise()
      .then(res => {
        return res;
      })
      .catch(error => {
        console.log(error);
      });
    this.actualStatusDate = bookingData[0].Fecha_Expediente;
    this.actualStatus = bookingData[0].Estatus_Declaracion_Cliente;
  }

  loadDefaultValues() {
    let fieldWithDefaultValues = formFields.filter(input => {
      return input.defaultValue ? true : false;
    });

    for (let i in fieldWithDefaultValues) {
      let field = fieldWithDefaultValues[i];
      if (!this.formData[field.value]) {
        this.formData[field.value] = field.defaultValue;
      }
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    const url = this.router.url;

    let action = Action.VerSolicitudServicio;

    if (url.indexOf('export-type') != -1)
      action = Action.AbortarCreacionSolicitudBookingExportacion;
    if (url.indexOf('import-type') != -1)
      action = Action.AbortarCreacionSolicitudBookingImportacion;
    if (url.indexOf('customs-type') != -1)
      action = Action.AbortarCreacionSolicitudServiciosLogisticos;

    this.payload$.subscribe(res => {
      let tokenData = jwt_decode(res.authToken);
      this.logService.postWithPayload(
        <LogDto>{
          ActionId: action,
          ScreenId: Screen.ConsultaSolicitudes,
          Json: '',
          UserName: res.user.fullname,
          Email: res.user.email,
          ClientId:
            tokenData['https://mardom.com/clientId'] == ''
              ? null
              : tokenData['https://mardom.com/clientId'],
          RoleName:
            tokenData[
              'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
            ][0]
        },
        res.authToken
      );
    });
  }

  getTransportEquipments(data){    
    this.equipments = data;
  }

  showSuccess(requestId) {
    this.toastr.success(
      'Solicitud Almacenada, código:' + requestId + '!',
      'Confirmación.'
    );

    this.router.navigate(['/search']);
  }

  showError() {
    this.toastr.error(
      'Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com',
      'Error en solicitud.'
    );
  }

  timeOptions(suffix) {
    let hours = [];
    for (let i = 1; i <= 12; i++) {
      let time = i + ':00 ' + suffix;
      hours.push({
        id: time,
        text: time
      });
      time = i + ':30 ' + suffix;
      hours.push({
        id: time,
        text: time
      });
    }
    return hours;
  }

  loadEditableFields() {
    this.Activatedroute.params.subscribe(params => {
      this.requestService
        .getRequestService(this.editableId)
        .toPromise()
        .then(res => {
          this.requestModel = res;
          this.assigned_user = res.ServiceRequest.assigned_user;
          this.assigned_name = res.ServiceRequest.assigned_name;
          this.formData['tipoServicio'] = res.ServiceRequest.service_type ;
          this.formData['numCotizacion'] = res.ServiceRequest.quotation==null?0:res.ServiceRequest.quotation;
          this.formData['cantidadBultos'] = res.ServiceRequest.bag_qty==null?0:res.ServiceRequest.bag_qty;
          this.formData['tipoBultos'] = res.ServiceRequest.bag_type;
          this.formData['peso'] = res.ServiceRequest.weight;
          this.formData['pesoUnit'] = res.ServiceRequest.weight_unit;
          this.formData['descripcionBL'] = res.ServiceRequest.bl_description;
          this.formData['referenciaBooking'] = res.ServiceRequest.reference;
          this.formData['comentario'] = res.ServiceRequest.comment;
          this.typeofService=parseInt(res.ServiceRequest.service_type);
          if (res.ServiceRequest.status != 1 && res.ServiceRequest.status != 2)
            this.canSave = false;

          this.optionSelected.costums = res.ServiceRequest.customs;
          this.optionSelected.import = res.ServiceRequest.import;
          this.optionSelected.export = res.ServiceRequest.export;
          this.optionSelected.transport = res.ServiceRequest.transport;
          this.getImportExport();
          this.getCustom();
          this.getTransport();
          this.chRef.detectChanges();
          if (this.requestModel.customs && this.requestModel.expediente) {
            this.getCustomStatus(this.requestModel.expediente);
          }          
          this.initialModel = {...this.formData}
         this.chRef.detectChanges(); 
        });
    });
    this.chRef.detectChanges(); 
  }

  async getImportExport() {
    await this.bookingService
      .getImportExport(this.editableId)
      .toPromise()
      .then(res => {
        this.exportimportModel = res;
        this.formData['cantidadContenedores'] = res.container_qty;
        this.qty_value=res.container_qty;
        this.formData['puertoEmbarque'] = res.shipping_port;
        this.formData['puertoDesembarque'] = res.landing_port;
        this.formData['buqueviaje'] = res.ship_id;
        this.formData['destinoCarga'] = res.cargo_destination;
        this.formData['additionalReference'] = res.reference;
        this.formData['cargaAsegurada'] = res.insured_cargo? '1': '2';
        this.formData['cargaPeligrosa'] = res.hazmat? '1': '2';
        this.formData['cargaRefriferada'] = res.refrigerated_cargo? '1': '2';
        if(this.editableId >0){
          if(this.formData['cargaRefriferada']=="1"){
            this.formData['cargaRefriferada']="Si";
          }else if(this.formData['cargaRefriferada']=="2"){
            this.formData['cargaRefriferada']="No";
          }
        }
        if(this.editableId >0){
          if(this.formData['cargaPeligrosa']=="1"){
            this.formData['cargaPeligrosa']="Si";
          }else if(this.formData['cargaPeligrosa']=="2"){
            this.formData['cargaPeligrosa']="No";
          }
        }
        this.contactService
          .getContact(this.exportimportModel.consignee_id)
          .toPromise()
          .then(res => {
            this.contactModel = res;
            this.formData[
              'consignatarioNombreEmpresa'
            ] = this.contactModel.name;
            this.formData['consignatarioDireccion'] = this.contactModel.address;
            this.formData['consignatarioEmail'] = this.contactModel.email;
            this.formData[
              'consignatarioPersonaContacto'
            ] = this.contactModel.contact_name;
            this.formData[
              'consignatarioTelefonoContacto'
            ] = this.contactModel.contact_phone;
            this.formData[
              'consignatarioTelefonoContacto'
            ] = this.contactModel.company_phone;
            this.formData['consignatarioTaxId'] = this.contactModel.tax_id;
            this.formData['consignatarioZipCode'] = this.contactModel.zipcode;

            if (this.contactModel.name != null && this.contactModel.name != '')
              this.formData['showConsignatary'] = '1';
            else this.formData['showConsignatary'] = '2';
            if(this.editableId >0){
              if(this.formData['showConsignatary']=="1"){
                this.formData['showConsignatary']="Si";
              }else if(this.formData['showConsignatary']=="2"){
                this.formData['showConsignatary']="No";
              }
            }
          });
        this.contactService
          .getContact(this.exportimportModel.notified_id)
          .toPromise()
          .then(res => {
            this.contactModel = res;
            this.formData['notificarNombre'] = this.contactModel.name;
            this.formData['notificarDireccion'] = this.contactModel.address;
            this.formData['notificarEmail'] = this.contactModel.email;
            this.formData[
              'notificarPersonaContacto'
            ] = this.contactModel.contact_name;
            this.formData[
              'notificarTelefonoEmpresa'
            ] = this.contactModel.contact_phone;
            this.formData[
              'notificarTelefonoEmpresa'
            ] = this.contactModel.company_phone;
            this.formData['notificarTaxId'] = this.contactModel.tax_id;

            if (this.contactModel.name != null && this.contactModel.name != '')
              this.formData['showNotificationInfo'] = '1';
            else this.formData['showNotificationInfo'] = '2';
          });
        this.contactService
          .getContact(this.exportimportModel.shipper_id)
          .toPromise()
          .then(res => {
            this.contactModel = res;
            this.formData['embarcardorNombre'] = this.contactModel.name;
            this.formData['embarcardorDireccion'] = this.contactModel.address;
            this.formData['embarcardorEmail'] = this.contactModel.email;
            this.formData[
              'embarcardorPersonaContacto'
            ] = this.contactModel.contact_name;
            this.formData[
              'embarcardorTelefono'
            ] = this.contactModel.contact_phone;
            this.formData[
              'embarcardorTelefono'
            ] = this.contactModel.company_phone;
            this.formData['embarcardorZipCode'] = this.contactModel.zipcode;
            if (this.contactModel.name != null && this.contactModel.name != '')
              this.formData['showEmbarquer'] = '1';
            else this.formData['showEmbarquer'] = '2';

            if(this.formData['showEmbarquer']=="1"){
              this.formData['showEmbarquer']="Si";
            }else if(this.formData['showEmbarquer']=="2"){
              this.formData['showEmbarquer']="No";
            }
          });
        this.contactService
          .getContact(this.exportimportModel.forwarder_id)
          .toPromise()
          .then(res => {
            this.contactModel = res;
            this.formData['forwarderNombre'] = this.contactModel.name;
            this.formData['forwarderDireccion'] = this.contactModel.address;
            this.formData['forwarderEmail'] = this.contactModel.email;
            this.formData[
              'forwarderPersonaContacto'
            ] = this.contactModel.contact_name;
            this.formData['forwarderZipCode'] = this.contactModel.zipcode;
            this.formData[
              'forwarderTelefonoContacto'
            ] = this.contactModel.contact_phone;
            this.formData[
              'forwarderTelefonoContacto'
            ] = this.contactModel.contact_name;
            this.formData['forwarderTaxId'] = this.contactModel.tax_id;
            if (this.contactModel.name != null && this.contactModel.name != '')
              this.formData['showForwarder'] = '1';
            else this.formData['showForwarder'] = '2';
          });

        this.formData['fumigacion'] = this.exportimportModel.fumigated;
        this.formData['lineaBooking'] = this.exportimportModel.line_id;
        this.formData['pagadoPor'] = this.exportimportModel.payed_by;
        this.formData['terminoPago'] = this.exportimportModel.payment_terms;
        this.formData['tipoCarga'] = this.exportimportModel.cargo_type;
        this.formData['tipoExportacion'] = this.exportimportModel.export_type;
        this.formData[
          'cantidadContenedores'
        ] = this.exportimportModel.container_qty;

        this.formData['ventilacion'] = this.exportimportModel.ventilacion;
        this.formData['temperatura'] = this.exportimportModel.temperatura;
        this.formData['tempUnit'] = this.exportimportModel.temp_unit;
        this.formData['humedad'] = this.exportimportModel.humedad;
        this.formData[
          'carbonDioxideLevel'
        ] = this.exportimportModel.carbonDioxideLevel;
        this.formData['oxygenLevel'] = this.exportimportModel.oxygenLevel;
        this.formData['commodity'] = this.exportimportModel.commodity;
        this.formData[
          'ventilationUnit'
        ] = this.exportimportModel.ventilationUnit;
        this.formData['numeroUN'] = this.exportimportModel.hazmatnumero_un;
        this.formData['codigoIMO'] = this.exportimportModel.hazmatcodigo_imo;
        this.formData[
          'componentePeligros'
        ] = this.exportimportModel.hazmatcomp_peligroso;
        this.formData[
          'pesoCargaPeligros'
        ] = this.exportimportModel.hazmatpeso_neto;
        this.formData[
          'pesoGruesoCargaPeligros'
        ] = this.exportimportModel.hazmatpeso_grueso;
        this.formData['flashPoint'] = this.exportimportModel.hazmatflashpoint;
        this.formData['codigoPaquete'] = this.exportimportModel.codigo_empaque;
        this.formData[
          'nombreContactoEmergencia'
        ] = this.exportimportModel.nombre_emergencia;
        this.formData[
          'telefonoContactoEmergencia'
        ] = this.exportimportModel.telefono_emergencia;
      });
  }

  getCustom() {
    this.customsService
      .getCustom(this.editableId)
      .toPromise()
      .then(res => {
        this.regNumber=res.regimen;
        this.cusNumber=res.custom_management_types;
        this.customsModel = res;

        this.formData['fechaEmbarque'] = formatDate(
          this.customsModel.shipping_date,
          'yyyy-MM-dd',
          'en'
        );
        this.formData['fechaEstimadaLlegadaSalida'] = formatDate(
          this.customsModel.arrival_date,
          'yyyy-MM-dd',
          'en'
        );
        this.formData['permisosEspeciales'] =
          this.customsModel.require_special_permissions == 1 ? true : false;

        if (this.requestModel.service_type == 1) {
          this.formData['tipoGestionAduanal'] = Number(
            this.customsModel.custom_management_types
          );
        } else {
          this.formData['tipoGestionAduanale'] = Number(
            this.customsModel.custom_management_types
          );
        }        
        this.formData['gestionRegimen'] = this.customsModel.regimen;
        this.formData[
          'servicioGestionAduanal'
        ] = this.customsModel.other_services.split(',');
       this.exportDate= this.formData['fechaEstimadaLlegadaSalida'];
      });
  }

  getLocationDataFromModel(coordsStr) {
    let origenCoordStrs = coordsStr.split('|');
    return {
      latitude: origenCoordStrs[0] ? parseFloat(origenCoordStrs[0]) : 0,
      longitude: origenCoordStrs[1] ? parseFloat(origenCoordStrs[1]) : 0
    };
  }

  getTransport() {
    this.transportService
      .getTransport(this.editableId)
      .toPromise()
      .then(res => {
        this.transportModel = res;

        this.formData['cantidad'] = this.transportModel.quantity;
        this.formData['dimensionCarga'] = this.transportModel.dimensions;

        if (this.transportModel.origen) {
          this.formData['direccionOrigen'] = {
            address: this.transportModel.origen, ...this.getLocationDataFromModel(this.transportModel.origen_coordinates)
          };
        }

        if (this.transportModel.destiny) {
          this.formData['direccionDestino'] = {
            address: this.transportModel.destiny, ...this.getLocationDataFromModel(this.transportModel.destiny_coordinates)
          };
        }

        this.formData['fechaRecogida'] = formatDate(this.transportModel.pickup_date,'yyyy-MM-dd','en');
        this.formData['horarioRecepcionEntrega'] = this.transportModel.delivery_time;
        this.formData['nombreContactoRecibe'] = this.transportModel.contact_name;
        this.formData['telefonoContactoRecibe'] = this.transportModel.contact_phone;
        this.formData['requiereParada'] = this.transportModel.require_stop ? '1' : '2';
        this.formData['tipoEquipo'] = this.transportModel.equipment_type;
        this.formData['peso'] = this.transportModel.cargo_weight;
        this.formData['referenciaBooking'] = this.transportModel.reference_no;

    if(this.editableId > 0){
      if(this.formData['tipoServicio']=="1"){
        this.formData['tipoServicio']="Importación";
      }else if(this.formData['tipoServicio']=="2"){
        this.formData['tipoServicio']="Exportación";
      }
     //if(this.editableId > 0)
      if(this.formData['showConsignatary']=="1"){
        this.formData['showConsignatary']="Si";
      }else if(this.formData['showConsignatary']=="2"){
        this.formData['showConsignatary']="No";
      }
    }
    this.chRef.detectChanges();
      }); 
  }
  getEquipments(): ITransportEquipment[] {
    let response: ITransportEquipment[] = [];

    for(let item of this.transportModel['Equipments']){
      response.push({
        commodity : item.Commodity,
        description : item.Description,
        destination : item.Destination,
        equipment : item.Equipment,
        equipmentNumber : item.EquipmentNumber,
        equipmentType : item.EquipmentType,
        hazardousCode : item.HazardousCode,
        height: item.Heigh,
        length: item.Length,
        numberOfEquipments: item.NumberOfEquipments,
        numberOfPieces : item.NumberOfPieces,
        origin: item.Origin,
        pONumber: item.PONumber,
        packingType: item.PackingType,
        refrigerated: item.Refrigerated,
        temperature: item.Temperature,
        temperatureUnit: item.TemperatureUnit,
        unitOfMeasurement: item.UnitOfMeasurement,
        weight: item.Weight,
        weightUnit: item.WeightUnit,
        width: item.Width
      })
    }

    return response;
  }

  async loadSelectOptions() {
    let selectInputs = formFields.filter(input => {
      return (
        (input.type == 'select' || input.type == 'multiselect') &&
        input.endPoint
      );
    });
    this.chRef.detectChanges();
    for (let i in selectInputs) {
      let selectInput = selectInputs[i];
      let endPoint = selectInput.endPoint + '?';
      for (let key in selectInput.endPointParams) {
        endPoint += key + '=' + selectInput.endPointParams[key];
      }
      console.log(endPoint)
      console.log(this.currentStep)
      if(endPoint == '/Packingtype?' && this.currentStep == 0){
        await this.bookingService
        .getOptions(endPoint, selectInput.api)
        .toPromise()
        .then((result: any) => {
          let index = formFields.findIndex(
            input => input.value == selectInput.value
          );
          if (index >= 0) {
            formFields[index].options = result.map(item => {
              if (item.codigo != null && item.descripcion != null)
              return {
                id: item.codigo,
                text: item.descripcion
              };
            });
            this.chRef.detectChanges();
          }
        })
      } else if( this.currentStep == 2 && endPoint != '/Packingtype?' && !this.loadOptions){
        await this.bookingService
        .getOptions(endPoint, selectInput.api)
        .toPromise()
        .then((result: any) => {
          let index = formFields.findIndex(
            input => input.value == selectInput.value
          );
          if (index >= 0) {
            formFields[index].options = result.map(item => {
              if (item.codigo != null && item.nombre != null)
                return {
                  id: item.codigo,
                  text: item.nombre
                };
              if (item.Codigo != null && item.Nombre != null)
                return {
                  id: item.codigo,
                  text: item.nombre
                };
              /* if (item.codigo != null && item.descripcion != null)
                return {
                  id: item.codigo,
                  text: item.descripcion
                }; */
              if (item.CodigoTipoCarga != null)
                return {
                  id: item.CodigoTipoCarga,
                  text: item.NombreTipoCarga
                };
              if (item.id != null && item.description != null)
                return {
                  id: item.id,
                  text: item.description
                }; 
            });
            this.chRef.detectChanges();
          }
        })
        .catch(error => {
          console.log(error);
        });
      }
    }
      let adunal='' 
      const t=formFields[74].options
      t.forEach(element=>{
      if(element.id==this.cusNumber){
        adunal=element.text
      }})

    if(adunal==''){
      const s=formFields[73].options
      s.forEach(element=>{
      if(element.id==this.cusNumber){
        adunal=element.text
      }})}
      this.aduanal=adunal;

      const r=regimenOptions.forEach(element=>{
      if(element.id==this.regNumber){
        this.regimen=element.text;
      }})
      this.chRef.detectChanges();
    
    if(this.currentStep == 2){
      this.loadOptions = true
    }
  }

  selectLocation(field) {
    this.addressFieldSelected = field;
    this.location = this.formData[field];
    this.modalService.open(this.mapModal);
  }

  setAddress() {
    if (this.addressSelected.address) {
      this.formData[this.addressFieldSelected] = this.addressSelected;
      this.modalService.dismissAll();
      this.addressSelected = {};
    } else {
      Swal.fire('Debe indicar una dirección', 'validación', 'warning');
    }
  }

  onSelectChange(event) {
    this.renderFields(event.nextId);
  }

  renderFields(type) {
    this.fields[type] = formFields
      .filter(field => {
        return this.canShowField(field.display[type], field.condition);
      })
      .reduce(function (r, a) {
        r[a.group] = r[a.group] || [];
        r[a.group].push(a);
        return r;
      }, Object.create(null));
    this.checkServiceType();
  }

  checkUserRole() {
    this.store.select(selectAuthState).subscribe(result => {
      var decoded = jwt_decode(result.authToken);
      //real userid.
      var currentRoles =
        decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      var internal = currentRoles.indexOf('Client');

      if (!(internal > -1)) {
        this.adminFilter = true;
      } else {
        this.adminFilter = false;
      }
    });
  }

  beforeSave() {
    this.checkServiceType();
    this.saveForm();
  }

  checkServiceType() {
    if (
      this.ngTabSet != null &&
      this.ngTabSet.tabs != null &&
      this.ngTabSet.tabs.length > 1
    ) {
      let tabs = this.ngTabSet.tabs['_results'];
      if (tabs.find(item => item.id == 'import') != null) {
        this.formData['tipoServicio'] = 1;
      } else if (tabs.find(item => item.id == 'export') != null) {
        this.formData['tipoServicio'] = 2;
      }
    }
  }

  getFieldValue(field) {
    if (field.type == 'select') {
      let valueSelected = field.options.find(option => {
        if(option){
          return option.id == this.formData[field.value];
        }
      });
      if (valueSelected) {
        return valueSelected.text;
      }
    } else if (field.type == 'location') {
      return this.formData[field.value]
        ? this.formData[field.value].address
        : null;
    }
    return this.formData[field.value];
  }

  previusStep() {
    this.currentStep--;
  }

  nextStep() {
    this.checkServiceType();
    this.currentStep++;
    if(this.currentStep == 2){
      this.loadSelectOptions();
      if(this.editableId > 0){
      this.formData['tipoEquipo']=this.tipoEquipof2nd
      //requiereParada
        if(this.formData['showForwarder']=="1"){
          this.formData['showForwarder']="Si";
          }else if(this.formData['showForwarder']=="2"){
          this.formData['showForwarder']="No"; }

          if(this.formData['requiereParada']=="1"){
            this.formData['requiereParada']="Si";
            }else if(this.formData['requiereParada']=="2"){
            this.formData['requiereParada']="No"; }
          }

          
    }
  }

  step2(){
    this.loadSelectOptions();
    if(this.editableId > 0){
    this.formData['tipoEquipo']=this.tipoEquipof2nd

      if(this.formData['showForwarder']=="1"){
        this.formData['showForwarder']="Si";
        }else if(this.formData['showForwarder']=="2"){
        this.formData['showForwarder']="No";
          }
    }
  }

  nextTab(tabId) {
    this.checkServiceType();
    let tabs = this.ngTabSet.tabs;
    let tabIndex = this.getTabIndex(tabId, tabs);

    if (tabs.length - 1 > tabIndex) {
      this.ngTabSet.select(tabs['_results'][tabIndex + 1].id);
    }

    if (tabs.length - 1 == tabIndex) {
      if (this.canSave) this.saveForm();
      else this.showError();
    }
  }

  previousTab(tabId) {
    this.checkServiceType();
    let tabs = this.ngTabSet.tabs;
    let tabIndex = this.getTabIndex(tabId, tabs);

    this.ngTabSet.select(tabs['_results'][tabIndex - 1].id);
  }

  getTabIndex(tabId, tabs) {
    let tabIndex = 0;
    for (let i in tabs['_results']) {
      let tab = tabs['_results'][i];
      if (tab.id == tabId) {
        tabIndex = parseInt(i);
        break;
      }
    }
    return tabIndex;
  }

  getTabBtnLabel(tabId) {
    let tabs = this.ngTabSet.tabs;
    let tabIndex = this.getTabIndex(tabId, tabs);
    if (tabs.length - 1 == tabIndex) {
      return 'Guardar';
    }
    return 'Siguiente';
  }

  savingAvailable(tabId) {
    let tabs = this.ngTabSet.tabs;
    let tabIndex = this.getTabIndex(tabId, tabs);
    if (tabs.length - 1 == tabIndex) {
      if (!this.canSave) return true;
    }
    return false;
  }

  validateRequireFields() {
    let isValidForm: boolean = true;
    this.optionSelected.general = true;
    for (let section in this.optionSelected) {
      let sectionActive = this.optionSelected[section];
      if (sectionActive) {
        let allFieldsInSection = Object.keys(this.fields[section])
          .map(group => {
            return this.fields[section][group];
          })
          .reduce((acc, val) => acc.concat(val), []);
        let fields = allFieldsInSection.filter(
          field => field.required && !this.formData[field.value]
        );
        if (fields.length > 0) {
          for (let elem of fields) {
            elem.isInvalid = true;
          }
          isValidForm = false;
        } else {
          let validFields = allFieldsInSection.filter(
            field => field.required && this.formData[field.value]
          );
          for (let elem of validFields) {
            elem.isInvalid = false;
          }
        }
        let generals = fields.filter(item => item.group == 'general');
        let exports = fields.filter(item => item.group == 'export');
        let imports = fields.filter(item => item.group == 'import');
        let transports = fields.filter(item => item.group == 'transport');

        if (section == 'general') {
          if (generals.length > 0) {
            this.invalidTabs.generalInformation = true;
          } else {
            this.invalidTabs.generalInformation = false;
          }
        }

        if (
          section == 'export' ||
          section == 'import' ||
          section == 'transport'
        ) {
          if (exports.length > 0) {
            this.invalidTabs.exports = true;
          } else {
            this.invalidTabs.exports = false;
          }
          if (imports.length > 0) {
            this.invalidTabs.imports = true;
          } else {
            this.invalidTabs.imports = false;
          }
          if (transports.length > 0) {
            this.invalidTabs.transports = true;
          } else {
            this.invalidTabs.transports = false;
          }
        }
      }
    }
    if (
      this.invalidTabs.transports ||
      this.invalidTabs.imports ||
      this.invalidTabs.exports
    )
      this.invalidTabs.requestData = true;
    else this.invalidTabs.requestData = false;
    return isValidForm;
  }

  getTabsSelectedCount() {
    let count = 0;
    for (let i in this.optionSelected) {
      if (this.optionSelected[i]) {
        count++;
      }
    }
    return count;
  }

  changeWeightUnit() {
    this.formData['pesoUnit'] = this.formData['pesoUnit'] == 'kg' ? 'lb' : 'kg';
  }
  saveForm() {
    if (
      this.formData['cargaRefriferada'] == '1' &&
      this.optionSelected.export &&
      (!this.formData['ventilationUnit'] ||
        !this.formData['oxygenLevel'] ||
        !this.formData['carbonDioxideLevel'])
    )
      return Swal.fire(
        'Campos Requeridos.',
        'Si la carga es refrigerada, por favor completar los campos requeridos (*)',
        'info'
      );
    let canProceed = this.validateRequireFields();
    if (!canProceed) {
      Swal.fire(
        'Información.',
        'Debe completar los campos requeridos (*)',
        'info'
      );
      return;
    }

    if (this.requestModel == null) {
      this.requestModel = new ServiceRequest();
      this.requestModel.request_id = 0;
    }

    this.requestModel.service_type = this.formData['tipoServicio'];
    this.requestModel.quotation = this.formData['numCotizacion'];
    this.requestModel.bag_type = this.formData['tipoBultos'];
    this.requestModel.bag_qty = this.formData['cantidadBultos'];
    this.requestModel.weight = this.formData['peso'];
    this.requestModel.weight_unit = this.formData['pesoUnit'];
    this.requestModel.bl_description = this.formData['descripcionBL'];
    this.requestModel.reference = this.formData['referenciaBooking'];
    this.requestModel.comment = this.formData['comentario'];
    this.requestModel.customs = this.optionSelected.costums != null ? this.optionSelected.costums : false;
    this.requestModel.import = this.optionSelected.import != null ? this.optionSelected.import : false;
    this.requestModel.export =
      this.optionSelected.export != null ? this.optionSelected.export : false;
    this.requestModel.transport =
      this.optionSelected.transport != null
        ? this.optionSelected.transport
        : false;

      
    //Update
    if (this.requestModel.request_id != null && this.requestModel.request_id > 0) {
      Swal.fire({
        title: '',
        text:
          '¿Estás seguro de actualizar la solicitud y la información completada?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No'
      }).then(result => {
        if (result.value) {
          let currentDate = new Date();
          this.requestModel.date_modified = new Date(
            Date.UTC(
              currentDate.getUTCFullYear(),
              currentDate.getUTCMonth(),
              currentDate.getUTCDate(),
              currentDate.getUTCHours(),
              currentDate.getUTCMinutes(),
              currentDate.getUTCSeconds()
            )
          );
          this.requestService
            .updateRequest(this.requestModel.request_id, this.requestModel)
            .subscribe(res => {
              this.uploadFiles(this.requestModel.request_id);
              this.saveImportExport(this.requestModel.request_id);
              this.saveCustoms(this.requestModel.request_id);
              //TODO: UPDATE TRANSPORT REQUEST              
              this.showSuccess(this.requestModel.request_id);
              this.saveResponsibleStatus();
            });
        }
      });
    } else {
      debugger;
      Swal.fire({
        title: '',
        text:
          '¿Estás seguro en proceder con la solicitud y la información completada?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No'
      }).then(result => {
        if (result.value) {
          //Add
          //search responsible.
          var totalSelectedTabs = this.getTabsSelectedCount();
          //since general tab counts we need more than 2 tabs
          if (totalSelectedTabs > 2) {
            this.responsibleService.getResponsiblesCustomer().subscribe(
              (result: any) => {
                if (result.length > 0) {
                  this.addDefaultResponsible(
                    result[0].responsibleId.replace('auth0|', ''),
                    result[0].responsibleName
                  );
                } else {
                  this.showError();
                }
              },
              error => {
                console.log(error);
              }
            );
          } else if (this.optionSelected.costums) {
            this.responsibleService.getRequestResponsible('customs').subscribe(
              (result: any) => {
                if (result.length > 0) {
                  this.addDefaultResponsible(
                    result[0].responsibleId.replace('auth0|', ''),
                    result[0].responsibleName
                  );
                } else {
                  this.showError();
                }
              },
              error => {
                console.log(error);
              }
            );
          } else if (this.optionSelected.import) {
            this.responsibleService.getRequestResponsible('import').subscribe(
              (result: any) => {
                if (result.length > 0) {
                  this.addDefaultResponsible(
                    result[0].responsibleId.replace('auth0|', ''),
                    result[0].responsibleName
                  );
                } else {
                  this.showError();
                }
              },
              error => {
                console.log(error);
              }
            );
          } else if (this.optionSelected.transport) {
            this.responsibleService
              .getRequestResponsible('transport')
              .subscribe(
                (result: any) => {
                  if (result.length > 0) {
                    this.addDefaultResponsible(
                      result[0].responsibleId.replace('auth0|', ''),
                      result[0].responsibleName
                    );
                  } else {
                    this.showError();
                  }
                },
                error => {
                  console.log(error);
                }
              );
          } else if (this.optionSelected.export) {
            var lineName = '';
            switch (
              this.formData['lineaBooking'] != null
                ? this.formData['lineaBooking']
                : '0'
            ) {
              case '1': {
                lineName = 'Geest';
                break;
              }
              case '2': {
                lineName = 'Baltic';
                break;
              }
              case '3': {
                lineName = 'Cosiarma';
                break;
              }
              default: {
                lineName = 'Freight Forwarding';
                break;
              }
            }
            this.responsibleService.getResponsibleLine(lineName).subscribe(
              (result: any) => {
                if (result.length > 0) {
                  this.addDefaultResponsible(
                    result[0].responsibleId.replace('auth0|', ''),
                    result[0].responsibleName
                  );
                } else {
                  this.toastr.error(
                    'No hay responsable registrado con la línea especificada.',
                    'Error en solicitud.'
                  );
                }
              },
              error => {
                console.log(error);
              }
            );
          }
        }
      });
    }
  }

  saveResponsibleStatus() {
    this.changeStatusModel = new ChangeStatusModel();
    this.changeStatusModel.requestId = this.editableId;
    this.changeStatusModel.message = 'En Proceso de validación';
    this.changeStatusModel.estatusId = 1; //En Proceso de validación;
    this.changeStatusModel.userId = this.assigned_user;
    this.changeStatusModel.resposibleName = this.assigned_name;

    this.responsibleService.changeStatus(this.changeStatusModel).subscribe(
      data => {
        this.showSuccess('Información actualizada satisfactoriamente.');
      },
      error => {
        this.toastr.error(
          'Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com',
          'Error actualizando solicitud.'
        );
      }
    );
  }

  goToRequestServices(){
    const navigationExtra: NavigationExtras = { state: { loadStore: true }}
    this.router.navigate(['search'], navigationExtra);
  }

  addDefaultResponsible(responsibleId, responsibleName) {
    this.requestModel.assigned_user = responsibleId;
    this.requestModel.assigned_name = responsibleName;
    {
      this.requestService.addRequest(this.requestModel).subscribe(res => {
        this.uploadFiles(res['request_id']);
        this.saveImportExport(res['request_id']);
        this.saveCustoms(res['request_id']);
        this.showSuccess(res['request_id']);
        this.saveTransport(res['request_id']);
        this.saveLog(this.requestModel);
      });
    }
  }
  saveLog(payload: any) {
    const isCreatePage = this.editableId == 0;

    let action = Action.CrearSolicitudServicio;

    if (
      this.optionSelected.export &&
      this.optionSelected.costums &&
      this.optionSelected.transport
    )
      action = isCreatePage
        ? Action.CrearSolicitudBookingExportacionMixto
        : Action.ActualizarSolicitudBookingExportacionMixto;

    if (this.optionSelected.export && this.optionSelected.costums)
      action = isCreatePage
        ? Action.CrearSolicitudBookingExportacionAduana
        : Action.ActualizarSolicitudBookingExportacionAduana;

    if (this.optionSelected.export && this.optionSelected.transport)
      action = isCreatePage
        ? Action.CrearSolicitudBookingExportacionTransporte
        : Action.ActualizarSolicitudBookingExportacionTransporte;

    if (
      this.optionSelected.import &&
      this.optionSelected.costums &&
      this.optionSelected.transport
    )
      action = isCreatePage
        ? Action.CrearSolicitudBookingImportacionMixto
        : Action.ActualizarSolicitudBookingImportacionMixto;

    if (this.optionSelected.import && this.optionSelected.costums)
      action = isCreatePage
        ? Action.CrearSolicitudBookingImportacionAduana
        : Action.ActualizarSolicitudBookingImportacionAduana;

    if (this.optionSelected.import && this.optionSelected.transport)
      action = isCreatePage
        ? Action.CrearSolicitudBookingImportacionTransporte
        : Action.ActualizarSolicitudBookingImportacionTransporte;

    if (this.optionSelected.costums && this.optionSelected.transport)
      action = isCreatePage
        ? Action.CrearSolicitudBookingImportacionTransporte
        : Action.ActualizarSolicitudBookingImportacionTransporte;

    this.payload$.subscribe(res => {
      let tokenData = jwt_decode(res.authToken);
      this.logService.postWithPayload(
        <LogDto>{
          ActionId: action,
          ScreenId: Screen.ConsultaSolicitudes,
          Json: '',
          UserName: res.user.fullname,
          Email: res.user.email,
          ClientId:
            tokenData['https://mardom.com/clientId'] == ''
              ? null
              : tokenData['https://mardom.com/clientId'],
          RoleName:
            tokenData[
              'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
            ][0]
        },
        res.authToken
      );
    });
  }

  saveCustoms(request_id) {
    if (this.customsModel == null) {
      this.customsModel = new CustomsModel();
      this.customsModel.id = 0;
    }
    var date = formatDate(new Date(), 'yyyy/MM/dd', 'en');

    this.customsModel.shipping_date =
      this.formData['fechaEmbarque'] != null
        ? this.formData['fechaEmbarque']
        : date;
    this.customsModel.arrival_date =
      this.formData['fechaEstimadaLlegadaSalida'] != null
        ? this.formData['fechaEstimadaLlegadaSalida']
        : date;
    this.customsModel.require_special_permissions = this.formData[
      'permisosEspeciales'
    ]
      ? 1
      : 0;

    if (this.requestModel.service_type == 1) {
      this.customsModel.custom_management_types =
        this.formData['tipoGestionAduanal'] != null
          ? this.formData['tipoGestionAduanal']
          : '0';
    } else {
      this.customsModel.custom_management_types =
        this.formData['tipoGestionAduanale'] != null
          ? this.formData['tipoGestionAduanale']
          : '0';
    }

    this.customsModel.request_service = request_id;
    if (this.formData['servicioGestionAduanal'] != null) {
      this.customsModel.other_services = this.formData[
        'servicioGestionAduanal'
      ].join(',');
    } else {
      this.customsModel.other_services = '';
    }

    //fillers
    this.customsModel.shipping_types = '';
    this.customsModel.product_type = '';
    this.customsModel.special_permissions = '';
    this.customsModel.records_average = '';
    this.customsModel.other_services_desc = '';
    this.customsModel.comments = this.formData['comentario'];
    this.customsModel.user_id = '';
    this.customsModel.created_date = date;
    this.customsModel.status = 1;
    this.customsModel.assigned_user = '';
    this.customsModel.management_type = '';
    this.customsModel.reference = this.formData['referenciaBooking'];
    this.customsModel.mardom_company_id = 0;
    this.customsModel.regimen = this.formData['gestionRegimen'];

    //call customs service.
    if (this.customsModel.id == 0)
      this.customsService.addCustom(this.customsModel);
    else
      this.customsService.updateCustom(this.customsModel.id, this.customsModel);
  }

  saveTransport(request_id) {
    var date = formatDate(new Date(), 'yyyy/MM/dd', 'en');

    if (this.transportModel == null) {
      this.transportModel = new TransportModel();
      this.transportModel.id = 0;
    }
    this.transportModel.quantity =
      this.formData['cantidad'] != null ? this.formData['cantidad'] : 0;
    this.transportModel.dimensions =
      this.formData['dimensionCarga'] != null
        ? this.formData['dimensionCarga']
        : 0;
    this.transportModel.origen =
      this.formData['direccionOrigen'] != null
        ? this.formData['direccionOrigen']['address']
        : '';
    this.transportModel.destiny =
      this.formData['direccionDestino'] != null
        ? this.formData['direccionDestino']['address']
        : '';
    this.transportModel.origen_coordinates =
      this.formData['direccionOrigen'] != null
        ? this.formData['direccionOrigen']['latitude'] +
          '|' +
          this.formData['direccionOrigen']['longitude']
        : ''; //change to text or coordinates
    this.transportModel.destiny_coordinates =
      this.formData['direccionDestino'] != null
        ? this.formData['direccionDestino']['latitude'] +
          '|' +
          this.formData['direccionDestino']['longitude']
        : '';
    this.transportModel.pickup_date =
      this.formData['fechaRecogida'] != null
        ? this.formData['fechaRecogida']
        : date;
    this.transportModel.delivery_time =
      this.formData['horarioRecepcionEntrega'] != null
        ? this.formData['horarioRecepcionEntrega']
        : '';
    this.transportModel.contact_name =
      this.formData['nombreContactoRecibe'] != null
        ? this.formData['nombreContactoRecibe']
        : '';
    this.transportModel.contact_phone =
      this.formData['telefonoContactoRecibe'] != null
        ? this.formData['telefonoContactoRecibe']
        : '';

    if (
      this.formData['requiereParada'] != null &&
      this.formData['requiereParada'] == '1'
    ) {
      this.transportModel.require_stop = true;
    } else {
      this.transportModel.require_stop = false;
    }

    this.transportModel.equipment_type = this.formData['tipoEquipo'] != null ? this.formData['tipoEquipo'] : 0;
    this.transportModel.comment = this.formData['comentario'];
    this.transportModel.cargo_weight = this.formData['peso'];
    this.transportModel.reference_no = this.formData['referenciaBooking'];
    this.transportModel.request_service = request_id;

    //filler fields
    this.transportModel.contact_email = '';
    this.transportModel.transport_type = 0;
    this.transportModel.return_date = date;
    this.transportModel.user_id = '';
    this.transportModel.created_date = date;
    this.transportModel.status = 1;
    this.transportModel.assigned_user = '';
    this.transportModel.mardom_company_id = 0;

    //call transport service.
    if (this.transportModel.id == 0)
      this.transportService.addTransport(this.transportModel);
    else
      this.transportService.updateTransport(
        this.transportModel.id,
        this.transportModel
      );
  }
  async saveImportExport(request_id) {
    if (this.exportimportModel == null) {
      this.exportimportModel = new ImportExport();
      this.exportimportModel.bookingId = 0;
      this.exportimportModel.Status = '1';
      this.exportimportModel.mardom_company_id = 0; //client id?
    }

    var date = formatDate(new Date(), 'yyyy/MM/dd', 'en');

    this.exportimportModel.reference = this.formData['additionalReference'];

    //Import Specific
    this.exportimportModel.shipping_port =
      this.formData['puertoEmbarque'] != null
        ? this.formData['puertoEmbarque']
        : 0;
    this.exportimportModel.landing_port =
      this.formData['puertoDesembarque'] != null
        ? this.formData['puertoDesembarque']
        : 0;
    this.exportimportModel.ship_id =
      this.formData['buqueviaje'] != null ? this.formData['buqueviaje'] : 0;
    this.exportimportModel.cargo_destination = this.formData['destinoCarga'];

    await this.saveContact('forwarder');
    this.exportimportModel.forwarder_id = this.forwarderid;
    await this.saveContact('embarcador');
    this.exportimportModel.shipper_id = this.embarcadorid;

    //Export Specific
    if (
      this.formData['cargaAsegurada'] != null &&
      this.formData['cargaAsegurada'] == '1'
    )
      this.exportimportModel.insured_cargo = true;
    else this.exportimportModel.insured_cargo = false;

    if (
      this.formData['cargaPeligrosa'] != null &&
      this.formData['cargaPeligrosa'] == '1'
    )
      this.exportimportModel.hazmat = true;
    else this.exportimportModel.hazmat = false;

    if (
      this.formData['cargaRefriferada'] != null &&
      this.formData['cargaRefriferada'] == '1'
    )
      this.exportimportModel.refrigerated_cargo = true;
    else this.exportimportModel.refrigerated_cargo = false;

    await this.saveContact('consignatario');
    this.exportimportModel.consignee_id = this.consignatarioid;
    await this.saveContact('notifier');
    this.exportimportModel.notified_id = this.notifierid;

    this.exportimportModel.fumigated = this.formData['fumigacion'];
    this.exportimportModel.line_id =
      this.formData['lineaBooking'] != null
        ? this.formData['lineaBooking']
        : '0';
    this.exportimportModel.payed_by =
      this.formData['pagadoPor'] != null ? this.formData['pagadoPor'] : '0';
    this.exportimportModel.payment_terms =
      this.formData['terminoPago'] != null ? this.formData['terminoPago'] : '0';
    this.exportimportModel.cargo_type =
      this.formData['tipoCarga'] != null ? this.formData['tipoCarga'] : '0';
    this.exportimportModel.export_type =
      this.formData['tipoExportacion'] != null
        ? this.formData['tipoExportacion']
        : '0';
    this.exportimportModel.request_service = request_id;
    this.exportimportModel.container_qty =
      this.formData['cantidadContenedores'] != null
        ? this.formData['cantidadContenedores']
        : 0;

    this.exportimportModel.ventilacion = this.formData['ventilacion'];
    this.exportimportModel.temperatura = this.formData['temperatura'];
    this.exportimportModel.temp_unit = this.formData['tempUnit'];

    this.exportimportModel.humedad = this.formData['humedad'];
    this.exportimportModel.carbonDioxideLevel = this.formData[
      'carbonDioxideLevel'
    ];
    this.exportimportModel.ventilationUnit = this.formData['ventilationUnit'];
    this.exportimportModel.commodity = this.formData['commodity'];
    this.exportimportModel.oxygenLevel = this.formData['oxygenLevel'];

    this.exportimportModel.hazmatnumero_un = this.formData['numeroUN'];
    this.exportimportModel.hazmatcodigo_imo = this.formData['codigoIMO'];
    this.exportimportModel.hazmatcomp_peligroso = this.formData[
      'componentePeligros'
    ];
    this.exportimportModel.hazmatpeso_neto = this.formData['pesoCargaPeligros'];
    this.exportimportModel.hazmatpeso_grueso = this.formData[
      'pesoGruesoCargaPeligros'
    ];
    this.exportimportModel.hazmatflashpoint = this.formData['flashPoint'];
    this.exportimportModel.codigo_empaque = this.formData['codigoPaquete'];
    this.exportimportModel.nombre_emergencia = this.formData[
      'nombreContactoEmergencia'
    ];
    this.exportimportModel.telefono_emergencia = this.formData[
      'telefonoContactoEmergencia'
    ];

    //filler fields
    this.exportimportModel.shipping_date =
      this.formData['fechaEstimadaLlegadaSalida'] != null
        ? this.formData['fechaEstimadaLlegadaSalida']
        : date;
    this.exportimportModel.quotation_number = this.formData['numCotizacion'];
    this.exportimportModel.container_pickupDate =
      this.formData['fechaEstimadaLlegadaSalida'] != null
        ? this.formData['fechaEstimadaLlegadaSalida']
        : date;
    this.exportimportModel.cargo_pickupDate =
      this.formData['fechaEstimadaLlegadaSalida'] != null
        ? this.formData['fechaEstimadaLlegadaSalida']
        : date;
    this.exportimportModel.destination_deliveryId = 0;
    this.exportimportModel.origen_pickupid = 0;
    this.exportimportModel.origen_custom = false;
    this.exportimportModel.destination_custom = false;
    this.exportimportModel.cargo1_id = 0;
    this.exportimportModel.cargo2_id = 0;
    this.exportimportModel.hazmat_id = 0;
    this.exportimportModel.pago_id = 0;
    this.exportimportModel.user_id = '';
    this.exportimportModel.assigned_user = '';
    this.exportimportModel.created_date = date;
    this.exportimportModel.transport_id = 0;
    this.exportimportModel.customs_id = 0;
    this.exportimportModel.special_permission = false;

    //call booking service.
    if (this.exportimportModel.bookingId == 0) {
      this.bookingService.addImportExport(this.exportimportModel);
    } else {
      this.bookingService.updateImportExport(
        this.exportimportModel.bookingId,
        this.exportimportModel
      );
    }
    this.chRef.detectChanges();
  }

  getUploadedDocuments() {
    this.sFileService
      .getServiceFiles('request', this.editableId)
      .subscribe(result => {
        var files = result.map(item => {
          return { id: item.filename, name: item.file_type, realid: item.id };
        });
        this.dbfiles = files;
          var docList=[];
          this.dbfiles.forEach(element => {
            docList.push(element.name)
          });
          this.formData['file']=docList.toString()
          this.chRef.detectChanges();
      });
  }

  getUploadedFiles(files) {
    if (files.target) {
      files = files.target.files;
    }
    this.files = files;
  }

  removeFiledb(index) {
    let fileArray = [];
    for (let key in this.dbfiles) {
      let file = this.dbfiles[key];
      if (key != index) {
        fileArray.push(file);
      } else {
        //if realid exists delete from db
        this.sFileService.deleteServiceFile(file.realid);
      }
    }
    this.dbfiles = fileArray;
  }

  removeFile(index) {
    let fileArray = [];
    for (let key in this.files) {
      let file = this.files[key];
      if (key != index && file instanceof File) {
        fileArray.push(file);
      }
    }
    this.files = fileArray;
  }

  uploadFiles(requestId) {
    this.bookingService.uploadFiles(this.files).subscribe(
      result => {
        var serviceFiles: ServiceFileModel[] = [];

        var canUpload: boolean = false;
        for (let i in result) {
          let file = result[i];
          this.serviceModel = new ServiceFileModel();
          this.serviceModel.service_type = 'request';
          this.serviceModel.service_id = requestId;
          this.serviceModel.filename = file.id;
          this.serviceModel.file_type = file.name;
          serviceFiles.push(this.serviceModel);
          canUpload = true;
        }
        if (canUpload) {
          this.sFileService.addServiceFile(serviceFiles);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  async saveContact(type: string) {
    this.contactModel = new ContactModel();

    switch (type) {
      case 'embarcador': {
        this.contactModel.name =
          this.formData['embarcardorNombre'] != null
            ? this.formData['embarcardorNombre']
            : '';
        this.contactModel.address =
          this.formData['embarcardorDireccion'] != null
            ? this.formData['embarcardorDireccion']
            : '';
        this.contactModel.email =
          this.formData['embarcardorEmail'] != null
            ? this.formData['embarcardorEmail']
            : '';
        this.contactModel.contact_name =
          this.formData['embarcardorPersonaContacto'] != null
            ? this.formData['embarcardorPersonaContacto']
            : '';
        this.contactModel.contact_phone =
          this.formData['embarcardorTelefono'] != null
            ? this.formData['embarcardorTelefono']
            : '';
        this.contactModel.company_phone =
          this.formData['embarcardorTelefono'] != null
            ? this.formData['embarcardorTelefono']
            : '';
        this.contactModel.tax_id = '';
        this.contactModel.contact_type = 0;
        this.contactModel.zipcode = this.formData['embarcardorZipCode'];

        if (
          this.formData['showEmbarquer'] == null ||
          this.formData['showEmbarquer'] == '2'
        )
          this.contactModel.name = '';

        const data = await this.contactService
          .addContact(this.contactModel)
          .toPromise()
          .then(res => {
            this.embarcadorid = res['contact_id'];
          });
        break;
      }
      case 'forwarder': {
        this.contactModel.name =
          this.formData['forwarderNombre'] != null
            ? this.formData['forwarderNombre']
            : '';
        this.contactModel.address =
          this.formData['forwarderDireccion'] != null
            ? this.formData['forwarderDireccion']
            : '';
        this.contactModel.email =
          this.formData['forwarderEmail'] != null
            ? this.formData['forwarderEmail']
            : '';
        this.contactModel.contact_name =
          this.formData['forwarderPersonaContacto'] != null
            ? this.formData['forwarderPersonaContacto']
            : '';
        this.contactModel.company_phone = '';
        this.contactModel.contact_phone = '';
        this.contactModel.tax_id = '';
        this.contactModel.contact_type = 0;
        this.contactModel.zipcode =
          this.formData['forwarderZipCode'] != null
            ? this.formData['forwarderZipCode']
            : '';
        this.contactModel.contact_phone =
          this.formData['forwarderTelefonoContacto'] != null
            ? this.formData['forwarderTelefonoContacto']
            : '';
        this.contactModel.company_phone =
          this.formData['forwarderTelefonoContacto'] != null
            ? this.formData['forwarderTelefonoContacto']
            : '';
        this.contactModel.tax_id =
          this.formData['forwarderTaxId'] != null
            ? this.formData['forwarderTaxId']
            : '';

        if (
          this.formData['showForwarder'] == null ||
          this.formData['showForwarder'] == '2'
        )
          this.contactModel.name = '';

        //call service.
        const data = await this.contactService
          .addContact(this.contactModel)
          .toPromise()
          .then(res => {
            this.forwarderid = res['contact_id'];
          });
        break;
      }
      case 'consignatario': {
        this.contactModel.name =
          this.formData['consignatarioNombreEmpresa'] != null
            ? this.formData['consignatarioNombreEmpresa']
            : '';
        this.contactModel.email =
          this.formData['consignatarioEmail'] != null
            ? this.formData['consignatarioEmail']
            : '';
        this.contactModel.address =
          this.formData['consignatarioDireccion'] != null
            ? this.formData['consignatarioDireccion']
            : '';
        this.contactModel.contact_name =
          this.formData['consignatarioPersonaContacto'] != null
            ? this.formData['consignatarioPersonaContacto']
            : '';
        this.contactModel.contact_phone =
          this.formData['consignatarioTelefonoContacto'] != null
            ? this.formData['consignatarioTelefonoContacto']
            : '';
        this.contactModel.company_phone =
          this.formData['consignatarioTelefonoContacto'] != null
            ? this.formData['consignatarioTelefonoContacto']
            : '';
        this.contactModel.tax_id =
          this.formData['consignatarioTaxId'] != null
            ? this.formData['consignatarioTaxId']
            : '';
        this.contactModel.zipcode =
          this.formData['consignatarioZipCode'] != null
            ? this.formData['consignatarioZipCode']
            : '';
        this.contactModel.contact_type = 0;

        if (
          this.formData['showConsignatary'] == null ||
          this.formData['showConsignatary'] == '2'
        )
          this.contactModel.name = '';

        //call service.
        const data = await this.contactService
          .addContact(this.contactModel)
          .toPromise()
          .then(res => {
            this.consignatarioid = res['contact_id'];
          });
        break;
      }
      case 'notifier': {
        this.contactModel.name =
          this.formData['notificarNombre'] != null
            ? this.formData['notificarNombre']
            : '';
        this.contactModel.address =
          this.formData['notificarDireccion'] != null
            ? this.formData['notificarDireccion']
            : '';
        this.contactModel.email =
          this.formData['notificarEmail'] != null
            ? this.formData['notificarEmail']
            : '';
        this.contactModel.contact_name =
          this.formData['notificarPersonaContacto'] != null
            ? this.formData['notificarPersonaContacto']
            : '';
        this.contactModel.contact_phone =
          this.formData['notificarTelefonoEmpresa'] != null
            ? this.formData['notificarTelefonoEmpresa']
            : '';
        this.contactModel.company_phone =
          this.formData['notificarTelefonoEmpresa'] != null
            ? this.formData['notificarTelefonoEmpresa']
            : '';
        this.contactModel.tax_id =
          this.formData['notificarTaxId'] != null
            ? this.formData['notificarTaxId']
            : '';

        if (
          this.formData['showNotificationInfo'] == null ||
          this.formData['showNotificationInfo'] == '2'
        )
          this.contactModel.name = '';

        //call service.
        const data = await this.contactService
          .addContact(this.contactModel)
          .toPromise()
          .then(res => {
            this.notifierid = res['contact_id'];
          });
        break;
      }
      default: {
        break;
      }
    }
  }

  loadFields(){
    this.chRef.detectChanges();
  }
  getEquipmentData(){
  let url=formFields.find(x=>x.value=='tipoEquipo').endPoint
   this.bookingService
        .getEquOptions(url)
        .toPromise()
        .then((result: any) => {
          this.EquipmentTypeData=result;
          const id=this.formData['tipoEquipo']
          const data=this.EquipmentTypeData.find(x=>x.value==id).description
          this.formData['tipoEquipo']=data
          this.tipoEquipof2nd=data
        })
  }
}
