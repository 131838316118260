export class ITransportEquipment {
    commodity:          string;
    description:        string;
    destination:        string;
    origin:             string;
    equipment:          string;
    equipmentNumber:    string;
    equipmentType:      string;
    hazardousCode:      string;
    height:             number;
    length:             number;
    numberOfEquipments: number;
    numberOfPieces:     number;
    pONumber:           string;
    packingType:        string;
    refrigerated:       boolean;
    temperature:        number;
    temperatureUnit:    string;
    unitOfMeasurement:  string;
    weight:             number;
    weightUnit:         string;
    width:              number;
}