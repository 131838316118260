// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
// Auth
import { AuthGuard } from './core/auth';
import { LoginComponent } from './views/session/login/login.component';
import { PanelComponent } from './components/dashboard/components/panel/panel.component';
import { MdmaincontainerComponent } from './views/layout/mdmaincontainer/mdmaincontainer.component';
import { LocateCargoComponent } from './views/locate-cargo/locate-cargo.component';
import { UserProfileComponent } from './views/user-profile/user-profile.component';
import { UserInfoComponent } from './views/user-info/user-info.component';
import { RegisterComponent } from './views/register/register.component';
import { BookingComponent } from './components/service-request/components/booking/booking.component';
import { SearchComponent } from './components/service-request/components/search/search.component';
import { BlankpageComponent } from './views/blankpage/blankpage.component';
import { StorageComponent } from './views/storage/storage.component';
import { QuotationComponent } from './views/quotation/quotation.component';
import { EstimateComponent } from './views/estimate/estimate.component';
import { VoucherComponent } from './views/voucher/voucher.component';
import { StorageDetailComponent } from './views/storage-detail/storage-detail.component';
import { LocateResultComponent } from './views/locate-result/locate-result.component';
import { MessageComponent } from './views/message/message.component';
import { ExitstorageComponent } from './views/exitstorage/exitstorage.component';
import { PermissionGuard } from './core/auth/_guards/permission.guard';
import { FormGuard } from './core/auth/_guards/form.guard';
import { ShareOrderComponent } from './views/dashboard-services/share-order/share-order.component';
import { InvoicesComponent } from './components/dashboard/components/invoices/invoices.component';
import { MyProfileComponent } from './views/my-profile/my-profile.component';
import { PasswordRecoveryComponent } from './views/session/password-recovery/password-recovery.component'
import { SignupComponent } from './views/session/signup/signup.component'
import { CustomModeBookingComponent } from '../app/components/service-request/components/custom-mode-booking/custom-mode-booking.component';
import { SlaFormComponent } from '../app/views/configureSLA/sla-form/sla-form.component'

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'order',
    component: ShareOrderComponent,
    pathMatch: 'full'
  },
  {
    path: 'order/:type/:sharing',
    component: ShareOrderComponent,
    pathMatch: 'full'
  },
  {
    path: 'order/:type',
    component: ShareOrderComponent,
    pathMatch: 'full'
  },
  {
    path: '',
    component: MdmaincontainerComponent,
    children: [
      {
        path: 'cargo',
        component: LocateCargoComponent,
        canActivate: [AuthGuard],
        data: {
          permission: 'accessToMyLocation'
        }
      },
      {
        path: 'panel1',
        component: PanelComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          permission: 'accessToDashboard accessToMyLocation canReadServiceRequest canSeePaymentReceipt canSendMessages'
        }
      },
      {
        path: 'invoices',
        component: InvoicesComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          permission: 'accessToDashboard canAttachDocument canSeeAttachedDocuments canPrintInvoice'
        }
      },
      {
        path: 'profile',
        component: UserProfileComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'userinfo',
        component: UserInfoComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'register',
        component: RegisterComponent
      },
      {
        path: 'booking',
        component: BookingComponent,
        canActivate: [AuthGuard, PermissionGuard],
        canDeactivate: [FormGuard],
        data: {
          permission: 'canReadServiceRequest canCreateImportServiceRequest canCreateExportServiceRequest canCreateLogisticServiceRequest'
        }
      },
      {
        path: 'booking/export-type',
        component: BookingComponent,
        canActivate: [AuthGuard, PermissionGuard],
        canDeactivate: [FormGuard],
        data: {
          permission: 'canCreateExportServiceRequest'
        }
      },
      {
        path: 'booking/import-type',
        component: BookingComponent,
        canActivate: [AuthGuard, PermissionGuard],
        canDeactivate: [FormGuard],
        data: {
          permission: 'canCreateImportServiceRequest'
        }
      },
      {
        path: 'booking/customs-type',
        component: BookingComponent,
        canActivate: [AuthGuard, PermissionGuard],
        canDeactivate: [FormGuard],
        data: {
          permission: 'canCreateLogisticServiceRequest'
        }
      },
      {
        path: 'booking/customstype-booking',
        component: CustomModeBookingComponent,
        canActivate: [AuthGuard, PermissionGuard],
        canDeactivate: [FormGuard],
        data: {
          permission: 'canCreateLogisticServiceRequest'
        }
      },
      {
        path: 'search',
        component: SearchComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          permission: 'canReadServiceRequest'
        }
      },
      {
        path: 'storeage',
        component: StorageComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          permission: 'canReadWarehouseRequestsIn'
        }
      },
      {
        path: 'exitstoreage',
        component: ExitstorageComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          permission: 'canReadWarehouseRequestsOut'
        }
      },
      {
        path: 'storageDetail/:type',
        component: StorageDetailComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          permission: 'canReadWarehouseRequestsIn canReadWarehouseRequestsOut'
        }
      },
      {
        path: 'quotation',
        component: QuotationComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'estimate',
        component: EstimateComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          permission: 'accessToDesconsolidationEstimate'
        }
      },
      {
        path: 'voucher',
        component: VoucherComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          permission: 'accessToReceipt'
        }
      },
      {
        path: 'locateBy/:type',
        component: LocateResultComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'message',
        component: MessageComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          permission: 'canSendMessages'
        }
      },
      {
        path: 'my-profile',
        component: MyProfileComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'configureSLA',
        pathMatch: 'full',
        component: SlaFormComponent,

      },
      {
        path: '',
        loadChildren: () => import('./views/transfer-request/transfer-request.module').then(m => m.TransferRequestModule)
      },
      {
        path: '',
        loadChildren: () => import('./views/product/product.module').then(m => m.ProductModule)
      },
      {
        path: '',
        loadChildren: () => import('./views/warehouse/warehouse.module').then(m => m.WarehouseModule)
      },
      {
        path: '',
        loadChildren: () => import('./views/user-managements/user-management.module').then(m => m.UserManagementModule)
      },
      {
        path: '',
        loadChildren: () => import('./views/dashboard-services/dashboard-services.module').then(m => m.DashboardServicesModule)
      },
    ]
  },
  {
    path: 'blank',
    component: BlankpageComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'password-recovery',
    pathMatch: 'full',
    component: PasswordRecoveryComponent,
  },
  {
    path: 'signup',
    pathMatch: 'full',
    component: SignupComponent,
  },

  {
    path: 'error/:errorType',
    component: ErrorPageComponent
  },
  { path: '**', redirectTo: 'error/403', pathMatch: 'full' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
