import { PermissionsEnum } from './permissions.table'

export class RolesTable {
	public static roles: any[] = [
        {
            id: 1,
            title: 'Client',
            isCoreRole: true,
            permissions: [
                PermissionsEnum.accessToDashboard,
                PermissionsEnum.accessToMyLocation,
                PermissionsEnum.canReadWarehouseRequestsIn,
                PermissionsEnum.canCreateWarehouseRequestsIn,
                PermissionsEnum.canReadWarehouseRequestsOut,
                PermissionsEnum.canCreateWarehouseRequestsOut,
                PermissionsEnum.canReadServiceRequest,
                PermissionsEnum.canCreateImportServiceRequest,
                PermissionsEnum.canCreateExportServiceRequest,
                PermissionsEnum.canCreateLogisticServiceRequest,
                PermissionsEnum.accessToDesconsolidationEstimate,
                PermissionsEnum.accessToReceipt,
                PermissionsEnum.canSendMessages,
                PermissionsEnum.canSeePaymentReceipt,
                PermissionsEnum.canUploadPaymentReceipt,
                PermissionsEnum.canAttachDocument,
                PermissionsEnum.canSeeAttachedDocuments,
                PermissionsEnum.canPrintInvoice,
                PermissionsEnum.canUseSaveButtomRequestServices
            ]
        },
        {
            id: 2,
            title: 'coordinator',
            isCoreRole: true,
			permissions: [
                PermissionsEnum.accessToDashboard,
                PermissionsEnum.accessToMyLocation,
                PermissionsEnum.canReadWarehouseRequestsIn,
                PermissionsEnum.canReadWarehouseRequestsOut,
                PermissionsEnum.accessToDesconsolidationEstimate,
                PermissionsEnum.canChangeRequestStatusAndResponsible,
                PermissionsEnum.canReadServiceRequest,
                PermissionsEnum.accessToReceipt,
                PermissionsEnum.canSeePaymentReceipt,
                PermissionsEnum.canAttachDocument,
                PermissionsEnum.canSeeAttachedDocuments,
                PermissionsEnum.canPrintInvoice,
                PermissionsEnum.canUseSaveButtomRequestServices,
                PermissionsEnum.canUploadPaymentReceipt
            ]
        },
        {
            id: 3,
            title: 'integrated-logistics-booking',
            isCoreRole: true,
			permissions: [
                PermissionsEnum.accessToDashboard,
                PermissionsEnum.accessToMyLocation,
                PermissionsEnum.canReadServiceRequest,                
            ]
        }, 
        {
            id: 4,
            title: 'transport',
            isCoreRole: true,
			permissions: [
                PermissionsEnum.accessToDashboard,                
                PermissionsEnum.canReadWarehouseRequestsIn,                
                PermissionsEnum.canReadWarehouseRequestsOut,                
                PermissionsEnum.canReadServiceRequest,
            ]
        }, 
        {
            id: 5,
            title: 'customs',
            isCoreRole: true,
			permissions: [                
                PermissionsEnum.accessToDashboard,
                PermissionsEnum.accessToMyLocation,
                PermissionsEnum.canReadServiceRequest,
            ]
        }, 
        {
            id: 6,
            title: 'warehouse',
            isCoreRole: true,
			permissions: [
                PermissionsEnum.accessToDashboard,
                PermissionsEnum.accessToMyLocation,
                PermissionsEnum.canReadWarehouseRequestsIn,                
                PermissionsEnum.canReadWarehouseRequestsOut,                
                PermissionsEnum.canReadServiceRequest
            ]
        }, 
        {
            id: 7,
            title: 'commercial',
            isCoreRole: true,
			permissions: [
                PermissionsEnum.accessToDashboard,
                PermissionsEnum.canReadServiceRequest,
                PermissionsEnum.accessToDesconsolidationEstimate,                
            ]
        }, 
        {
            id: 7,
            title: 'collections-coordinator',
            isCoreRole: true,
			permissions: [
                PermissionsEnum.accessToDashboard,
                PermissionsEnum.accessToReceipt,
                PermissionsEnum.canSeePaymentReceipt,                
                PermissionsEnum.canChangeRequestStatusAndResponsible,
                PermissionsEnum.canUploadPaymentReceipt,
                PermissionsEnum.canAttachDocument,
                PermissionsEnum.canSeeAttachedDocuments,
                PermissionsEnum.canAttachDocument,
                PermissionsEnum.canSeeAttachedDocuments,
                PermissionsEnum.canPrintInvoice,
            ]
        }, 
        {
            id: 8,
            title: 'admin',
            isCoreRole: true,
            permissions: [
                PermissionsEnum.accessToDashboard,
                PermissionsEnum.accessToMyLocation,
                PermissionsEnum.canReadWarehouseRequestsIn,
                PermissionsEnum.canCreateWarehouseRequestsIn,
                PermissionsEnum.canReadWarehouseRequestsOut,
                PermissionsEnum.canCreateWarehouseRequestsOut,
                PermissionsEnum.canReadServiceRequest,
                PermissionsEnum.canCreateImportServiceRequest,
                PermissionsEnum.canCreateExportServiceRequest,
                PermissionsEnum.canCreateLogisticServiceRequest,
                PermissionsEnum.accessToDesconsolidationEstimate,
                PermissionsEnum.accessToReceipt,
                PermissionsEnum.canChangeRequestStatusAndResponsible,
                PermissionsEnum.canSendMessages,
                PermissionsEnum.canSeePaymentReceipt,
                PermissionsEnum.canUploadPaymentReceipt,
                PermissionsEnum.canAttachDocument,
                PermissionsEnum.canSeeAttachedDocuments,
                PermissionsEnum.canPrintInvoice,
                PermissionsEnum.canUseSaveButtomRequestServices
            ]
        },

    ];
}