export const ResponseMessages: {
  code: string
  language: string
  message: string
}[] = [
    {
      code: 'access_denied',
      language: 'es',
      message: 'Usuario o contraseña inválidos.',
    },
    {
      code: 'too_many_attempts',
      language: 'es',
      message: `Su cuenta ha sido bloqueada debido a que ha intentado acceder demasiadas veces, 
      se le ha enviado un correo con las instrucciones para desbloquearla.`,
    },
    {
      code: 'password_change_successful',
      language: 'es',
      message: `Se ha enviado el correo de cambio de contraseña a la dirección de Email suministrada.`,
    },
  ]
