import { Component, ViewChild, TemplateRef, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import * as jwt_decode from 'jwt-decode';
import { AppState } from '../../../core/reducers/index';
import { currentUserRoleIds, selectAuthState } from '../../../core/auth/_selectors/auth.selectors';
import { selectAllPermissions } from '../../../core/auth';
import { SidebarService } from '../../../services/sidebar.service';
import { Router } from '@angular/router';
import { orderInProgressService } from '../../dashboard-services/orders-in-progress/orders-in-progress.service';
import { Subscription } from 'rxjs/Subscription';
import { DashboardServicesService } from '../../../services/dashboard-services/dashboard-services.service';
import { type } from 'os';
import { TrackingServiceService } from '../../../services/tracking-service.service';
import { select } from '@ngrx/store';
import { LogService } from '../../../services/log/log.service';
import { LogDto } from '../../../models/dto/log.dto';
import { Action } from '../../../models/enums/action.enum';
import { Screen } from '../../../models/enums/screen.enum';
import { PowerBILogService } from '../../../services/log/powerBILog.service';


@Component({
  selector: 'kt-mdmaincontainer',
  templateUrl: './mdmaincontainer.component.html',
  styleUrls: ['./mdmaincontainer.component.scss']
})
export class MdmaincontainerComponent implements OnInit {
  @ViewChild('calificar', { static: true }) calificar: TemplateRef<any>;
  subscription: Subscription;
  showMobileMenu: boolean;
  public payload$: any;
  public module: any;
  public objectType: any;
  public objectName: any;
  public clickEvent: any;
  public action: any;
  public screenId: any;
  public currentRoles: any;


  constructor(
    private store: Store<AppState>,
    private sidebarService: SidebarService,
    private orderinprogress: orderInProgressService,
    private toastrService: ToastrService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private modalService: NgbModal,
    private dashboardServices: TrackingServiceService,
    private logService: LogService,
    private powerBIService: PowerBILogService
  ) {
    this.sidebarService.sidebarVisibilityChange.subscribe((value) => {
      this.showMobileMenu = value;
    });
    this.router.events.subscribe(() => {
      this.sidebarService.closeSideBar();
    })
    // this.subscription = this.orderinprogress.getData().subscribe(x => {
    //   console.log(x)
    //   this.Calificarbtn = x.calificar
    //   this.blnum = x.blnumber
    // });
  }
  Almacen: boolean = false
  isExpandAlmacen: boolean = false
  isExpandAlmaceninner: boolean = false
  isExpandSolicitudTrasaloda: boolean = false
  isExpandSolicitudTrasalodainner: boolean = false
  isExpandSolicitudServicio: boolean = false
  isExpandSolicitudServicioinner: boolean = false
  EntradaAlmacen: boolean = false
  public commentsdisable: boolean = false;
  public comments: any;
  DespachoAlmacen: boolean = false
  SolicitudServicio: boolean = false
  SolicitudTrasaloda: boolean = false
  adminFilter = false;
  ratingstardisable: boolean = false;
  ratingstar: any = {};
  permissions: any[]
  isExpand: boolean = true;
  isCosigner: boolean = true;
  iscoordinator: boolean = true;
  isConsolidador: boolean = true;
  ratingcomments: boolean = false;
  reasondisable: boolean = false;
  Calificarbtn: any;
  blnum: "";
  type: any;
  currentTab = 0;
  currentButton = 0;
  checked: boolean = false;
  others: any;
  value: any;
  comment: any;
  stars: any;
  reasons: any
  ratingstars: any;
  othersdisable: boolean = false;



  ngOnInit() {
    this.checkUserRole();
    this.payload$ = this.store.pipe(select(selectAuthState));
    let menuState = localStorage.getItem('nav-condition');
    if (menuState == 'colapse') this.isExpand = false;
    this.store.select(selectAllPermissions).subscribe(
      res => {
        this.permissions = res
      }
    );
  }

  changemenu() {
    this.isExpand = !this.isExpand
    if (this.isExpand == true) {
      this.isExpandAlmaceninner = true
      this.isExpandSolicitudServicioinner = true
      this.isExpandSolicitudTrasalodainner = true
      localStorage.setItem('nav-condition', "expand");
    } else {
      this.isExpandAlmaceninner = false
      this.isExpandSolicitudServicioinner = false
      this.isExpandSolicitudTrasalodainner = false
      localStorage.setItem('nav-condition', "colapse");
    }
  }

  hasAccess(moduleAction: string): boolean {
    return this.permissions.findIndex(permission => permission.name === moduleAction) > -1
  }
  makeacall() {
    this.screenId = Screen.AccesoPlataforma;
    this.module = "AccesoPlataforma"
    this.clickEvent = "Click";
    this.action = "Redirect";
    this.objectType = "Button";
    this.objectName = "Ayuda";
    this.powerBIService.createPayload(this.screenId, this.module, this.clickEvent, this.action, this.objectType, this.objectName, null, null, null);
    window.open("https://wa.me/8095396000", "_blank");
  }

  checkUserRole() {
	this.store.select(selectAuthState).subscribe((result) => {
		var decoded = jwt_decode(result.authToken);
		console.log('decoded');
		console.log(decoded);

		let recompany = JSON.parse(localStorage.getItem("Selectedcompany"));
		if (recompany && recompany.roles) {
			this.currentRoles = recompany.roles;
			console.log('currentRolesNuevo');
			console.log(this.currentRoles);
		} else {
			this.currentRoles = decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
			console.log('currentRolesViejo');
			console.log(this.currentRoles);
		}

		var internal = this.currentRoles.indexOf("Client");
		let cosigneId = this.currentRoles.indexOf("consignatario");
		let coordinatorId = this.currentRoles.indexOf("coordinator");
		let consolidadorId = this.currentRoles.indexOf("consolidador");

		if (!(consolidadorId > -1)) {
			this.isConsolidador = false;
		} else {
			this.isConsolidador = true;
		}

		if (!(cosigneId > -1)) {
			this.isCosigner = false;
		} else {
			this.isCosigner = true;
		}

		if (!(coordinatorId < -1)) {
			this.iscoordinator = false;
		} else {
			this.iscoordinator = true;
		}

		if (!(internal > -1)) {
			this.adminFilter = true;
		}
		else {
			this.adminFilter = false;
		}
		this.checkRoute()
	})
}

  ratesystempopup() {
    this.screenId = Screen.AccesoPlataforma;
    this.module = "AccesoPlataforma"
    this.clickEvent = "Click";
    this.action = "Rate";
    this.objectType = "Button";
    this.objectName = "Calificar Servicio";
    this.powerBIService.createPayload(this.screenId, this.module, this.clickEvent, this.action, this.objectType, this.objectName, null, null, null);

    this.Buttonsteps()
    this.currentButton = 0;
    this.ratingstar.ratings = 0
    this.modalService.open(this.calificar, {
      size: 'm', backdrop: 'static', centered: true
    });
    this.dashboardServices.getratingcount("", "2").subscribe(response => {
      if (response.length > 0) {
        response.forEach(element => {
          this.ratingstar = element
          this.comments = element.comment
          this.commentsdisable = true
          this.ratingstardisable = element.ratings ? true : false;
          this.reasondisable = true;
          this.othersdisable = true;
          this.value = element.reason;
          this.cd.detectChanges()
        });
      } else {
        this.ratingstardisable = false
        this.commentsdisable = false
        this.reasondisable = false;
        this.othersdisable = false;
      }
    })
  }

  cancel() {
    this.modalService.dismissAll();
    this.comments = ""
    this.currentTab = 0;
    this.currentButton = 0;
  }

  enableratingcomments(event) {
    this.ratingstar.ratings = event
    this.stars = event;
    this.commentsdisable = false;
  }

  ratesystem() {
    let data = {
      BlNo: "",
      Ratings: this.ratingstar.ratings ? this.ratingstar.ratings : this.stars,
      Comment: this.comment,
      Reason: this.value,
      IsSystem: true,
      Type: "2",
    }
    this.dashboardServices.ratethesystem(data).subscribe(response => {
    }, (error) => {
      this.toastrService.success("Ha calificado correctamente el sistema");
    })
  }

  shownext1() {
    this.Buttonsteps();
    this.fixStepIndicator();
    this.cd.detectChanges();
  }

  Continuar(val) {
    this.checked = val;
    this.value = val == null ? this.others : val;
    this.Buttonsteps();
    this.fixStepIndicator();
    this.cd.detectChanges();
  }

  finalizar() {
    this.comment = this.comments;
    this.Buttonsteps()
    this.fixStepIndicator();
  }

  fixStepIndicator() {
    if (this.currentTab <= 3) {
      this.currentTab++;
    }
    this.cd.detectChanges();
  }

  Buttonsteps() {
    if (this.currentButton <= 3) {
      this.currentButton++;
    }
    this.cd.detectChanges();
  }

  checkRoute() {
    const path = this.router.url;
    if (path.startsWith('/warehouse-entry')
      || path.startsWith('/storeage')
      || path.startsWith('/product') || path.startsWith('/productlist')) {
      this.findPath("Entrada")
    } else if (path.startsWith('/exitstoreage')
      || path.startsWith('/warehouse-dispatch')) {
      this.findPath("Despacho")
    }
  }
  public findPath(type) {
    const path = this.router.url;
    if (type == "Entrada")
      if (path.startsWith('/warehouse-entry')
        || path.startsWith('/storeage')
        || path.startsWith('/product') || path.startsWith('/productlist')) {
        this.EntradaAlmacen = true
      } else {
        this.EntradaAlmacen = !this.EntradaAlmacen
      } else if (type == "Despacho") {
        if (path.startsWith('/exitstoreage')
          || path.startsWith('/warehouse-dispatch')) {
          this.DespachoAlmacen = true
        } else {
          this.DespachoAlmacen = !this.DespachoAlmacen
        }
      }
  }
}
