import serviceConfig from '../../../../services/service-config';

export const fieldGroups = {
  general: {
    title: 'Información general'
  },
  import: {
    title: 'Información de la carga',
  },
  export: {
    title: 'Información de la carga',
  },
  contact: {
    title: 'Información de contacto',
  },
  dangerCargo: {
    title: 'Sobre la carga peligrosa'
  },
  consignatary: {
    title: 'Consignatario'
  },
  cooling: {
    title: 'Refrigerada'
  },
  embarquer: {
    title: 'Embarcador'
  },
  forwarder: {
    title: 'Forwarder'
  },
  notificationInfo: {
    title: 'Notificar a'
  },
  contactInfoReception: {
    title: 'Contacto recepción'
  },
  transport: {
    title: 'Transporte'
  },
  costums: {
    title: 'Aduanas'
  },
  contactInfo: {
    title: 'Contacto'
  }
}

export const groupOrder = {
  general: [
    'general'
  ],
  export: [
    'consignatary',
    'cooling',
    'dangerCargo',
    'embarquer',
    'export',
    'forwarder',
    'notificationInfo'
  ],
  import: [
    'embarquer',
    'export'
  ],
  costums: [
    'export'
  ],
  transport: [
    'transport',
    'contactInfo'
  ]
}

export const formFields = [
  {
    label: 'Puerto de Embarque',
    value: 'puertoEmbarque',
    css: '',
    group: 'export',
    type: 'select',
    required: true,
    isInvalid: false,
    endPoint: serviceConfig.endPoints.ports,
    api: 'new',
    options: [
      {
        id: 1,
        text: 'ND'
      }
    ],
    display: {
      export: {
        import: false
      },
      import: {
        always: true
      },
      costums: {
        import: false,
        export: false
      }

    },
    condition: false
  },
  {
    label: 'Líneas Navieras',
    value: 'lineaBooking',
    css: '',
    group: 'export',
    type: 'select',
    required: false,
    isInvalid: false,
    options: [
      {
        id: 1,
        text: 'Geest'
      },
      {
        id: 2,
        text: 'Baltic'
      },
      {
        id: 3,
        text: 'Cosiarma'
      },
      {
        id: 4,
        text: 'Freight Forwarding'
      }
    ],
    display: {
      export: {
        always: true
      }

    },
    condition: false
  },
  {
    label: 'Puerto de Desembarque',
    value: 'puertoDesembarque',
    css: '',
    group: 'export',
    type: 'select',
    required: true,
    isInvalid: false,
    endPoint: serviceConfig.endPoints.ports,
    api: 'new',
    options: [
      {
        id: 1,
        text: 'ND'
      }
    ],
    display: {
      export: {
        import: false,
        costums: false
      },
      import: {
        always: true
      },
      costums: {
        import: false,
        export: false
      }

    },
    condition: false
  }
  ,
  {
    label: 'Buque y Viaje',
    value: 'buqueviaje',
    css: '',
    group: 'export',
    type: 'select',
    endPoint: serviceConfig.endPoints.ships,
    api:'new',
    options: [
      {
        id: 1,
        text: 'ND'
      }
    ],
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: [{
        field: 'lineaBooking',
        value: 4,
        operator: 'neq'
      }
    ]
    }
  }
  ,
  {
    label: 'Tipo de Servicio',
    value: 'tipoServicio',
    css: '',
    group: 'general',
    type: 'select',
    required: true,
    isInvalid: false,
    options: [
      {
        id: 1,
        text: 'Importación'
      },
      {
        id: 2,
        text: 'Exportación'
      }
    ],
    display: {
      general: {
        always: true
      }

    },
    condition: false
  },
  {
    label: 'Tipo de Carga',
    value: 'tipoCarga',
    css: '',
    group: 'export',
    type: 'select',
    required: false,
    isInvalid: false,
    endPoint: serviceConfig.endPoints.cargotype,
    endPointParams: { },
    options: [
      {
        id: 1,
        text: 'Carga Suelta'
      },
      {
        id: 2,
        text: 'A Granel'
      },
      {
        id: 3,
        text: 'Aerea'
      }
    ],
    display: {
      transport: {
        always: true
      },
      costums: {
        transport: false
      },
      import: {
        costums: false
      },
      export: {
        import: false
      }

    },
    condition: {
      export: [{
        field: 'lineaBooking',
        value: 4,
        operator: 'eq'
      }
    ]
    }
  },
  {
    label: 'Fecha de embarque',
    value: 'fechaEmbarque',
    css: '',
    group: 'export',
    type: 'date',
    required: false,
    isInvalid: false,
    display: {
      export: {
        always: true
      }

    },
    condition: {
      export: [{
        field: 'lineaBooking',
        value: 4,
        operator: 'neq'
      }
    ]
    }
  },
   
  // {
  //   label: 'No. cotización',
  //   value: 'numCotizacion',
  //   css: '',
  //   group: 'general',
  //   type: 'text',
  //   display: {
  //     general: {
  //       always: true
  //     }

  //   },
  //   condition: false
  // },
  // {
  //   label: 'Cantidad de contenedores',
  //   value: 'cantidadContenedores',
  //   css: '',
  //   group: 'general',
  //   type: 'number',
  //   display: {
  //     general: {
  //       always: true
  //     }

  //   },
  //   condition: false
  // },
  // {
  //   label: 'Cantidad de bultos',
  //   value: 'cantidadBultos',
  //   css: '',
  //   group: 'general',
  //   type: 'number',
  //   required: false,
  //   isInvalid: false,
  //   display: {
  //     general: {
  //       always: true
  //     }
  //   },
  //   condition: false
  // },
  {
    label: 'Dimensión carga',
    value: 'dimensionCarga',
    css: '',
    group: 'export',
    type: 'text',
    display: {
      transport: {
        always: true
      },
      import: {
        transport: false
      },
      export: {
        import: false
      }

    },
    condition: {
      export: [{
        field: 'lineaBooking',
        value: 4,
        operator: 'eq'
      }
    ]
    }
  },
  {
    label: 'Destino final de la carga',
    value: 'destinoCarga',
    css: '',
    group: 'export',
    type: 'text',
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: [{
        field: 'lineaBooking',
        value: 4,
        operator: 'neq'
      }
    ]
    }
  },
  // {
  //   label: 'Tipo de Bultos',
  //   value: 'tipoBultos',
  //   css: '',
  //   group: 'general',
  //   type: 'select',
  //   required: false,
  //   isInvalid: false,
  //   endPoint: serviceConfig.endPoints.packingTypes,
  //   endPointParams: { },
  //   options: [

  //   ],
  //   display: {
  //     general: {
  //       always: true
  //     }

  //   },
  //   condition: false
  // },
  // {
  //   label: 'Peso',
  //   value: 'peso',
  //   css: '',
  //   group: 'general',
  //   type: 'weight',
  //   required: true,
  //   isInvalid: false,
  //   display: {
  //     general: {
  //       always: true
  //     }
  //   },
  //   condition: false
  // },
  {
    label: 'Descripción de la Mercancia',
    value: 'descripcionBL',
    css: '',
    group: 'general',
    type: 'text',
    required: true,
    isInvalid: false,
    display: {
      general: {
        always: true
      }
    },
    condition: false
  },
  {
    label: 'No. BL/Booking',
    value: 'referenciaBooking',
    css: '',
    group: 'general',
    type: 'text',
    required: true,
    isInvalid: false,
    display: {
      general: {
        always: true
      }
    },
    condition: false
  },
  // {
  //   label: 'Referencia',
  //   value: 'additionalReference',
  //   css: '',
  //   group: 'general',
  //   type: 'text',
  //   display: {
  //     general: {
  //       always: true
  //     }
  //   },
  //   condition: false
  // },
  {
    label: 'Comentario',
    value: 'comentario',
    css: '',
    group: 'general',
    type: 'text',
    display: {
      general: {
        always: true
      }
    },
    condition: false
  },
  {
    label: 'Desea incluir datos del embarcador?',
    value: 'showEmbarquer',
    defaultValue: 2,
    css: '',
    group: 'embarquer',
    type: 'select',
    options: [
      {
        id: 1,
        text: 'Si'
      },
      {
        id: 2,
        text: 'No'
      }
    ],
    display: {
      import: {
        always: true
      },
      export: {
        import: false
      }
    },
    condition: {
      export: [{
        field: 'lineaBooking',
        value: 4,
        operator: 'neq'
      }
    ]
    }
  },
  {
    label: 'Nombre',
    value: 'embarcardorNombre',
    css: '',
    group: 'embarquer',
    type: 'text',
    required: false,
    isInvalid: false,
    display: {
      import: {
        always: true
      },
      export: {
        import: false
      }
    },
    condition: {
      export: [
      {
        field: 'showEmbarquer',
        value: 1,
        operator: 'eq'
      }
    ]
    }
  },
  {
    label: 'Dirección',
    value: 'embarcardorDireccion',
    css: '',
    group: 'embarquer',
    type: 'text',
    required: false,
    isInvalid: false,
    display: {
      import: {
        always: true
      },
      export: {
        import: false
      }
    },
    condition: {
      export: [
      {
        field: 'showEmbarquer',
        value: 1,
        operator: 'eq'
      }
    ]
    }
  },
  {
    label: 'ZIP Code',
    value: 'embarcardorZipCode',
    css: '',
    group: 'embarquer',
    type: 'text',
    required: false,
    isInvalid: false,
    display: {
      import: {
        always: true
      },
      export: {
        import: false
      }
    },
    condition: {
      export: [
      {
        field: 'showEmbarquer',
        value: 1,
        operator: 'eq'
      }
    ]
    }
  },
  {
    label: 'Persona Contacto',
    value: 'embarcardorPersonaContacto',
    css: '',
    group: 'embarquer',
    type: 'text',
    required: false,
    isInvalid: false,
    display: {
      import: {
        always: true
      },
      export: {
        import: false
      }
    },
    condition: {
      export: [
      {
        field: 'showEmbarquer',
        value: 1,
        operator: 'eq'
      }
    ]
    }
  },
  {
    label: 'Email',
    value: 'embarcardorEmail',
    css: '',
    group: 'embarquer',
    type: 'text',
    required: false,
    isInvalid: false,
    display: {
      import: {
        always: true
      },
      export: {
        import: false
      }
    },
    condition: {
      export: [
      {
        field: 'showEmbarquer',
        value: 1,
        operator: 'eq'
      }
    ]
    }
  },
  {
    label: 'Teléfono',
    value: 'embarcardorTelefono',
    mask:'(000) 000-0000',
    css: '',
    group: 'embarquer',
    type: 'text',
    required: false,
    isInvalid: false,
    display: {
      import: {
        always: true
      },
      export: {
        import: false
      }
    },
    condition: {
      export: [
      {
        field: 'showEmbarquer',
        value: 1,
        operator: 'eq'
      }
    ]
    }
  },
  {
    label: 'Desea incluir datos del forwarder?',
    value: 'showForwarder',
    css: '',
    group: 'forwarder',
    defaultValue: 2,
    type: 'select',
    options: [
      {
        id: 1,
        text: 'Si'
      },
      {
        id: 2,
        text: 'No'
      }
    ],
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: [{
        field: 'lineaBooking',
        value: 4,
        operator: 'neq'
      }
    ]
    }
  },
  {
    label: 'Nombre',
    value: 'forwarderNombre',
    css: '',
    group: 'forwarder',
    type: 'text',
    display: {
      export: {
        import: false
      }
    },
    condition: {
      export: [
      {
        field: 'showForwarder',
        value: 1,
        operator: 'eq'
      }
    ]
    }
  },
  {
    label: 'Dirección',
    value: 'forwarderDireccion',
    css: '',
    group: 'forwarder',
    type: 'text',
    display: {
      export: {
        import: false
      }
    },
    condition: {
      export: [
      {
        field: 'showForwarder',
        value: 1,
        operator: 'eq'
      }
    ]
    }
  },{
    label: 'Tax ID',
    value: 'forwarderTaxId',
    css: '',
    group: 'forwarder',
    type: 'text',
    display: {
      export: {
        import: false
      }
    },
    condition: {
      export: [
      {
        field: 'showForwarder',
        value: 1,
        operator: 'eq'
      }
    ]
    }
  },


  {
    label: 'Teléfono Contacto',
    value: 'forwarderTelefonoContacto',
    mask:'(000) 000-0000',
    css: '',
    group: 'forwarder',
    type: 'text',
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: [
      {
        field: 'showForwarder',
        value: 1,
        operator: 'eq'
      }
    ]
    }
  },
  {
    label: 'ZIP Code',
    value: 'forwarderZipCode',
    css: '',
    group: 'forwarder',
    type: 'text',
    display: {
      export: {
        import: false
      }
    },
    condition: {
      export: [
      {
        field: 'showForwarder',
        value: 1,
        operator: 'eq'
      }
    ]
    }
  },
  {
    label: 'Persona Contacto',
    value: 'forwarderPersonaContacto',
    css: '',
    group: 'forwarder',
    type: 'text',
    display: {
      export: {
        import: false
      }
    },
    condition: {
      export: [
      {
        field: 'showForwarder',
        value: 1,
        operator: 'eq'
      }
    ]
    }
  },
  {
    label: 'Email',
    value: 'forwarderEmail',
    css: '',
    group: 'forwarder',
    type: 'text',
    display: {
      export: {
        import: false
      }
    },
    condition: {
      export: [
      {
        field: 'showForwarder',
        value: 1,
        operator: 'eq'
      }
    ]
    }
  },
  {
    label: 'Desea incluir datos del consignatario?',
    value: 'showConsignatary',
    css: '',
    group: 'consignatary',
    type: 'select',
    defaultValue: 2,
    options: [
      {
        id: 1,
        text: 'Si'
      },
      {
        id: 2,
        text: 'No'
      }
    ],
    display: {
      export: {
        always: true
      }
    },
    condition: false
  },
  {
    label: 'Nombre de la empresa',
    value: 'consignatarioNombreEmpresa',
    css: '',
    group: 'consignatary',
    type: 'text',
    required: false,
    isInvalid: false,
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: {
        field: 'showConsignatary',
        value: 1,
        operator: 'eq'
      }
    }
  },
  {
    label: 'Tax ID',
    value: 'consignatarioTaxId',
    css: '',
    group: 'consignatary',
    type: 'text',
    required: false,
    isInvalid: false,
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: {
        field: 'showConsignatary',
        value: true,
        operator: 'eq'
      }
    }
  },
   {
    label: 'Zip Code',
    value: 'consignatarioZipCode',
    css: '',
    group: 'consignatary',
    type: 'text',
    required: false,
    isInvalid: false,
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: {
        field: 'showConsignatary',
        value: true,
        operator: 'eq'
      }
    }
  },
  {
    label: 'Dirección',
    value: 'consignatarioDireccion',
    css: '',
    group: 'consignatary',
    type: 'text',
    required: false,
    isInvalid: false,
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: {
        field: 'showConsignatary',
        value: true,
        operator: 'eq'
      }
    }
  },
  {
    label: 'Persona contacto',
    value: 'consignatarioPersonaContacto',
    css: '',
    group: 'consignatary',
    type: 'text',
    required: false,
    isInvalid: false,
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: {
        field: 'showConsignatary',
        value: true,
        operator: 'eq'
      }
    }
  },
  {
    label: 'Teléfono Contacto',
    value: 'consignatarioTelefonoContacto',
    mask:'(000) 000-0000',
    css: '',
    group: 'consignatary',
    type: 'text',
    required: false,
    isInvalid: false,
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: {
        field: 'showConsignatary',
        value: true,
        operator: 'eq'
      }
    }
  },
  {
    label: 'Email',
    value: 'consignatarioEmail',
    css: '',
    group: 'consignatary',
    type: 'text',
    required: false,
    isInvalid: false,
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: {
        field: 'showConsignatary',
        value: true,
        operator: 'eq'
      }
    }
  },
  {
    label: 'Desea incluir datos de notificante?',
    value: 'showNotificationInfo',
    css: '',
    group: 'notificationInfo',
    defaultValue: 2,
    type: 'select',
    options: [
      {
        id: 1,
        text: 'Si'
      },
      {
        id: 2,
        text: 'No'
      }
    ],
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: [{
        field: 'lineaBooking',
        value: 4,
        operator: 'eq'
      }
    ]
    }
  },
  {
    label: 'Nombre',
    value: 'notificarNombre',
    css: '',
    group: 'notificationInfo',
    type: 'text',
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: {
        field: 'showNotificationInfo',
        value: true,
        operator: 'eq'
      }
    }
  },
  {
    label: 'Dirección',
    value: 'notificarDireccion',
    css: '',
    group: 'notificationInfo',
    type: 'text',
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: {
        field: 'showNotificationInfo',
        value: true,
        operator: 'eq'
      }
    }
  },
  {
    label: 'Tax ID',
    value: 'notificarTaxId',
    css: '',
    group: 'notificationInfo',
    type: 'text',
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: {
        field: 'showNotificationInfo',
        value: true,
        operator: 'eq'
      }
    }
  },
  {
    label: 'Persona contacto',
    value: 'notificarPersonaContacto',
    css: '',
    group: 'notificationInfo',
    type: 'text',
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: {
        field: 'showNotificationInfo',
        value: true,
        operator: 'eq'
      }
    }
  },
  {
    label: 'Teléfono Contacto',
    value: 'notificarTelefonoEmpresa',
    mask:'(000) 000-0000',
    css: '',
    group: 'notificationInfo',
    type: 'text',
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: {
        field: 'showNotificationInfo',
        value: true,
        operator: 'eq'
      }
    }
  },
  {
    label: 'Email',
    value: 'notificarEmail',
    css: '',
    group: 'notificationInfo',
    type: 'text',
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: {
        field: 'showNotificationInfo',
        value: true,
        operator: 'eq'
      }
    }
  },
  {
    label: 'Carga refrigerada',
    value: 'cargaRefriferada',
    css: '',
    group: 'cooling',
    type: 'select',
    defaultValue: 2,
    options: [
      {
        id: 1,
        text: 'Si'
      },
      {
        id: 2,
        text: 'No'
      }
    ],
    display: {
      export: {
        always: true
      }
    },
    condition: false
  },
  {
    label: 'Ventilación',
    value: 'ventilacion',
    css: '',
    group: 'cooling',
    type: 'text',
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: {
        field: 'cargaRefriferada',
        value: true,
        operator: 'eq'
      }
    }
  },
  {
    label: 'Indique la unidad de temperatura',
    value: 'tempUnit',
    css: '',
    group: 'cooling',
    type: 'select',
    options: [
      {
        id:'c',
        text: '°C'
      },
      {
        id:'f',
        text: '°F'
      }
    ],
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: {
        field: 'cargaRefriferada',
        value: true,
        operator: 'eq'
      }
    }
  },
  {
    label: 'Temperatura',
    value: 'temperatura',
    css: '',
    group: 'cooling',
    type: 'text',
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: {
        field: 'cargaRefriferada',
        value: true,
        operator: 'eq'
      }
    }
  },
  {
    label: 'Humedad',
    value: 'humedad',
    css: '',
    group: 'cooling',
    type: 'text',
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: {
        field: 'cargaRefriferada',
        value: true,
        operator: 'eq'
      }
    }
  },
  {
    label: 'CO2 *',
    value: 'carbonDioxideLevel',
    css: '',
    group: 'cooling',
    type: 'number',
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: {
        field: 'cargaRefriferada',
        value: true,
        operator: 'eq'
      }
    }
  },
  {
    label: 'O2 *',
    value: 'oxygenLevel',
    css: '',
    group: 'cooling',
    type: 'number',
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: {
        field: 'cargaRefriferada',
        value: true,
        operator: 'eq'
      }
    }
  },
  {
    label: 'Unidad de Ventilación (°C) *',
    value: 'ventilationUnit',
    css: '',
    group: 'cooling',
    type: 'number',
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: {
        field: 'cargaRefriferada',
        value: true,
        operator: 'eq'
      }
    }
  },
  {
    label: 'Commodity',
    value: 'commodity',
    css: '',
    group: 'cooling',
    type: 'number',
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: {
        field: 'cargaRefriferada',
        value: true,
        operator: 'eq'
      }
    }
  },
  {
    label: 'La carga requiere fumigación',
    value: 'fumigacion',
    css: '',
    group: 'export',
    type: 'checkbox',
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: [{
        field: 'lineaBooking',
        value: 4,
        operator: 'eq'
      }
    ]
    }
  },
  {
    label: 'Carga peligrosa',
    value: 'cargaPeligrosa',
    css: '',
    group: 'dangerCargo',
    type: 'select',
    defaultValue: 2,
    options: [
      {
        id: 1,
        text: 'Si'
      },
      {
        id: 2,
        text: 'No'
      }
    ],
    display: {
      export: {
        always: true
      }
    },
    condition: false
  },
  {
    label: 'Número UN',
    value: 'numeroUN',
    css: '',
    group: 'dangerCargo',
    type: 'text',
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: {
        field: 'cargaPeligrosa',
        value: true,
        operator: 'eq'
      }
    }
  },
  {
    label: 'Código IMO',
    value: 'codigoIMO',
    css: '',
    group: 'dangerCargo',
    type: 'text',
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: {
        field: 'cargaPeligrosa',
        value: true,
        operator: 'eq'
      }
    }
  },
  {
    label: 'Componente Peligroso',
    value: 'componentePeligros',
    css: '',
    group: 'dangerCargo',
    type: 'text',
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: {
        field: 'cargaPeligrosa',
        value: true,
        operator: 'eq'
      }
    }
  },
  {
    label: 'Peso Neto',
    value: 'pesoCargaPeligros',
    css: '',
    group: 'dangerCargo',
    type: 'text',
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: {
        field: 'cargaPeligrosa',
        value: true,
        operator: 'eq'
      }
    }
  },
  
  {
    label: 'Peso Grueso',
    value: 'pesoGruesoCargaPeligros',
    css: '',
    group: 'dangerCargo',
    type: 'text',
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: {
        field: 'cargaPeligrosa',
        value: true,
        operator: 'eq'
      }
    }
  },
  {
    label: 'Flash Point',
    value: 'flashPoint',
    css: '',
    group: 'dangerCargo',
    type: 'text',
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: {
        field: 'cargaPeligrosa',
        value: true,
        operator: 'eq'
      }
    }
  },
  {
    label: 'Código Paquete',
    value: 'codigoPaquete',
    css: '',
    group: 'dangerCargo',
    type: 'text',
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: {
        field: 'cargaPeligrosa',
        value: true,
        operator: 'eq'
      }
    }
  }
  ,
  {
    label: 'Nombre Contacto Emergencia',
    value: 'nombreContactoEmergencia',
    css: '',
    group: 'dangerCargo',
    type: 'text',
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: {
        field: 'cargaPeligrosa',
        value: true,
        operator: 'eq'
      }
    }
  },
  {
    label: 'Télefono Contacto',
    value: 'telefonoContactoEmergencia',
    mask:'(000) 000-0000',
    css: '',
    group: 'dangerCargo',
    type: 'text',
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: {
        field: 'cargaPeligrosa',
        value: true,
        operator: 'eq'
      }
    }
  },
  {
    label: 'Desea asegurar su Carga',
    value: 'cargaAsegurada',
    css: '',
    group: 'export',
    type: 'checkbox',
    display: {
      export: {
        always: true
      },
      import: {
        export: false
      }
    },
    condition: {
      export: [{
        field: 'lineaBooking',
        value: 4,
        operator: 'eq'
      }
    ]
    }
  },
  {
    label: 'Fecha de entrega',
    value: 'fechaRecogida',
    css: '',
    group: 'transport',
    type: 'date',
    required: false,
    isInvalid: false,
    display: {
      transport: {
        always: true
      }
    },
    condition: {
      export: [{
        field: 'lineaBooking',
        value: 4,
        operator: 'eq'
      }
    ],
      transport: {
        field: 'tipoCarga',
        value: 2,
        operator: 'eq'
  }
}
  },
  {
    label: 'Fecha de recogida',
    value: 'fechaRecogida',
    css: '',
    group: 'transport',
    type: 'date',
    required: false,
    isInvalid: false,
    display: {
      transport: {
        always: true
      }
    },
    condition: {
      transport: {
        field: 'tipoCarga',
        value: 2,
        operator: 'neq'
  }
}
  },
  {
    label: 'Tipo de equipo',
    value: 'tipoEquipo',
    css: '',
    group: 'transport',
    type: 'select',
    required: false,
    isInvalid: false,
    api:'mardom',
    endPoint: serviceConfig.endPoints.EquipmentType,
    endPointParams: { },
    options: [
      {
        id: 1,
        text: 'Equipo 1'
      },
      {
        id: 2,
        text: 'Equipo 1'
      }
    ],
    display: {
      transport: {
        always: true
      }
    },
    condition: false
  },
  {
    label: 'Cantidad de Equipos',
    value: 'cantidad',
    css: '',
    group: 'transport',
    type: 'number',
    required: false,
    isInvalid: false,
    display: {
      transport: {
        always: true
      }
    },
    condition: false
  }
 
  ,
  {
    label: 'Horario de recepción o entrega',
    value: 'horarioRecepcionEntrega',
    css: '',
    group: 'transport',
    type: 'time',
    required: false,
    isInvalid: false,
    display: {
      transport: {
        always: true
      }
    },
    condition: false
  },
  {
    label: 'Origen',
    value: 'direccionOrigen',
    css: '',
    required: false,
    isInvalid: false,
    group: 'transport',
    type: 'location',
    display: {
      transport: {
        always: true
      }
    },
    condition: false
  },
  {
    label: 'Destino',
    value: 'direccionDestino',
    css: '',
    group: 'transport',
    type: 'location',
    required: false,
    isInvalid: false,
    display: { 
      transport: {
        always: true
      }
    },
    condition: false
  },
  {
    label: 'Requiere paradas',
    value: 'requiereParada',
    css: '',
    group: 'transport',
    type: 'select',
    options: [
      {
        id: 1,
        text: 'Si'
      },
      {
        id: 2,
        text: 'No'
      }
    ],
    display: {
      transport: {
        always: true
      }
    },
    condition: false
  },
  {
    label: 'Nombre contacto recepción de mercancía',
    value: 'nombreContactoRecibe',
    css: '',
    group: 'contactInfo',
    type: 'text',
    required: false,
    isInvalid: false,
    display: {
      transport: {
        always: true
      }
    },
    condition: false
  },
  {
    label: 'Teléfono contacto',
    value: 'telefonoContactoRecibe',
    mask:'(000) 000-0000',
    css: '',
    group: 'contactInfo',
    type: 'text',
    required: false,
    isInvalid: false,
    display: {
      transport: {
        always: true
      }
    },
    condition: false
  },
  {
    label: 'Tipo de Gestión Aduanal',
    value: 'tipoGestionAduanal',
    css: '',
    group: 'export',
    type: 'select',
    required: true,
    isInvalid: false,
    endPoint: serviceConfig.endPoints.customTypesByType,
    endPointParams: { tipo: 'I' },
    options: [
      {
        id: 1,
        text: 'gestion 1'
      }
    ],
    display: {
      costums: {
        always: true
      }
    },
    condition: {
      general: {
        field: 'tipoServicio',
        value: 1,
        operator: 'eq'
      }
    
    }
  },
  {
    label: 'Tipo de Gestión Aduanal',
    value: 'tipoGestionAduanale',
    css: '',
    group: 'export',
    required: true,
    isInvalid: false,
    type: 'select',
    endPoint: serviceConfig.endPoints.customTypesByType,
    endPointParams: { tipo: 'E' },
    options: [
      {
        id: 1,
        text: 'gestion 1'
      }
    ],
    display: {
      costums: {
        always: true
      }
    },
    condition: {
      general: {
        field: 'tipoServicio',
        value: 2,
        operator: 'eq'
      }
    
    }
  },
  {
    label: 'Régimen',
    value: 'gestionRegimen',
    css: '',
    group: 'export',
    required: true,
    isInvalid: false,
    type: 'select',
    options: [
      {
        id: 11,
        text: 'ZONA FRANCA INDUSTRIAL'
      }
      ,{
        id: 2,
        text: 'ADMISION TEMPORAL CON PERFECCIONAMIENTO ACTIVO'
      },{
        id: 1,
        text: 'DESPACHO A CONSUMO'
      },{
        id: 10,
        text: 'ZONA FRANCA COMERCIAL'
      },{
        id: 14,
        text: 'REIMPORTACION'
      }
      ,{
        id: 15,
        text: 'DEPOSITO PARTICULAR'
      },{
        id: 18,
        text: 'DEPOSITO CONSOLIDACION DE CARGA'
      },{
        id: 3,
        text: 'INTERNACION TEMPORAL SIN TRANSFORMACION'
      }
      ,{
        id: 6,
        text: 'DEPOSITO FISCAL'
      },{
        id: 7,
        text: 'DEPOSITO DE REEXPORTACION'
      },{
        id: 22,
        text: 'TRANSITO'
      },{
        id: 5,
        text: 'SALIDA TEMPORAL'
      }
      ,{
        id: 4,
        text: 'DEPOSITO LOGISTICO'
      },{
        id: 531961,
        text: 'CONSUMO DE REEXPORTACION'
      }

    ],
    display: {
      costums: {
        always: true
      }
    },
    condition: {
      general: {
        field: 'tipoServicio',
        value: 1,
        operator: 'eq'
      }
    
    }
  },
  
  {
    label: 'Régimen',
    value: 'gestionRegimen',
    css: '',
    group: 'export',
    required: true,
    isInvalid: false,
    type: 'select',
    options: [
      {
        id: 222,
        text: 'ADMISION TEMPORAL CON PERFECCIONAMIENTO ACTIVO'
      }
      ,{
        id: 111,
        text: 'ZONA FRANCA INDUSTRIAL'
      }
      ,{
        id: 55,
        text: 'SALIDA TEMPORAL'
      }
      ,{
        id: 20,
        text: ' CONSUMO DE REEXPORTACION'
      }
      ,{
        id: 17,
        text: 'REEMBARQUE'
      }
      ,{
        id: 19,
        text: 'EXPORTACION A CONSUMO'
      },{
        id: 531963,
        text: 'EXPORTACION NACIONAL'
      }
    ],
    display: {
      costums: {
        always: true
      }
    },
    condition: {
      general: {
        field: 'tipoServicio',
        value: 2,
        operator: 'eq'
      }
    
    }
  },
  {
    label: 'Fecha Estimada Llegada',
    value: 'fechaEstimadaLlegadaSalida',
    css: '',
    group: 'export',
    type: 'date',
    required: false,
    isInvalid: false,
    display: {
      costums: {
        always: true
      },
      import: {
        costums: false
      },
      export: {
        import: false
      }
    },
    condition: {
      general: {
        field: 'tipoServicio',
        value: 1,
        operator: 'eq'
      }
    
    }
  },


  {
    label: 'Fecha Estimada Salida',
    value: 'fechaEstimadaLlegadaSalida',
    css: '',
    group: 'export',
    type: 'date',
    required: false,
    isInvalid: false,
    display: {
      costums: {
        always: true
      },
      import: {
        costums: false
      },
      export: {
        import: false
      }
    },
    condition: {
     
      export: [{
        field: 'lineaBooking',
        value: 4,
        operator: 'eq'
      }
    ],
    general:[ {
      field: 'tipoServicio',
      value: 2,
      operator: 'eq'
    }]
    }
  },
  {
    label: 'Su carga requiere permisos especiales',
    value: 'permisosEspeciales',
    css: '',
    group: 'export',
    type: 'checkbox',
    
    display: {
      costums: {
        always: true
      },
      import: {
        costums: false
      },
      export: {
        import: false,
        costums: false
      }
    },
    condition: {
      export: [{
        field: 'lineaBooking',
        value: 4,
        operator: 'eq'
      }
    ]
    }
  },
  {
    label: 'Desea Agregar Otros Servicios',
    value: 'servicioGestionAduanal',
    css: '',
    group: 'export',
    type: 'multiselect',
    options: [
      {
        id: "1",
        text: 'Pagos a terceros'
      },{
        id: "2",
        text: 'Traducción de facturas'
      }
    ],
    display: {
      costums: {
        always: true
      }
    },
    condition: false
  },
  {
    label: 'Términos de pago',
    value: 'terminoPago',
    css: '',
    group: 'export',
    type: 'select',
    required: false,
    isInvalid: false,
    options: [
      {
        id: 1,
        text: 'Origen (prepaid)'
      },{
        id: 2,
        text: 'Destino (collect)'
      }
    ],
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: [{
        field: 'lineaBooking',
        value: 4,
        operator: 'eq'
      }
    ]
    }
  },
  {
    label: 'Pagado por: ',
    value: 'pagadoPor',
    css: '',
    group: 'export',
    required: false,
    isInvalid: false,
    type: 'select',
    options: [
      {
        id: 1,
        text: 'Embarcador'
      },{
        id: 2,
        text: 'Consignatario'
      },{
        id: 3,
        text: 'Notificante'
      },{
        id: 2,
        text: 'Otro'
      }
    ],
    display: {
      export: {
        always: true
      }
    },
    condition: {
      export: [{
        field: 'lineaBooking',
        value: 4,
        operator: 'eq'
      }
    ]
    }
  },
  ,
  {
    label: 'Al momento de enviar su solicitud, es requerido someter la factura comercial y BL correspondiente a la mercancía. De no estar adjuntos dichos documentos, la solicitud no podrá ser validada para su procesamiento y MARDOM no será responsable de multas por declaración tardía.',
    value: 'file',
    css: '',
    group: 'general',
    type: 'file',
    display: {
      general: {
        always: false
      }
    },
    condition: false
  }
]

export const regimenOptions=[
  {
    id: 222,
    text: 'ADMISION TEMPORAL CON PERFECCIONAMIENTO ACTIVO'
  }
  ,{
    id: 111,
    text: 'ZONA FRANCA INDUSTRIAL'
  }
  ,{
    id: 55,
    text: 'SALIDA TEMPORAL'
  }
  ,{
    id: 20,
    text: ' CONSUMO DE REEXPORTACION'
  }
  ,{
    id: 17,
    text: 'REEMBARQUE'
  }
  ,{
    id: 19,
    text: 'EXPORTACION A CONSUMO'
  },{
    id: 531963,
    text: 'EXPORTACION NACIONAL'
  },{
    id: 11,
    text: 'ZONA FRANCA INDUSTRIAL'
  }
  ,{
    id: 2,
    text: 'ADMISION TEMPORAL CON PERFECCIONAMIENTO ACTIVO'
  },{
    id: 1,
    text: 'DESPACHO A CONSUMO'
  },{
    id: 10,
    text: 'ZONA FRANCA COMERCIAL'
  },{
    id: 14,
    text: 'REIMPORTACION'
  }
  ,{
    id: 15,
    text: 'DEPOSITO PARTICULAR'
  },{
    id: 18,
    text: 'DEPOSITO CONSOLIDACION DE CARGA'
  },{
    id: 3,
    text: 'INTERNACION TEMPORAL SIN TRANSFORMACION'
  }
  ,{
    id: 6,
    text: 'DEPOSITO FISCAL'
  },{
    id: 7,
    text: 'DEPOSITO DE REEXPORTACION'
  },{
    id: 22,
    text: 'TRANSITO'
  },{
    id: 5,
    text: 'SALIDA TEMPORAL'
  }
  ,{
    id: 4,
    text: 'DEPOSITO LOGISTICO'
  },{
    id: 531961,
    text: 'CONSUMO DE REEXPORTACION'
  }
]