import { ReceptionDetailModel } from './receptiondetail';

export class ReceptionReferenceModel{
    //MardomGoWeb
    autoagendable:string;
    reference_type:string;// truckplate
    agent_type:string;
    agent_code:string;
    delivery_date:string;
    date_issued:string;
    user_id:string;
    created_date:string;
    assigned_user:string;
    Status:string;
    reference_number:string;
    regimen:string;
    memo: string;
    detail:ReceptionDetailModel[];

    //wis fields
    NoReference:string;
    SelfReadable:string;
    AgentType:string;
    TruckPlate:string;
    DriveName:string;
    DriversLicense:string;
    CompanyCode:string;
    Commentary:string;
    Regime:string;
    AgentCode:string;
    Delivery:string;
    Anexo:string;
    Products:ReceptionDetailModel[];
    TypeMoneyAStore:string;
    ChargeMode:string;
    Transformation:string;
    InstructionsError:string;
}
