export class VoucherModel{
    id:number;
    description: string;
    service_type: number;
    service_id: string;
    user_id: string;
    created_date: string;
    Status: string;
    assigned_user: string;
    client_name:string;
    customer_id: string;
}