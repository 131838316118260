import { InjectionToken } from '@angular/core'
import { WebAuth } from 'auth0-js'
import { AuthConfiguration } from '../_models/auth-configuration'

declare const authConfiguration

export const AUTH0CLIENT = new InjectionToken<WebAuth>('Auth0Config', {
  providedIn: 'root',
  factory: () => {
    const config = <AuthConfiguration>authConfiguration
    const authClientOptions = {
      domain: config.domain,
      clientID: config.clientId,
      redirectUri: config.redirectUri,
      //  redirectUri: 'http://localhost:4200/',
      responseType: config.responseType,
      ...config.internalOptions,
    }
    return new WebAuth(authClientOptions)
  },
})
