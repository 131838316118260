import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from "@angular/common/http";

import serviceConfig from '../service-config';
import { Observable, throwError } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { selectAuthState } from '../../core/auth/_selectors/auth.selectors';
import { AppState } from '../../core/reducers/index';
import * as jwt_decode from 'jwt-decode';
import { catchError, map } from 'rxjs/operators';
import moment from 'moment';
import { currentUser, User } from '../../core/auth';

@Injectable({
  providedIn: 'root'
})
export class TransferrequestService {
  uri = serviceConfig.manifestBaseUrl;
  headers: HttpHeaders;
  fileHeaders:HttpHeaders;
  userId: '';
  HttpUploadOptions: any;
  urialtBaseUrl = serviceConfig.altBaseUrl;
  logBaseUrl=serviceConfig.logisticWarehouseBaseUrl;

  user$: Observable<User>;
  public Auth0User : object  = {
    id: "",
    fullname: "",
    email: "",
    pic: "",
    username: "",
    permissions: []
  }

  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.store.select(selectAuthState).subscribe((result) => {
      this.fileHeaders = new HttpHeaders()
        .append('Authorization', "Bearer " + result.authToken)
        .append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept, Referer, User-Agent')

      var decoded = jwt_decode(result.authToken);
      this.userId = decoded['https://mardom.com/clientId'];
      this.headers = new HttpHeaders()
      .append('Authorization', "Bearer "+result.authToken)  
      .append('Content-Type', 'application/json')
      .append('accept','*/*')
      .append('Allow', '*')
      .append('Access-Control-Allow-Origin', '*')
      .append('Access-Control-Allow-Methods', 'POST, GET, PUT, DELETE, OPTIONS')
      .append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept, Referer, User-Agent')
      .append('accept','text/plain');
    });

    this.user$ = this.store.pipe(select(currentUser));
    this.user$.subscribe((user) => {
      //this.userName = user.fullname;
      this.Auth0User = { ...this.Auth0User , ...user };
    })
  }

  verifyManifest(ManifestNo):Observable<any>{
    let url = this.uri + serviceConfig.endPoints.verifyManifest+ManifestNo;
    return this.http.get<any>(`${url}`,{ headers: this.headers })
  }

  checkBlMNo(BLMNo):Observable<any>{
    let url = this.uri + serviceConfig.endPoints.verifyBLMNo+BLMNo;
    return this.http.get<any>(`${url}`,{ headers: this.headers })
  }

  checkContener(contenerNo,manifestno):Observable<any>{
    let url = this.uri + serviceConfig.endPoints.verifyContener+'Contendor='+contenerNo+'&ManifestNo='+ manifestno;
    return this.http.get<any>(`${url}`,{ headers: this.headers })
  }

  checkBLNO(Contendor,manifestNo,blNo):Observable<any>{
    let url = this.uri + serviceConfig.endPoints.verifyBL+ 'Contendor='+Contendor+'&ManifestNo='+ manifestNo+'&BlNomber='+blNo;
    return this.http.get<any>(`${url}`,{ headers: this.headers })
  }

  getAllShipList(): Observable<any> {
    let url = this.uri + serviceConfig.endPoints.ship;
    return this.http.get<any>(`${url}`, { headers: this.headers });
  }

  getAllPortList(): Observable<any> {
    let url = this.uri + serviceConfig.endPoints.port;
    return this.http.get<any>(`${url}`, { headers: this.headers });
  }

 getContainerTypeList(): Observable<any> {
    let url = this.uri + serviceConfig.endPoints.containerType;
    return this.http.get<any>(`${url}`, { headers: this.headers });
  }

  getLoadTypeandClientList(): Observable<any> {
    let url = this.uri + serviceConfig.endPoints.loadTypeandClient;
    return this.http.get<any>(`${url}`, { headers: this.headers });
  }

  getTypeofMerchandiseist(): Observable<any> {
    let url = this.uri + serviceConfig.endPoints.typeofMerchandise;
    return this.http.get<any>(`${url}`, { headers: this.headers });
  }

  getAgenciesList(): Observable<any> {
    let url = this.uri + serviceConfig.endPoints.agencies;
    return this.http.get<any>(`${url}`, { headers: this.headers });
  }

  getPackingTypeList(value): Observable<any> {
    let url = this.uri + serviceConfig.endPoints.packingType + '?name=' + value;
    return this.http.get<any>(`${url}`, { headers: this.headers });
  }

  getCityList(): Observable<any> {
    let url = this.uri + serviceConfig.endPoints.city;
    return this.http.get<any>(`${url}`, { headers: this.headers });
  }

  getDangerTypeList(): Observable<any> {
    let url = this.uri + serviceConfig.endPoints.dangerType;
    return this.http.get<any>(`${url}`, { headers: this.headers });
  }

  saveParseXML(paylod: any) {
    let url = this.uri + serviceConfig.endPoints.saveParseXML;
    return this.http.post(`${url}`, paylod, { headers: this.fileHeaders }).pipe(
      catchError((err) => {
        return throwError(err);  
      })
    );
  }

  uploadmanifestDoc(paylod: any) {
    let url = this.uri + serviceConfig.endPoints.uploadmanifestDoc;
    return this.http.post(`${url}`, paylod, { headers: this.fileHeaders }).pipe(
      catchError((err) => {
        return throwError(err);  
      })
    );
  }

  uploadmanifestDocInTable(paylod: any) {
    let url = this.uri + serviceConfig.endPoints.uploadmanifestDocInTable;
    return this.http.post(`${url}`, paylod, { headers: this.headers }).pipe(
      catchError((err) => {
        return throwError(err);  
      })
    );
  }

  saveManifest(paylod: any) {
    let url = this.uri + serviceConfig.endPoints.saveManifest;
    return this.http.post(`${url}`, paylod, { headers: this.headers });
  }

  updateManifest(paylod: any) {
    let url = this.uri + serviceConfig.endPoints.updateManifest;
    return this.http.post(`${url}`, paylod, { headers: this.headers });
  }

  getManifestList(page) {
    let url = this.uri + '/Manifest/GetManifestList?page='+page+'&size=10';
    return this.http.get(`${url}`, { headers: this.headers })
      .pipe(map((res: any) => this.convertDateArrayFromServer(res)));
  }

  updateImpediment(paylod) {
    let url = this.uri + serviceConfig.endPoints.updateImpediment;
    const payload = Object.freeze({
      ...paylod,
      Auth0User : this.Auth0User
    });
    return this.http.post(`${url}`, payload, { headers: this.headers });
  }

  searchManifest(page,datefrom, dateto, status, request) {
    let url = this.uri + serviceConfig.endPoints.serchManifest + '?page=' +page+'&size=10'
      + '&datefrom=' + datefrom + '&dateto=' + dateto + '&status=' + status + '&request=' + request;
    return this.http.get(`${url}`, { headers: this.headers })
    .pipe(map((res: any) => this.convertDateArrayFromServer(res)));
  }

  getDocumentList(manifestoNo,manifestCode) {
    let url = this.uri + serviceConfig.endPoints.getDocument + manifestoNo +'&manifestCode='+manifestCode;
    return this.http.get<any>(`${url}`, { headers: this.headers });
  }
  
  downlodDocument(id) {
    let url = this.uri + serviceConfig.endPoints.downloadDocument + id;
    return this.http.get<any>(`${url}`, { headers: this.headers, responseType: 'blob' as 'json' })
  }
  
  loadInvoice(id){
    let url = this.uri + serviceConfig.endPoints.getInvoice + id;
    return this.http.get<any>(`${url}`, { headers: this.headers }).pipe(
      catchError((err) => {
        return throwError(err); 
      })
    );
  }

  getManifestDetailsById(id){
    let url = this.uri + serviceConfig.endPoints.manifetDetailsById + id;
    return this.http.get<any>(`${url}`, { headers: this.headers })
    .pipe(map((res: any) => this.convertDateArrayFromServer(res)))
  }

  calculateCIF(paylod){
    let url = this.uri + serviceConfig.endPoints.calculateCIF;
  return this.http.post(`${url}`,paylod,{ headers: this.headers })
  }

  getQuotation(paylod){
    let url = this.urialtBaseUrl + serviceConfig.endPoints.warehouseTHQuotation+'?ClientId='+paylod.ClientId+'&BlId='+paylod.BlId
    +'&DownloadDate='+paylod.DownloadDate+'&CifValue='+paylod.CifValue+'&DispatchDate='+paylod.DispatchDate+'&Consult='+paylod.Consult;
  return this.http.get(`${url}`,{ headers: this.headers })
  }

  verifyRNC(no) {
    let url = this.uri + serviceConfig.endPoints.verifyRNC + '?rnc=' + no;
    return this.http.get(`${url}`, { headers: this.headers }).pipe(
      catchError((err) => {
        return throwError(err);    
      })
    );
  }

  paymentRequest(data){
    let url = this.uri + serviceConfig.endPoints.paymentRequest ;
    return this.http.post(`${url}`,data,{ headers: this.headers })
  }

  getAzualAuthHash(data){
    let url = this.uri + serviceConfig.endPoints.getAuthHash ;
    return this.http.post(`${url}`,data,{ headers: this.headers })
  }

  getAzualPaymentResponse(data){
    let url = this.uri + serviceConfig.endPoints.getAzualPaymentResponse
    return this.http.post(`${url}`,data,{ headers: this.headers })
  }

  getLatetStatusHistoryDetails(talllyNo) {
    let url = this.uri + serviceConfig.endPoints.getHistoryDetailsByTallyNo + talllyNo;
    return this.http.get<any>(`${url}`, { headers: this.headers })
  }

  getBlDetailsList(page){
    let url = this.uri + serviceConfig.endPoints.getAllBlDetailsList+'?page='+page+'&size=10';
    return this.http.get<any>(`${url}`, { headers: this.headers })
  }

  searchBL(page,datefrom, dateto, status, request) {
    let url = this.uri + serviceConfig.endPoints.getAllBlDetailsList + '?page=' +page+'&size=10'
      + '&datefrom=' + datefrom + '&dateto=' + dateto + '&status=' + status + '&request=' + request;
    return this.http.get(`${url}`, { headers: this.headers })
    .pipe(map((res: any) => this.convertDateArrayFromServer(res)));
  }

  changeBLStatus(paylod){
    let url = this.uri + serviceConfig.endPoints.changeBlStatus;
    return this.http.post(`${url}`,paylod,{ headers: this.headers })
  }

  menifestWis(manifestNo){
    let url = this.uri + serviceConfig.endPoints.menifestWis +manifestNo;
    return this.http.post(`${url}`,manifestNo,{ headers: this.headers })
  }

  getResponsibles () {
    let url = serviceConfig.altBaseUrl + serviceConfig.endPoints.responsible;
    return this.http.get(url, { headers: this.headers});
  }

  deleteFile(id){
    let url = this.uri + serviceConfig.endPoints.deleteFile+id;
    return this.http.delete(`${url}`,{ headers: this.headers })
  }

  getBLStatus(){
    let url = this.uri + serviceConfig.endPoints.blStatus;
    return this.http.get<any>(`${url}`, { headers: this.headers })
  }

  getClientCode(){
    let url = this.uri + serviceConfig.endPoints.getClientCode;
    return this.http.get<any>(`${url}`, { headers: this.headers })
  }

  uploadFiles(files){
    let url = serviceConfig.filesUrl+serviceConfig.endPoints.azureUpload;
    let formData = new FormData();
    formData.append('files[]', files, files.name);
    return this.http.post(url,formData, { headers:this.fileHeaders } );
  }

  saveStatusInformation(paylod){
    let url = this.uri + serviceConfig.endPoints.saveStatusinfo;
    return this.http.post(`${url}`,paylod,{ headers: this.headers })
  }

  getStatusIfromationById(id){
    let url = this.uri + serviceConfig.endPoints.getStatusinfo + id;
    return this.http.get<any>(`${url}`, { headers: this.headers })
  }

  downloadPaymentFile(id:number): Observable<any>{
    let url= serviceConfig.filesUrl+serviceConfig.endPoints.azureDownload;
		return this.http.get(`${url}/${id}`, { headers: this.headers, responseType:'blob'});
  }

  getBLByManifest(id){
    let url = this.uri + serviceConfig.endPoints.getBLByManifest+id;
    return this.http.get(`${url}`,{ headers: this.headers })
  }

  downloadXML(id) {
    let url = this.uri + serviceConfig.endPoints.downloadXML+id;
    return this.http.get(`${url}`,{ headers: this.headers, responseType: 'blob' });
  }

  getPaymnetDetails(id){
    let url = this.uri + serviceConfig.endPoints.getPaymentDetails+id;
    return this.http.get(`${url}`,{ headers: this.headers })
  }

  getConsolidator(data){
    let url = this.uri + serviceConfig.endPoints.getConsolidator + data;
    return this.http.get<any>(`${url}`, { headers: this.headers })
  }
  
  saveConsolidador(data){
    let url = this.uri + serviceConfig.endPoints.saveConsolidator;
    return this.http.post<any>(`${url}`, data,{ headers: this.headers })
  }

  getConsolidatorList(){
    let url = this.uri + serviceConfig.endPoints.getConsolidatorList;
    return this.http.get<any>(`${url}`, { headers: this.headers })
  }
  
  protected convertDateArrayFromServer(res: any): any {
    if (res.Manifests) {
      res.Manifests.forEach((data: any) => {
        data.CreationDate = data.CreationDate ? moment(data.CreationDate) : undefined;
        data.DockingDate = data.DockingDate ? moment(data.DockingDate) : undefined;  
      });
    }
    return res;
  }

  sendmail(email:string,emailAccount:string,companyName:string,Solicitud_Number:string){
    let url =this.logBaseUrl + serviceConfig.endPoints.requestMail+`/?email=${email}&emailAccount=${emailAccount}&companyName=${companyName}&Solicitud_Number=${Solicitud_Number}`;
    return this.http.get<any>(`${url}`);
  }

  sentRequestNotification(CompanyId,RequestId,RequestType){
    let url=this.logBaseUrl + serviceConfig.endPoints.sentRequestNotification+`/?CompanyId=${CompanyId}&RequestId=${RequestId}&RequestType=${RequestType}`;
    return this.http.post<any>(`${url}`,null, { headers: this.headers });
  }
}