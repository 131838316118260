import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import serviceConfig from '../../app/services/service-config';
import { Observable, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAuthState } from '../core/auth/_selectors/auth.selectors';
import { AppState } from '../core/reducers/index';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class TrackingServiceService {
  uri = serviceConfig.trackAndTraceBaseUrl;
  logisticuri = serviceConfig.logisticWarehouseBaseUrl
  baseuri = serviceConfig.altBaseUrl;
  headers: HttpHeaders;
  fileHeaders: HttpHeaders;
  userId: '';
  manifestUri = serviceConfig.manifestBaseUrl;

  constructor(private http: HttpClient, private store: Store<AppState>) {

    this.store.select(selectAuthState).subscribe((result) => {
      console.log('result', result)
      this.fileHeaders = new HttpHeaders()
        .append('Authorization', "Bearer " + result.authToken)
        .append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept, Referer, User-Agent')

      var decoded = jwt_decode(result.authToken);
      this.userId = decoded['https://mardom.com/clientId'];
      this.headers = new HttpHeaders()
        .append('Authorization', "Bearer " + result.authToken)
        .append('Content-Type', 'application/json')
        .append('accept', '*/*')
        .append('Allow', '*')
        .append('Access-Control-Allow-Origin', '*')
        .append('Access-Control-Allow-Methods', 'POST, GET, PUT, DELETE, OPTIONS')
        .append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept, Referer, User-Agent')
        .append('accept', 'text/plain');
    });
  }

  getFavoritosList(page, pagesize, companyId): Observable<any> {
    let url = this.uri + serviceConfig.endPoints.getFavouriteList + '?pageNumber=' + page + '&pageSize=' + pagesize + '&companyId=' + companyId;
    return this.http.get<any>(`${url}`, { headers: this.headers })
  }

  getRecentServicesList(page): Observable<any> {
    let url = this.uri + serviceConfig.endPoints.getRecentServicesList + '?pageNumber=' + page + '&pageSize=5';
    return this.http.get<any>(`${url}`, { headers: this.headers })
  }

  getOrderInProgressList(page, size, payload): Observable<any> {
    //let url = "https://localhost:44391" + serviceConfig.endPoints.getOrders + '?pageNumber=' + page + '&pageSize=' + size + '&Status=' + payload.Status
    let url = this.uri + serviceConfig.endPoints.getOrders + '?pageNumber=' + page + '&pageSize=' + size + '&Status=' + payload.Status
      + '&FromDate=' + payload.FromDate + '&ToDate=' + payload.ToDate + '&Refrenceno=' + payload.Refrenceno + '&refrencetype=' + payload.refrencetype
      + '&orderon=' + payload.orderon + '&orderby=' + payload.orderby + '&startswith=' + payload.startswith;
    return this.http.get<any>(`${url}`, { headers: this.headers })
  }

  downloadOrderExcel(page, size, payload): Observable<any> {
    let url = this.uri + serviceConfig.endPoints.ordersDownload + '?pageNumber=' + page + '&pageSize=' + size + '&Status=' + payload.Status
      + '&FromDate=' + payload.FromDate + '&ToDate=' + payload.ToDate + '&Refrenceno=' + payload.Refrenceno + '&refrencetype=' + payload.refrencetype
      + '&orderon=' + payload.orderon + '&orderby=' + payload.orderby + '&startswith=' + payload.startswith;
    return this.http.get<any>(`${url}`, { headers: this.headers, responseType: 'blob' as 'json' })
  }

  getratingcount(blnum: any, type: any): Observable<any> {
    let url = this.uri + serviceConfig.endPoints.ratingcount + "/?BlNumber=" + blnum + "&Type=" + type;
    return this.http.get<any>(`${url}`, { headers: this.headers })
  }

  ratethesystem(payload: any): Observable<any> {
    let url = this.uri + serviceConfig.endPoints.calificar;
    return this.http.post<any>(`${url}`, payload, { headers: this.headers })
  }

  getInvoicesbyBl(page: any, blnum: any): Observable<any> {
    let url = this.baseuri + serviceConfig.endPoints.pendingInvoicebyBl + '?page=' + page + '&invoiceNumber=&bl=' + blnum;
    return this.http.get<any>(`${url}`, { headers: this.headers })
  }

  getAllRefrences(refrenceNo): Observable<any> {
    let url = this.uri + serviceConfig.endPoints.getAllRefrences + refrenceNo;
    return this.http.get<any>(`${url}`, { headers: this.headers })
  }

  getDetailsBlStatus(refrenceNo): Observable<any> {
    let url = this.uri + serviceConfig.endPoints.getDetailsBlStatus + refrenceNo;
    return this.http.get<any>(`${url}`, { headers: this.headers })
  }

  addToFavourite(payload): Observable<any> {
    let url = this.uri + serviceConfig.endPoints.addToFavourite;
    return this.http.post<any>(`${url}`, payload, { headers: this.headers })
  }

  updateImpediment(paylod) {
    let url = this.uri + serviceConfig.endPoints.updateOrderImpediment + 'bl=' + paylod.bl + '&action=' + paylod.action;
    return this.http.get(`${url}`, { headers: this.headers });
  }

  getTranspTerrestreDetils(refrenceNo, system): Observable<any> {
    let url
    if (system.includes('Camiencito')) {
      url = this.uri + serviceConfig.endPoints.getTranspTerrestreDetils + refrenceNo;
    } else if (system.includes('TMS')) {
      url = this.uri + serviceConfig.endPoints.getTranspTerrestreDetilsTMS + refrenceNo;
    }
    return this.http.get<any>(`${url}`, { headers: this.headers })
  }

  getTarjaDetils(refrenceNo): Observable<any> {
    let url = this.uri + serviceConfig.endPoints.getAllTarjasDetails + refrenceNo;
    return this.http.get<any>(`${url}`, { headers: this.headers })
  }

  downloadWindipDocumnet(networkPath, filePath): Observable<any> {
    let url = this.uri + serviceConfig.endPoints.downloadWindipDocumnet + 'networkPath=\\' + networkPath + '&filePath=\\' + filePath;
    return this.http.get<any>(`${url}`, { headers: this.headers, responseType: 'blob' as 'json' })
  }

  downloadDocumnet(id: number): Observable<any> {
    let url = serviceConfig.filesUrl + serviceConfig.endPoints.azureDownload;
    return this.http.get(`${url}/${id}`, { headers: this.headers, responseType: 'blob' });
  }

  getLastVisitedServices(): Observable<any> {
    let url = this.uri + serviceConfig.endPoints.getLastVisitedServices;
    return this.http.get<any>(`${url}`, { headers: this.headers })
  }

  getUserPreferenceList(authid): Observable<any> {
    let url = this.logisticuri + serviceConfig.endPoints.GetUserPreferenceList + '?AuthId=' + authid;
    return this.http.get<any>(`${url}`, { headers: this.headers })
  }

  saveUserPreference(payload, authid): Observable<any> {
    let url = this.logisticuri + serviceConfig.endPoints.SaveUserPreference + '?AuthId=' + authid;
    return this.http.post<any>(`${url}`, payload, { headers: this.headers })
  }
}

