import { InjectionToken } from '@angular/core'
import { AuthConfiguration } from '../_models/auth-configuration'

declare const authConfiguration

export const AUTH0CONFIGURATION = new InjectionToken<AuthConfiguration>(
  'Auth0Config',
  {
    providedIn: 'root',
    factory: () => {
      return authConfiguration
    },
  }
)
