import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'kt-blankpage',
  templateUrl: './blankpage.component.html',
  styleUrls: ['./blankpage.component.scss']
})
export class BlankpageComponent implements OnInit {

  @ViewChild('detailModal', {static: true}) detailModal: TemplateRef<any>;

  constructor(private modalService: NgbModal) { }

  showModal(modal){
    console.log('showing modal')
    this.modalService.open(this.detailModal, {size: 'lg'})
  }

  ngOnInit() {
  }

}
