import { Component, OnInit, Input} from '@angular/core';
import { element } from 'protractor';

@Component({
  selector: 'kt-status-indicator',
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.scss']
})
export class StatusIndicatorComponent implements OnInit {

  @Input() steps: any[];
  @Input() actualStep: number = 0;
  @Input() subStatus: string;
  @Input() subStatusDate: string;
  updatedSteps=[];

  constructor() { 
   
  }

  ngOnInit() {
    this.removeIndex();
  }

removeIndex(){
  if(this.steps){
    for(let i=0;i<this.steps.length;i++){
      if(this.steps[i].date!=null){
        this.updatedSteps.push(this.steps[i])
      } }
  }
}

}
