// Angular
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
// RxJS
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
// Module reducers and selectors
import { AppState } from '../../reducers';
import { currentUserPermissions } from '../_selectors/auth.selectors';
import { Permission } from '../_models/permission.model';
import { find } from 'lodash';
import { PermissionWeb } from '../_models/permission.web.model';
import { selectAllPermissions } from '../_selectors/permission.selectors';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

  permissions: any[]
  
  constructor(private store: Store<AppState>, private router: Router) {
    this.store.select(selectAllPermissions).subscribe(
      res => {
        this.permissions = res        
      }
    );
  }

  hasAccess(moduleAction: string):boolean {   
    return this.permissions.findIndex(permission => permission.name === moduleAction) > -1
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const permissionName = route.data.permission as string;
    if (!permissionName) {
      return of(false);
    }

    return this.store.pipe(
      select(selectAllPermissions),
      map((permissions: PermissionWeb[]) => {        
        const _perm = find(permissions, (elem: PermissionWeb) => {          
          return (
             permissionName.toLocaleLowerCase().indexOf(elem.name.toLocaleLowerCase()) > -1
          );
        });
        return _perm ? true : false;
      }),
      tap(hasAccess => {
        if (!hasAccess) {
          this.router.navigateByUrl('/error/401');
        }
      })
    );
  }
}
