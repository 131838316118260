import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpErrorResponse  } from "@angular/common/http";
import { AppState } from '../../core/reducers/index';
import { Store } from '@ngrx/store';
import {OutboxModel} from '../../models/outbox'
import serviceConfig from '../service-config';
import { Observable } from 'rxjs';
import { selectAuthState } from '../../core/auth/_selectors/auth.selectors';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class OutboxService {

  uri = serviceConfig.baseUrl + serviceConfig.endPoints.outboxMessage;
  headers = new HttpHeaders()
  clientId
  altHeaders: HttpHeaders;

  constructor(private http:HttpClient, private store: Store<AppState> ) { 
    this.store.select(selectAuthState).subscribe((result) => { 
      this.headers = new HttpHeaders().set('Authorization', "Bearer "+result.authToken);

      var decoded = jwt_decode(result.authToken); 
      //real userid.
      this.clientId=decoded['https://mardom.com/clientId'];

      this.altHeaders =new HttpHeaders()
      .append('Authorization', "Bearer "+result.authToken)  
      .append('Content-Type', 'application/json')
      .append('accept','*/*')
      .append('Allow', '*')
      .append('Access-Control-Allow-Origin', '*')
      .append('Access-Control-Allow-Methods', 'POST, GET, PUT, DELETE, OPTIONS')
      .append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept, Referer, User-Agent')
      .append('accept','text/plain');
      
    });
  }

  addMessage(outbox: OutboxModel){
    outbox.user_id=this.clientId;    
    return this.http.post(`${this.uri}`, outbox, { headers: this.altHeaders });
  }

  updateMessage(id:number ,outbox: OutboxModel){
    this.http.put(`${this.uri}/${id}`, outbox, { headers: this.altHeaders });
  }

  getMessage(id:number):Observable<any> {
    return this
      .http
      .get<any>(`${this.uri}/${id}`,
       { headers: this.altHeaders });

  }

}
