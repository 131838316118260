import { Component, OnInit, NgZone, ElementRef, ViewChild, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MapsAPILoader, MouseEvent, AgmMap } from '@agm/core';
import { SharedataService } from './sharedata.service';

@Component({
  selector: 'kt-address-selector',
  templateUrl: './address-selector.component.html',
  styleUrls: ['./address-selector.component.scss']
})
export class AddressSelectorComponent implements OnInit, AfterViewInit {

  @ViewChild('search', {static: false }) searchElement: ElementRef;
  @ViewChild('agmMap', {static: false }) agmMap : AgmMap
  @Input() location: any;
  @Input() disabled: boolean = false;

  latitude: number = 18.427056;
  longitude: number = -70.004173;
  zoom:number;
  address: string = 'Marítima Dominicana, Santo Domingo';
  private geoCoder;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private shareDataService: SharedataService,
    private cdRef:ChangeDetectorRef) { }

  ngOnInit() {       
    this.loadPlacesAPI();    
  }

  ngAfterViewInit(){    
    if(this.location && this.location.latitude && this.location.longitude){   
      this.ngZone.run(() => {
        this.latitude = this.location.latitude;
        this.longitude = this.location.longitude;
        this.searchElement.nativeElement.value = this.location.address;
        this.zoom = 12;
        this.agmMap.triggerResize();
      })
      
    } else {      
      //this.setCurrentLocation();      
      this.ngZone.run(() => {
        this.latitude =  18.427056;
        this.longitude = -70.004173;
        this.searchElement.nativeElement.value = "Marítima Dominicana, Santo Domingo";
        this.zoom = 12;
        this.agmMap.triggerResize();
      })
    }
    this.cdRef.detectChanges();
  }

  getSelectedAddress(){
    return {
      address: this.address,
      latitude: this.latitude,
      longitude: this.longitude
    }
  }

  loadPlacesAPI(){    
    this.mapsAPILoader.load().then(() => {
    
      this.geoCoder = new google.maps.Geocoder;      

      let autocomplete = new google.maps.places.Autocomplete(this.searchElement.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;          
          this.shareDataService.sendAddress({
            address: place.name,
            latitude: this.latitude,
            longitude: this.longitude
          })
        });
      });
    });
  }

  markerDragEnd($event: MouseEvent) {    
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {    
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {      
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          this.searchElement.nativeElement.value = this.address;
          this.shareDataService.sendAddress({
            address: this.address,
            latitude: latitude,
            longitude: longitude
          })
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 15;
      });      
    }
  }

}