import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";

import serviceConfig from '../service-config';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAuthState } from '../../core/auth/_selectors/auth.selectors';
import { AppState } from '../../core/reducers/index';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class EstimateService {

  uri = serviceConfig.altBaseUrl;
  headers: HttpHeaders;
  userId: '';

  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.store.select(selectAuthState).subscribe((result) => {
      var decoded = jwt_decode(result.authToken);
      //real userid.
      this.userId=decoded['https://mardom.com/clientId'];
      
      this.headers =new HttpHeaders()
                    .append('Authorization', "Bearer "+result.authToken)  
                    .append('Content-Type', 'application/json')
                    .append('accept','*/*')
                    .append('Allow', '*')
                    .append('Access-Control-Allow-Origin', '*')
                    .append('Access-Control-Allow-Methods', 'POST, GET, PUT, DELETE, OPTIONS')
                    .append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept, Referer, User-Agent')
                    .append('accept','text/plain');
    });
  }

  getDataByTally(tally: string): Observable<any> {
    let url = this.uri + serviceConfig.endPoints.warehouseTHByTally;
    return this
      .http
      .get<any>(`${url}/${tally}`,
        { headers: this.headers });
  }

  getDataByBL(id: string): Observable<any> {
    let url = this.uri + serviceConfig.endPoints.warehouseTHByBL;
    return this
      .http
      .get<any>(`${url}/${id}`,
        { headers: this.headers });
  }
  getAllData(): Observable<any> {
    let url = this.uri + serviceConfig.endPoints.warehouseTHAll;
    return this
      .http
      .get<any>(`${url}`, { headers: this.headers });
  }
 
  getQuotationData(clientId: string, blId: string, downloadDate: string, cif: string, dispatchDate: string): Observable<any> {
    let url = this.uri + serviceConfig.endPoints.warehouseTHQuotation;
    return this
      .http
      .get<any>(`${url}/?ClientId=${clientId}&BlId=${blId}&DownloadDate=${downloadDate}&CifValue=${cif}&DispatchDate=${dispatchDate}&Consult=true`,
        { headers: this.headers });
  }

  downloadReport(id): Observable<any> {
    let url = this.uri + serviceConfig.endPoints.downloadInvoiceStorageQuote;
    return this.http.get(`${url}/${id}`, { headers: this.headers, responseType: 'blob' });
  }

}
