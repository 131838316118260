// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  isMockEnabled: true, // You have to switch this, when your real back-end is done
  accessTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
  idTokenKey: 'auth6c8cdbc3b812e663429f67a5697cceb7',
  antiCSRFTokenKey: 'auth52af7224499fe176dd223135a412a0f6',
  tokensExpirationDateKey: 'exp58c6607aca90dedfc78b8fe52b65d5ac',


  auth: {
    clientID: '#{authClientID}#',
    domain: '#{authDomain}#',
    connection: '#{connection}#',
    audience: '#{audience}#',
    redirectUri: '#{redirectUri}#'
  },
  Endpoint: {
    baseUrl: '#{baseUrl}#',
    altBaseUrl: '#{altBaseUrl}#',
    filesUrl: '#{filesUrl}#',
    trackAndTraceBaseUrl: '#{trackAndTraceBaseUrl}#',
    customsFilesUrl: '#{customsFilesUrl}#',
    manifestBaseUrl:'#{manifestBaseUrl}#', 
    azualApprovedUrl: '#{azualApprovedUrl}#',
    azualDeclinedUrl: '#{azualDeclinedUrl}#',
    azualCancelUrl: '#{azualCancelUrl}#',
    logisticWarehouseBaseUrl:'#{logisticWarehouseBaseUrl}#',
    logisticWarehouseQueryBaseUrl:'#{logisticWarehouseQueryBaseUrl}#'
   }
 
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
