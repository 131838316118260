import { ChangeDetectorRef, Component, HostListener, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { AppState } from '../../../core/reducers';
import { Logout, currentUser, User, AuthService } from '../../../core/auth';
import { selectAuthState } from '../../../core/auth/_selectors/auth.selectors';
import * as jwt_decode from "jwt-decode";
import { DOCUMENT } from '@angular/common';
import { SidebarService } from '../../../services/sidebar.service';
import { LogService } from '../../../services/log/log.service';
import { LogDto } from '../../../models/dto/log.dto';
import { Action } from '../../../models/enums/action.enum';
import { Screen } from '../../../models/enums/screen.enum';
var md5 = require('md5');
import { ClientService } from '../../../services/accountmanagement/client.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import auth0 from 'auth0-js';
import config from '../../../../../auth-config';
import { shareReplay } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TransferrequestService } from '../../../services/transferrequest/transferrequest.service'
import { PowerBILogService } from '../../../services/log/powerBILog.service';

@Component({
  selector: 'kt-mdheader',
  templateUrl: './mdheader.component.html',
  styleUrls: ['./mdheader.component.scss'],
  host: {
    "(window:click)": "onClick()"
  }
})
export class MdheaderComponent implements OnInit {
  @ViewChild('selectedEmpresa', { static: true }) selectedEmpresa: TemplateRef<any>;
  @ViewChild('errormsg', { static: true }) errormsg: TemplateRef<any>;
  @ViewChild('notification', { static: true }) notification: TemplateRef<any>;

  user$: Observable<User>;
  username: string = '';
  company: string = '';
  email = '';
  emailHash = '';
  elem: any; isFullScreen: boolean;
  token: any;
  payload$: Observable<any>;
  public roleName: string = 'Cliente';
  public responseList: any = [];
  public notiflist: any = [];
  selectedEmpresaname = 'seleccionar';
  isMenuOpen = false;
  scrollFlag = false;
  empresaId;
  public admin: Boolean = false;
  isadminrole: string = '';
  refreshcompany: any;
  public tokenRole: any
  public seennotif: any = [];
  public unreadnotif: any = [];
  public nulllist: boolean = false;
  authid: any;
  total: any;
  dott: boolean = false;
  public executionNoo: any;
  public executionnum: any;
  public Role: any;
  public Coordinator: boolean = false;
  notificationdeatils: boolean = false;
  public dsc: string = '';
  public clientcode = '';
  public isOpen: any = true;
  public title: string = '';
  public result: any = [];
  public totall: any;
  public SelectedCompany: string;
  //constructor(private authService: AuthService) { }
	public refToken: string;
	public module: any;
	public objectType: any;
	public objectName: any;
	public clickEvent: any;
	public action: any;
	public screenId: any;

  constructor(private store: Store<AppState>,
    private authService: AuthService,
    @Inject(DOCUMENT) private document: any,
    private logService: LogService,
    private sidebarService: SidebarService,
    private clientService: ClientService,
    private cd: ChangeDetectorRef,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private transferrequestService: TransferrequestService,
    private powerBIService: PowerBILogService

  ) {
  }

  ngOnInit() {
    this.getclientCode();
    this.getCompanies();
    this.chkScreenMode();
    this.elem = document.documentElement;
    this.user$ = this.store.pipe(select(currentUser));
    this.payload$ = this.store.pipe(select(selectAuthState));
    this.user$.subscribe((user) => {
      this.email = user.email;
      this.emailHash = user.email ? md5(user.email) : '';
      this.username = user.fullname;
      this.company = user.companyName;
    })
    this.payload$.subscribe(res => {
      let tokenData = jwt_decode(res.authToken)
      this.Role = tokenData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
      this.Coordinator = this.Role.includes('coordinator');
			console.log(this.Role)
			this.authid = tokenData.sub;
			/*this.logService.postWithPayload(<LogDto>{
				ActionId: Action.AccederPantalla,
				ScreenId: Screen.AccesoPlataforma,
				Json: '',
				UserName: res.user.fullname,
				Email: res.user.email,
				ClientId: tokenData['https://mardom.com/clientId'] == '' ? null : tokenData['https://mardom.com/clientId'],
				RoleName: tokenData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
			},
				res.authToken);*/
      console.log(this.Role)
      this.authid = tokenData.sub;
      this.tokenRole = tokenData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
			//this.setcompany();
		});


  }

  setcompany() {
	let recompany = JSON.parse(localStorage.getItem("Selectedcompany"));
	const newrole = recompany.roles ? recompany.roles : this.tokenRole
	console.log('recompany');
	console.log(newrole);
	this.isadminrole = newrole.find(item => item == 'Admin')
	//this.admin = this.isadminrole == 'admin' ? true : false;
	this.admin = this.isadminrole == 'Admin' ? true : false;
	if (newrole.find(item => item == 'Admin')) {
		this.roleName = 'Admin'
	} else if (newrole.find(item => item == 'coordinator')) {
		this.roleName = 'Coordinador'
	} else if (newrole.find(item => item == 'consolidador')) {
		this.roleName = 'Consolidador'
	} else { this.roleName = 'Cliente' }
	this.cd.detectChanges();
}

  toogleMenu() {
    this.sidebarService.toggleSidebarVisibility();
  }

  logout() {
		this.module = "Cerrarsesión"
		this.clickEvent = "Click";
		this.action = "Redirect";
		this.objectType = "Button";
		this.objectName = "Cerrar sesión";

    this.powerBIService.createPayload(this.screenId, this.module, this.clickEvent, this.action, this.objectType, this.objectName, null, null, null);

    this.store.dispatch(new Logout());

		this.store.dispatch(new Logout());
  }

  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])

  fullscreenmodes(event) {
    this.chkScreenMode();
  }
  chkScreenMode() {
    if (document.fullscreenElement) {
      //fullscreen
      this.isFullScreen = true;
    } else {
      //not in full screen
      this.isFullScreen = false;
    }
  }

  toggleFullScreen() {
    this.chkScreenMode()
    if (this.isFullScreen) {
      this.closeFullscreen();
    } else {
      this.openFullscreen();
    }
  }

  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      // / Firefox /
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      // / Chrome, Safari and Opera /
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      // / IE/Edge /
      this.elem.msRequestFullscreen();
    }
  }
  closeFullscreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      // / Firefox /
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      // / Chrome, Safari and Opera /
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      // / IE/Edge /
      this.document.msExitFullscreen();
    }
  }
  async getCompanies() {

    await this.clientService.getAssignCompany().subscribe(response => {
      this.responseList = response;
      this.selectedEmpresaname = response ? response[0].companyName : '';
			this.empresaId = response ? response[0].companyId : '';
			//  localStorage.setItem("Selectedcompany", JSON.stringify(response[0]));UAT
      let company = JSON.parse(localStorage.getItem("Selectedcompany"));
      if (company == null) {
        localStorage.setItem("Selectedcompany", JSON.stringify(response ? response[0] : ''));
      } else {
        let reCompany = JSON.parse(localStorage.getItem("Selectedcompany"));
        this.selectedEmpresaname = reCompany.companyName;
				this.empresaId = reCompany.companyId;
			}
			localStorage.setItem("SelectedcompanyID", this.empresaId);
			this.setcompany();
      this.cd.detectChanges();
    })
  }

  selecteEmpresa(name, id, company) {
    this.refToken = localStorage.getItem('refreshToken');
    localStorage.setItem("Selectedcompany", JSON.stringify(company));
    this.empresaId = id;
		localStorage.setItem("SelectedcompanyID", this.empresaId);
		console.log("Empresa Id:", this.empresaId);
    this.cd.detectChanges();
    if (this.selectedEmpresaname === name) {
      this.toastr.success('Ya has elegido esta empresa, elige otra.', 'Confirmación');
    } else {
      this.SelectedCompany = this.selectedEmpresaname;
      this.selectedEmpresaname = name;
      this.modalService.open(this.selectedEmpresa, {
        size: 'sm', backdrop: 'static', centered: true
      });
    }

  }

  async Confirmar() {
    this.store.dispatch;
    this.modalService.dismissAll();
    //this.authService.getNewToken(this.empresaId);
    await this.clientService.getRefreshToken(this.refToken, this.empresaId).subscribe((response) => {
      console.log(response)
      this.authService.refreshToken(response);
    });
		
		this.module = "AccesoPlataforma"
		this.clickEvent = "Switch Company";
		this.action = "Select";
		this.objectType = "Button";
		this.objectName = "Seleccionar";

		this.integrateAuditApi();

		// this.payload$ = this.store.pipe(select(selectAuthState));
		// this.payload$.subscribe(res => {
		//   let tokenData = jwt_decode(res.authToken);
		//   let newrole = tokenData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
		//   if (newrole.length == 1) {
		//     this.admin = newrole[0] == 'admin' ? true : false;
		//     this.cd.detectChanges();
		//   } else {
		//     let ad = newrole.find(item => item == 'admin');
		//     this.admin = ad == 'admin' ? true : false;
		//     this.cd.detectChanges();
		//   }
		// })
    this.screenId = Screen.AccesoPlataforma;
    this.powerBIService.createPayload(this.screenId, this.module, this.clickEvent, this.action, this.objectType, this.objectName, this.empresaId, null, null);

  }

  integrateAuditApi() {
    this.powerBIService.createPayload(this.screenId, this.module, this.clickEvent, this.action, this.objectType, this.objectName, null, null, null);
  }

  closeModal() {
    this.selectedEmpresaname = this.SelectedCompany;
    this.modalService.dismissAll();
  }

  counter(i: number) {
    return new Array(i);
  }

  toggleMenu($event) {

    $event.stopPropagation();
    this.isMenuOpen = !this.isMenuOpen;
    if (this.isMenuOpen && this.responseList.length == 0) {
      this.toastr.info('El usuario no tiene una empresa asociada.', 'warning');
    }
  }

  onClick() {
    this.isMenuOpen = false;
  }

  getnotif() {
    this.getclientCode();
    this.screenId = Screen.Notificacionesnoleídas;
    this.module = "Notificacionesnoleídas";
    this.clickEvent = "Click";
    this.action = "View";
    this.objectType = "Button";
    this.objectName = "notifications";
    this.integrateAuditApi();

    this.modalService.open(this.notification, {
      size: 'lg',

    });
  }

  cancel() {
    this.modalService.dismissAll();
    this.unreadnotification();
  }

  seennotification(event) {
    this.clientService.SetSeenNotificationDetail(event.id).subscribe((res: any) => {
      this.result = res.result
      console.log(this.result);
      if (this.result == "Success") {
        this.total--;
        this.cd.detectChanges();
      }
    }, error => {
      console.log(error)

    })
  }

  unreadnotification() {
    this.clientService.UnreadNotificationList(this.clientcode).subscribe((response: any) => {
      this.unreadnotif = response.notifications;
      this.total = response.totalCount;
      this.totall = response.totalCount;
      if (this.unreadnotif.length == 0) {
        this.dott = true;
      }
      if (this.total >= 99) {
        this.totall = '99+';
      } else {
        this.total
      }
      this.cd.detectChanges();
    })
  }

  mensaje(data, k) {
    this.dsc = data.description
    document.getElementById("collapse" + k).classList.toggle("show");
    this.cd.detectChanges();
    this.seennotification(data)
  }

  getclientCode() {
    this.transferrequestService.getClientCode().subscribe(res => {
      this.clientcode = res.ClientCode;
      this.unreadnotification();
    })
  }
}
