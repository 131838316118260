import { Injectable } from '@angular/core';
import * as lodash from 'lodash'

@Injectable({
  providedIn: 'root'
})
export class LodashService {
  constructor(){}

  IsObjectEqual(firstObject: any, secondObject: any): Boolean {
    return lodash.isEqual(firstObject, secondObject);
  }
}
