// Angular
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
// RxJS
import { Observable, zip } from 'rxjs';
import { tap, map } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
// Auth reducers and selectors
import { AppState } from '../../../core/reducers/';
import { isLoggedIn, isLoggingIn } from '../_selectors/auth.selectors';
import { AuthService } from '../_services/auth.service';
import { LogService } from '../../../services/log/log.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private authService: AuthService,
    private logService: LogService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const loggedInObservable = this.store.pipe(select(isLoggedIn));
    const loggingInObservable = this.store.pipe(select(isLoggingIn));
    console.log(route);
    return zip(loggedInObservable, loggingInObservable)
      .pipe(
        tap(([loggedIn, loggingIn]) => {
          if (!(loggedIn || loggingIn)) {
            this.authService.logindirect(state.url,false);
          }
        }),
        map(([loggedIn]) => {
          return loggedIn;
        })
      );
  }
}
