export class ResponseResult<T> {
    Count: number;
    Response: T[]
}

export class ResponseStorageResult<T> {
    Total: number;
    Response: T[]
}

export class invoiceResult<T> {
    total: number;
    results: T[]
}