import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  forwardRef,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core'
import { FormGroup, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms'

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PasswordInputComponent),
  multi: true,
}

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class PasswordInputComponent implements OnInit {
  @Input() idd = ''
  @Input() text = ''
  @Input() placeholder: string
  @Input() control: FormControl = new FormControl()
  @Input() optional: boolean = false
  @Input() submitted = false

  @Input() set ngModel(value) {
    this.value = value
  }

  @Output() ngModelChange = new EventEmitter<string>()

  hasError = false
  isPasswordVisible = false

  private innerValue: string = ''

  get value(): any {
    return this.innerValue
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v
    }
  }

  @Input()
  focused = false

  @ViewChild('input',{ static: false }) inputRef: ElementRef

  constructor() {}

  private watchErrors() {
    ;(<any>this.control).watch('errors', (value) => {
      if (this.control.errors) {
        this.hasError = true
      } else {
        this.hasError = false
      }
    })
  }
  ngOnInit() {
    this.watchErrors()
  }

  ngAfterViewInit() {
    if (this.placeholder === undefined) {
      this.placeholder = 'Enter ' + this.text
    }

    this.control.valueChanges.subscribe(() => {
      if (
        this.control.value == '' ||
        this.control.value == null ||
        this.control.value == undefined
      ) {
        this.innerValue = ''
        this.inputRef.nativeElement.value = ''
      }
    })
  }

  onChange(e: Event, value: any) {
    this.value = value
    this.ngModelChange.emit(this.value)
    this.propagateChange(this.value)
  }

  propagateChange = (_: any) => {}
  propagateTouched = () => {}

  writeValue(value: any) {
    this.innerValue = value
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn
  }

  registerOnTouched(fn: any) {
    this.propagateTouched = fn
  }

  showPassword() {
    this.isPasswordVisible = !this.isPasswordVisible
  }

  onFocus() {
    this.propagateTouched()
    this.focused = true
  }

  onFocusOut() {
    this.focused = false
  }
}
