export enum Screen {
    AccesoConsultaFacturas = 'D125D2DD-EEC7-4E25-B5BB-0C4449B40E85',
    AccesoPlataforma = '0DF0D701-F430-4AE4-962A-6CC8EA9F78D9',
    CargaComprobantesPago = 'DD0B926C-074E-47A2-9D8C-6843B11596D3',
    ConsultaSolicitudes = '1AB9286A-41A5-49AF-A291-3B0EDBA71A4E',
    DespachoAlmacen = 'F75B0236-FE10-41CB-9A5B-A89695C6722B',
    EncuestaSatisfaccion = '9399A950-1568-4DC6-9C7B-423DFDDAA970',
    EntradaAlmacen = 'DC593099-B3D8-4444-B7D9-5D695B676A3D',
    EnvioMensaje = 'BC078397-4BA1-4956-B7CA-9D21E907FE7D',
    EstimadoDesconsolidación = '190940C9-231C-4B09-826D-770EA4904E08',
    // ImpresionCotizacionAlmacenajeDesconsolidacion = '3475024D-1DB6-4009-9DDF-8E06B2AA5323',
    ImpresionFactura = 'DA31D786-6627-49FC-A929-6EF1E8EE1BD5',
    // PagoFactura = 'E136686E-4F69-473E-8A14-DFF1947210FD',
    TasaCambio = '68A858BD-2247-47C7-95A1-F3A7A40D5D50',
    Tracking = '082F7265-98A4-4839-ABEA-6007153F362E',
    SolicitudTrasladoCrearSolicitud = '137f27a8-06a2-4534-98a6-071434723393',
    SolicitudTrasladoConsultasolicitudes = '1785D6FF-3AF7-43C9-A36A-0BABA5351181',
    SolicitudTrasladoConsultatarjas = 'A726EDD8-C046-4EEA-8B66-6712AD94B24E',
    CambiarEmpresa = 'E97C3E96-AFA5-4E29-A4A6-EFB6FC87304',
    GestióndeUsuarios = '2391EDE3-B1E4-4E21-B09C-47D49DFA5464',
    MiPerfil = 'CABDCAA7-BA1B-4B40-9C53-98443B414FA5',
    IniCio = '9125B555-17AF-4892-84AF-5375F4F8D1AA',
    TusFacturas = "92C4B547-25FE-4F4F-98B9-4018006BDD54",
    LocalizatusCargas = "48A280A1-A1A3-46FC-8A0B-903D539C3E29",
    AlmacenajeInventarioenAlmacén = "65EA7AF3-DCED-4CC7-BAE5-630948F74121",
    EntradadeAlmacénCrearEntrada = "A4179C32-CA01-44B2-A27D-CAD5C272F52C",
    EntradadealmacénCrearProducto = "13F885C7-821E-4B45-B115-497174412968",
    EntradadeAlmacénSolicitudesdeProductos = "57C2ABAD-6C3B-4741-A634-0BF79F61BD3B",
    DespachodeAlmacénCrearSolicitud = "07022D58-B8D5-4DED-9FC6-DAB1C85118EC",
    Notificacionesnoleídas = "59B38DB1-5884-46BF-8C89-BA47E64FD060",
    Cerrarsesión = "2FC23F93-B668-426E-A08A-60F182F6DAC3",
    Configuracióndenotificaciones = "FE8A0685-A2F4-44C4-A20A-EB37E3F1B026",
    ServicioConsultadesolicitudes = "6DA42EEE-0828-4548-89B9-5507AB2A028C",
    ServiciosVistosRecientemente = "F6669B3D-4F60-4426-B4C4-DD8260A3050B",
    PedidosEnCurso = "F09E17F3-2416-462E-A202-9FD91FF4AC27",
    PedidosPróximosaLlegar = "45B9A4C9-145A-4B2D-B8C4-E16FA47E5D74",
    PedidosPendientesdeConfirmar = "B13CE1E8-0F5A-43AA-A68A-DF003E64B7AE",
    PedidosEntregados = "E79E28A4-2573-4B7A-838F-0C99F208F96A",
    Favoritos = "20B7EE81-562E-45F6-9842-C1D88D25D811",
    Vertodaslasnotificaciones = 'D434BBB0-8436-4375-A645-11AE2F74B448',
    Bienvenida = '8898B128-BFF1-45E7-9058-3B72491BE853',
    Registrarme = '2EF2BD8C-0763-481F-BFCA-03CA0A3CCAF1',
    Recuperarcontrasena = '651CC950-B5E1-43AC-A71D-89DFE450B14F'
}