import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import serviceConfig from '../service-config';
import { Observable } from 'rxjs';
import { AppState } from '../../core/reducers/index';
import { Store } from '@ngrx/store';
import { selectAuthState } from '../../core/auth/_selectors/auth.selectors';
import { ChangeStatusModel } from '../../models/changestatus';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class ResponsibleService {
  alturi = serviceConfig.altBaseUrl;
  uri = serviceConfig.baseUrl;

  altHeaders: HttpHeaders;
  headers: HttpHeaders;
  userId;
  clientName;

  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.store.select(selectAuthState).subscribe(result => {
      var decoded = jwt_decode(result.authToken);
      //real userid.
      this.userId = decoded['https://mardom.com/clientId'];
      var decodediD = jwt_decode(result.idToken);

      this.clientName = decodediD['name'];

      this.altHeaders = new HttpHeaders()
        .append('Authorization', 'Bearer ' + result.authToken)
        .append('Content-Type', 'application/json')
        .append('accept', '*/*')
        .append('accept', 'text/plain');

      this.headers = new HttpHeaders()
        .append('Authorization', 'Bearer ' + result.authToken)
        .append('Access-Control-Allow-Origin', '*')
        .append('Content-Type', 'application/json')
        .append(
          'Access-Control-Allow-Headers',
          'Origin, X-Requested-With, Content-Type, Accept'
        )
        .append('accept', '*/*')
        .append('accept', 'text/plain');
    });
  }

  getResponsibles() {
    //let url = this.alturi + serviceConfig.endPoints.responsibleCustomer;
    let url = this.alturi + serviceConfig.endPoints.responsible;
    return this.http.get(url, { headers: this.altHeaders });
  }

  getResponsiblesCustomer() {
    let url = this.alturi + serviceConfig.endPoints.responsibleCustomer;

    return this.http.get(url, { headers: this.altHeaders });
  }
  getRequestResponsible(type: string) {
    let url =
      this.alturi + serviceConfig.endPoints.responsibleRequestTypeCustomer;
    var requestType = ''; //type=='customs'?serviceConfig.requesttypes.customs:serviceConfig.requesttypes.transport;
    switch (type) {
      case 'import': {
        requestType = serviceConfig.requesttypes.import;
        break;
      }
      case 'customs': {
        requestType = serviceConfig.requesttypes.customs;
        break;
      }
      case 'voucher': {
        requestType = serviceConfig.requesttypes.voucher;
        break;
      }
      default: {
        requestType = serviceConfig.requesttypes.transport;
        break;
      }
    }

    return this.http.get(url + requestType, { headers: this.altHeaders });
  }
  getResponsibleLine(line: string) {
    let url = this.alturi + serviceConfig.endPoints.responsibleLine;
    var linesId = ''; //type=='customs'?serviceConfig.requesttypes.customs:serviceConfig.requesttypes.transport;
    switch (line) {
      case 'Geest': {
        linesId = serviceConfig.lines.geest;
        break;
      }
      case 'Baltic': {
        linesId = serviceConfig.lines.baltic;
        break;
      }
      case 'Cosiarma': {
        linesId = serviceConfig.lines.cosiarma;
        break;
      }

      default: {
        linesId = serviceConfig.lines.freightForward;
        break;
      }
    }

    return this.http.get(url + linesId, { headers: this.altHeaders });
  }
  changeStatus(sModel: ChangeStatusModel) {
    sModel.changedUser = this.userId;
    sModel.changedUserName = this.clientName;

    let url = this.uri + serviceConfig.endPoints.changeStatus;
    //this.http.post(`${url}`, sModel, { headers: this.headers }).subscribe(res => console.log('Done'));;
    return this.http.post(`${url}`, sModel, { headers: this.altHeaders });
  }
}
