import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import serviceConfig from '../service-config';
import { Observable, throwError } from 'rxjs';
import { AppState } from '../../core/reducers/index';
import { Store } from '@ngrx/store';
import { selectAuthState } from '../../core/auth/_selectors/auth.selectors';
import * as jwt_decode from 'jwt-decode';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class ClientService {

  alturi = serviceConfig.altBaseUrl;
  alturi2 = serviceConfig.altBaseUrl2;
  uri = serviceConfig.logisticWarehouseBaseUrl;
  altHeaders: HttpHeaders;
  fileHeaders:HttpHeaders;
  public userId;

  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.store.select(selectAuthState).subscribe((result) => {
      var decoded = jwt_decode(result.authToken);
      //real userid.
      this.userId = decoded['sub'];

      this.altHeaders = new HttpHeaders()
        .append('Authorization', "Bearer " + result.authToken)
        .append('Content-Type', 'application/json')
        .append('accept', '*/*')
        .append("Access-Control-Allow-Origin", "*")
        .append("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept")
        .append('accept', 'text/plain');

        this.fileHeaders = new HttpHeaders()
        .append('Authorization', "Bearer " + result.authToken)
        .append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept, Referer, User-Agent')
    });
  }
  getCurrentUser() {
    return this.userId;
  }
  getCustomers() {
    let url = this.alturi + serviceConfig.endPoints.customers;
    return this.http.get(url, { headers: this.altHeaders });
  }

  getEmployee() {
    let url = this.alturi + serviceConfig.endPoints.employeesAccount;
    return this.http.get(url, { headers: this.altHeaders });
  }
  getUserCompany() {
    //let url = this.alturi + serviceConfig.endPoints.user; //FOR PROD ENV
    let url = this.alturi2 + serviceConfig.endPoints.user; //FOR UAT ENV

    return this.http.get(url, { headers: this.altHeaders });
  }
  getAssignCompany(){
    let url = this.alturi + serviceConfig.endPoints.assignUser;
    return this.http.get(url, { headers: this.altHeaders });

  }
  getCountry() {
    let url = this.alturi + serviceConfig.endPoints.country;
    return this.http.get(url, { headers: this.altHeaders });
  }

  getCompanySearch(companyName) {
   let url = this.alturi + serviceConfig.endPoints.companySearch + companyName;
    return this.http.get(url, { headers: this.altHeaders });
  }
  getUserProfile() {
    //let url = this.alturi + serviceConfig.endPoints.getUserProfile; //FOR PROD ENV
    let url = this.alturi2 + serviceConfig.endPoints.getUserProfile; //FOR UAT ENV

    return this.http.get(url, { headers: this.fileHeaders });
  }

  getUserAddress() {
    //let url = this.alturi + serviceConfig.endPoints.getUserAddress; //FOR PROD ENV
    let url = this.alturi2 + serviceConfig.endPoints.getUserAddress; //FOR UAT ENV

    return this.http.get(url, { headers: this.altHeaders });
  }
  addCompany(payload) {
    //let url = this.alturi +  serviceConfig.endPoints.companyAdd; //FOR PROD ENV
    let url = this.alturi2 + serviceConfig.endPoints.companyAdd; //FOR UAT ENV

    return this.http.post(`${url}`, payload, { headers: this.altHeaders }).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  saveUserDetails(payload) {
    //let url = this.alturi + serviceConfig.endPoints.userDetails; //FOR PROD ENV
    let url = this.alturi2 + serviceConfig.endPoints.userDetails; //FOR UAT ENV

    return this.http.post(`${url}`, payload, { headers: this.fileHeaders }).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  addAddress(payload) {
    //let url = this.alturi + serviceConfig.endPoints.addressAdd; //FOR PROD ENV
    let url = this.alturi2 + serviceConfig.endPoints.addressAdd; //FOR UAT ENV

    return this.http.post(`${url}`, payload, { headers: this.altHeaders }).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  deleteAddress(id){
    //let url = this.alturi + serviceConfig.endPoints.deleteAddress+id; //FOR PROD ENV
    let url = this.alturi2 + serviceConfig.endPoints.deleteAddress + id; //FOR UAT ENV

    return this.http.get(`${url}`,{ headers: this.altHeaders })
  }
  updateAddress(payload) {
    //let url = this.alturi + serviceConfig.endPoints.updateAddress; //FOR PROD ENV
    let url = this.alturi2 + serviceConfig.endPoints.updateAddress; //FOR UAT ENV

    return this.http.post(`${url}`, payload, { headers: this.altHeaders }).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  sendMailToCompanyUser(data){
    //let url = this.alturi +  serviceConfig.endPoints.SendMailToCompanyUser + '?Type=2' +'&emailId='+ data.useremailId + '&Admin=' + data.adminemail + '&NombreDeRole=' + data.role + '&empresa=' + data.company + '&UserName=' + data.UserName; //FOR PROD ENV
    let url = `${this.alturi2}${serviceConfig.endPoints.SendMailToCompanyUser}?Type=2&emailId=${data.useremailId}&Admin=${data.adminemail}&NombreDeRole=${data.role}&empresa=${data.company}&UserName=${data.UserName}`; //FOR UAT ENV
    
    return this.http.get<any[]>(`${url}`,{ headers: this.altHeaders });
  }

  getRefreshToken(refreshtoken:string,companyid:string):Observable<any>{
    let url=this.alturi + serviceConfig.endPoints.refreshToken + '?refreshtoken='+ refreshtoken + '&companyid=' + companyid;
    return this.http.get<any>(`${url}`,{ headers: this.altHeaders })
  }


  NotificationList(pageNumber,pageSize,clientCode){
    //let url = this.alturi + serviceConfig.endPoints.NotificationList + '?pageNumber='+ pageNumber + '&pageSize=' + pageSize + '&clientCode=' + clientCode; //FOR PROD ENV 
    let url = `${this.alturi2}${serviceConfig.endPoints.NotificationList}?pageNumber=${pageNumber}&pageSize=${pageSize}&clientCode=${clientCode}`; //FOR UAT ENV

    return this.http.get<any[]>(`${url}`,{ headers: this.altHeaders });
  }

  SetSeenNotificationDetail(NotificationId){
    //let url = this.alturi + serviceConfig.endPoints.SetSeenNotificationDetail + '?NotificationId=' + NotificationId; //FOR PROD ENV
    let url = `${this.alturi2}${serviceConfig.endPoints.SetSeenNotificationDetail}?NotificationId=${NotificationId}`; //FOR UAT ENV

    return this.http.get<any[]>(`${url}`,{ headers: this.altHeaders });
  }

  UnreadNotificationList(clientCode){
 //let url = this.alturi + serviceConfig.endPoints.UnreadNotificationList + '?clientCode=' + clientCode; //FOR PROD ENV
 let url = `${this.alturi2}${serviceConfig.endPoints.UnreadNotificationList}?clientCode=${clientCode}`; //FOR UAT ENV

   return this.http.get<any[]>(`${url}`,{ headers: this.altHeaders });

  }

  getAllClients(name){
    let url = this.alturi + serviceConfig.endPoints.getAllClients + '?name='+ name 
    return this.http.get<any[]>(`${url}`,{ headers: this.altHeaders });
  }
}
