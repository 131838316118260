import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAuthState } from '../../../app/core/auth/_selectors/auth.selectors';
import { AppState } from '../../../app/core/reducers';
import serviceConfig from '../service-config';
import * as jwt_decode from 'jwt-decode';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SalesexecutiveService {
  
  uri = serviceConfig.altBaseUrl ;
  billuri = serviceConfig.baseUrl + serviceConfig.endPoints.billVoucher;
  headers = new HttpHeaders()
  altHeaders: HttpHeaders;
  userId:'';
  clientName:'';
  constructor(private http:HttpClient, private store: Store<AppState>) { 
    this.store.select(selectAuthState).subscribe((result) => {  
      this.headers = new HttpHeaders().set('Authorization', "Bearer "+result.authToken);

      var decoded = jwt_decode(result.authToken); 
      //real userid.
      this.userId=decoded['https://mardom.com/clientId'];
      var decodediD = jwt_decode(result.idToken); 
      
      this.clientName=decodediD['name'];
      
      this.altHeaders =new HttpHeaders()
                    .append('Authorization', "Bearer "+result.authToken)  
                    .append('Content-Type', 'application/json')
                    .append('accept','*/*')
                    .append('Allow', '*')
                    .append('Access-Control-Allow-Origin', '*')
                    .append('Access-Control-Allow-Methods', 'POST, GET, PUT, DELETE, OPTIONS')
                    .append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept, Referer, User-Agent')
                    .append('accept','text/plain');
    });
  }

  getsalesexecutive() : Observable<any>
  {
    let url = this.uri + serviceConfig.endPoints.salesexecutive;
		return this.http.get<any>(`${url}`, { headers: this.altHeaders});
  }
}
