import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  AfterViewInit,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VoucherService } from '../../services/invoice/voucher.service';
import { ResponsibleService } from '../../services/responsiblemanagement/responsible.service';
import { ServicefileService } from '../../services/files/servicefile.service';
import { Subject, Observable } from 'rxjs';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { formatDate } from '@angular/common';
import { AzurefileService } from '../../services/files/azurefile.service';
import serviceConfig from '../../services/service-config';
import { ChangeStatusModel } from '../../models/changestatus';
import { ServiceFileModel } from '../../models/servicefile';
import { ClientService } from '../../services/accountmanagement/client.service';
import { ToastrService } from 'ngx-toastr';
import { AppState } from '../../core/reducers/index';
import { select, Store } from '@ngrx/store';
import * as jwt_decode from 'jwt-decode';
import {
  currentUserRoleIds,
  selectAuthState
} from '../../core/auth/_selectors/auth.selectors';
import { push } from 'object-path';
import { selectAllPermissions } from '../../core/auth/_selectors/permission.selectors';
import { Router } from '@angular/router';
import * as forge from 'node-forge';
import { LogService } from '../../services/log/log.service';
import { LogDto } from '../../models/dto/log.dto';
import { Action } from '../../models/enums/action.enum';
import { Screen } from '../../models/enums/screen.enum';
import { PowerBILogService } from '../../services/log/powerBILog.service';


@Component({
  selector: 'kt-search',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.scss']
})
export class VoucherComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('detailModal', { static: true }) detailModal: TemplateRef<any>;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  privateKey: string = `-----BEGIN RSA PRIVATE KEY-----
	MIIEpAIBAAKCAQEAskgPKBcNpz71mi4NSYa5mazJrO0WZim7T2yy7qPxk2NqQE7O
	mWWakLJcaeUYnI0kO3yC57vck66RPCjKxWuWSGZ7dHXe0bWb5IXjcT4mNdnUIalR
	+lV8czsoH/wDUvkQdG1SJ+IxzW64WvoaCRZ+/4wBF2cSUh9oLwGEXiodUJ9oJXFZ
	VPKGCEjPcBI0vC2ADBRmVQ1sKsZg8zbHN+guU9rPLFzN4YNrCnEsSezVw/W1FKVS
	8J/Xx4HSSg7AyVwniz8eHi0e3a8VzFg+H09I5wK+w39sjDYfAdnJUkr6PjtSbN4/
	Sg/NMkKB2Ngn8oj7LCfe/7RNqIdiS+dQuSFgeQIDAQABAoIBAQCtAyEyVqz5UTAn
	n+llIUmcyQIuvyatnnIYcfRzgsY9soy6O8BjPSbypSTp31ozEwXGdN7U5Ir7Br4L
	E8s0K2R8fnfwXTMPcJ8I9qXyj82lFO+4ewkDV1KewRn/cCIJs4s0caL48ZpDMt/V
	6kC58QruFIA6pdwxtij09RPwDg7E3U1MrL9PqgGXXQcAwtboP7gHs2ac+0b6CwV0
	Cyev09Xpni3ID0iKX8yKsxi6wOh+2O4Mz8B3R2gIXq7Gzw8oeho0nSBD19B7fWs+
	w4vFlHIl57zbeISQK10cvvJ8BB4byy/bn5V2O2lESgU10d48csD5IUcjdV4/yuFx
	vI8S9vfBAoGBAOqbw03WXnhZQu8O7Gos8P7lrBi3eYdF45hHAfWbK5ub6O7fC0kQ
	FYZqO0AR7oEAzuApWP0uGxQFALZ0QPyFZbpnKinE9T9a4M5AaP1Ex8WmeWbto96b
	md6jVRXKXz24x5GDk+UdF7PJF91DufpUB5ZaO/AXpB9/3R9BDu7JHTjzAoGBAMKJ
	grfZmyH3B9Cslnb8HUSlbXZQ/tnLoc3DpXnaENpYKWz/FMjqxTHuVRRqH86tYL33
	/ow3UCaN3EYgIguoVC+DTbzk3/I9IXXeDXrocH1qCUy+x6dohzPoKZMoILLs+VeF
	eAJfIptgVIfcD5teKdEY4lYPbV0m47Q2ET8pgNvjAoGADWgDPBJw6Y2oPoLqzZJt
	3xL+x5bMcgdzXwNHwGIylrzNDcFfIrixkjHF2v5rU6HZMIQMWsNktrsmdEamZAe3
	bXRxu1tO7GTkDcUuerKQe+HsdA5GW/UmUMuilBExahSCCvWq4N/Nt+9Y1sMFAKIG
	oPfdDvY64bXry9pq4QhLDDMCgYAGs9ovH24641n/lJKyksknXlPBKdU+B8gRYNZI
	tnyyKSt0N+2tZnlY2Q9RXr6tzkdxBCRfDGDUkzqtp9nEcQ5FfM6m3XPh0r1eZEIu
	o+eMSqT7Ye9bEzzlSvBzw4Qs5gFJRK331vNISy9eZvSas8nZDHRv5qQr+3UI9nvt
	oy5vZQKBgQCK5z2IeL1NFd5OuJaR07Y+xwO7IOiZ+nn+fXWYkZ4bD08Dg0ACnd/w
	OcS+2fU2/IIuXnhT/f5M5r0AJI1lG/V8TBo4gxN2dm7yBLgczbK3u+tex+pEnfEZ
	qxAE0159q3iGvU5nmtX6VC0Z/IJIUCVgiEMwoKct0T5eOV2iQf5PaQ==
	-----END RSA PRIVATE KEY-----`;
  uploadedFiles: ServiceFileModel[] = [];
  formData = {};
  searchForm: FormGroup;
  statusForm: FormGroup;

  dtTrigger: Subject<any> = new Subject();
  dtOptions: any = {};
  blDTOptions: any = {};
  changeModel: ChangeStatusModel;
  executiveUsers = [];
  vouchers = [];
  responsibleId = '';
  adminFilter = false;
  canSaveResponsible = false;

  singleSelectOptions = {
    width: '100%',
    multiple: false,
    placeholder: 'Selecccionar...'
  };

  executiveUserSelected: any;

  voucherSelected: any;
  permissions: any[];
  payload$: any;
  public module: any;
  public objectType: any;
  public objectName: any;
  public clickEvent: any;
  public action: any;
  public screenId: any;
  public refNumber: any;
  public refType: any;

  constructor(
    private voucherService: VoucherService,
    private responsibleService: ResponsibleService,
    private modalService: NgbModal,
    private clientService: ClientService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private store: Store<AppState>,
    private sFileService: ServicefileService,
    private azFileService: AzurefileService,
    private chRef: ChangeDetectorRef,
    private logService: LogService,
    private router: Router,
    private powerBIService: PowerBILogService
  ) {
    this.store.select(selectAllPermissions).subscribe(res => {
      this.permissions = res;
    });
  }

  showModal(modal) {
    this.modalService.open(modal, { size: 'lg' });
  }

  hasAccess(moduleAction: string): boolean {
    return (
      this.permissions.findIndex(
        permission => permission.name === moduleAction
      ) > -1
    );
  }

  selectedItem(item) {
    if (this.responsibleId != item.value) this.canSaveResponsible = true;

    this.responsibleId = item.value;
  }

  selectStatus(item) {
    if (this.statusForm.value.statusType != item.value)
      this.canSaveResponsible = true;
  }

  ngOnInit() {
    this.payload$ = this.store.pipe(select(selectAuthState));

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      ordering: false,
      language: {
        lengthMenu: 'Mostrando _MENU_ registros por página',
        zeroRecords: 'No se encontraron registros',
        info: 'Mostrando página _PAGE_ de _PAGES_',
        infoEmpty: 'No se encontraron registros',
        infoFiltered: '(Filtrado de _MAX_ registros)',
        search: 'Filtrar solicitudes:',
        paginate: {
          previous: 'Anterior',
          next: 'Siguiente',
          last: 'Ultima',
          first: 'Primera'
        }
      }
    };
    this.statusForm = this.fb.group({
      statusType: [0],
      comment: ''
    });

    this.searchForm = this.fb.group({
      statusTypeFilter: [0],
      dateFrom: formatDate(new Date().setDate(-20), 'yyyy/MM/dd', 'en'),
      dateTo: formatDate(new Date(), 'yyyy/MM/dd', 'en'),
      requestId: '',
      reference: ''
    });

    this.screenId = Screen.CargaComprobantesPago;
    this.module = 'CargaComprobantesPago';
    this.clickEvent = "Click";
    this.action = "Redirect";
    this.objectType = "Button";
    this.objectName = "Comprobantes";
    this.integrateAuditApi();

    this.loadExecutiveUsers();
    this.checkUserRole();
    this.searchRequests('');
  }

  integrateAuditApi() {
    this.powerBIService.createPayload(this.screenId, this.module, this.clickEvent, this.action, this.objectType, this.objectName, this.refNumber, this.refType, null);
  }

  selectVoucher(voucher) {
    this.clearVoucher();
    this.voucherSelected = voucher;
    this.canSaveResponsible = false;
    this.statusForm = this.fb.group({
      statusType: [voucher.StatusId],
      comment: ''
    });

    this.executiveUserSelected = voucher.assigned_user;
    this.responsibleId = this.executiveUserSelected;
  }

  getUploadedDocuments(voucher) {
    this.sFileService
      .getServiceFiles(
        voucher.Tipo == 'Independiente' ? 'voucher' : 'invoice',
        voucher.id
      )
      .subscribe(result => {
        console.log(result);
        this.uploadedFiles = result;
        //this.rerender();
        this.chRef.detectChanges();
      });
  }

  checkUserRole() {
    this.store.select(selectAuthState).subscribe(result => {
      var decoded = jwt_decode(result.authToken);

      //real userid.
      var currentRoles =
        decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      var internal = currentRoles.indexOf('Client');

      if (!(internal > -1)) {
        this.adminFilter = true;
      } else {
        this.adminFilter = false;
      }
    });
  }

  changeDate() {
    let fromDate = new Date(this.searchForm.value.dateFrom);
    let toDate = new Date(this.searchForm.value.dateTo);

    if (fromDate > toDate) this.searchForm.get('dateTo').setValue('');
  }

  clearFields() {
    this.action = "Reset Info";
    this.objectType = "Button";
    this.objectName = "Limpiar";
    this.refNumber = null;
    this.refType = null;
    this.integrateAuditApi();
    this.searchForm = this.fb.group({
      statusTypeFilter: [0],
      dateFrom: formatDate(new Date().setDate(-20), 'yyyy/MM/dd', 'en'),
      dateTo: formatDate(new Date(), 'yyyy/MM/dd', 'en'),
      requestId: '',
      reference: ''
    });
  }

  downloadDocument(sfile) {
    console.log(sfile);
    this.azFileService.downloadFile(sfile.filename).subscribe(response => {
      const url = window.URL.createObjectURL(response);

      var fileLink = document.createElement('a');
      fileLink.href = url;
      fileLink.download = sfile.file_type;
      fileLink.click();
      //window.open(url);
    }),
      error => console.log('Error downloading the file'),
      () => console.info('File downloaded successfully');
  }
  showSuccess(message) {
    this.toastr.success(message, 'Confirmación.');
  }

  saveResponsibleStatus() {
    this.changeModel = new ChangeStatusModel();
    this.changeModel.billId = this.voucherSelected.id;
    this.changeModel.message = this.statusForm.value.comment;
    this.changeModel.serviceType = this.voucherSelected.Tipo;
    this.changeModel.estatusId = this.statusForm.value.statusType;
    this.changeModel.userId = this.responsibleId;
    this.responsibleService.changeStatus(this.changeModel).subscribe(data => {
      this.showSuccess('Información actualizada satisfactoriamente.');
      this.clearVoucher();
      this.searchRequests('');
    });
  }
  clearVoucher() {
    this.statusForm.reset(this.statusForm.value);
    this.statusForm = this.fb.group({
      statusType: [1],
      comment: ''
    });
  }

  loadExecutiveUsers() {
    this.responsibleService.getResponsibles().subscribe(
      (result: any) => {
        this.executiveUsers = result.map(item => {
          var rsa = forge.pki.privateKeyFromPem(this.privateKey);
          var idByte = forge.util.decode64(item.id);
          var id = rsa.decrypt(idByte);
          //return { id: item.codigo, text: item.nombre}
          return { id: id.replace('auth0|', ''), text: item.name };
        });

        //this.executiveUsers = [{id:'0',text:'--Seleccione Responsable--'}].concat(execU) ;
        this.executiveUsers.splice(0, 0, {
          id: '',
          text: 'Seleccionar responsable'
        });

        this.chRef.detectChanges();
      },
      error => {
        switch (error.status) {
          case 401:
            this.toastr.info(
              'No tiene los permisos para realizar esta acción.',
              'Aviso.'
            );
            break;
          case 500:
            this.toastr.error(
              'Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com'
            );
            break;
          case 403:
            this.toastr.info(
              'No tiene los permisos para realizar esta acción.',
              'Aviso.'
            );
            break;
          case 0:
            this.toastr.error(
              'Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com'
            );
            break;
          case undefined:
            this.toastr.error(
              'Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com'
            );
            break;
          default:
            this.toastr.error(
              'Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com'
            );
            break;
        }
        setTimeout(() => {
          this.router.navigateByUrl('/');
        }, 3000);
      }
    );
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  searchRequests(type) {
    if (type == 'search') {
      this.action = "Search";
      this.objectType = "Button";
      this.objectName = "Consultar";
      this.refNumber = this.searchForm.value.reference == "" ? null : this.searchForm.value.reference;
      this.refType = null;
      this.integrateAuditApi();
    }
    this.voucherService
      .getVoucherByDate(
        this.searchForm.value.dateFrom,
        this.searchForm.value.dateTo,
        this.searchForm.value.statusTypeFilter,
        this.searchForm.value.requestId == ''
          ? 0
          : this.searchForm.value.requestId,
        this.searchForm.value.reference == ''
          ? 0
          : this.searchForm.value.reference,
        this.adminFilter
      )
      .subscribe(data => {
        console.log(data);
        this.vouchers = data;
        this.rerender();
      });
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();

      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
      this.chRef.detectChanges();
    });
  }
}
