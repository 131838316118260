import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from '../../services/accountmanagement/client.service';
import { Observable } from 'rxjs';
import { User } from '../../core/auth';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../core/reducers';
import { currentUser } from '../../core/auth';
import moment from 'moment';
import { selectAuthState } from '../../core/auth/_selectors/auth.selectors';
import { LogDto } from '../../models/dto/log.dto';
import { Action } from '../../models/enums/action.enum';
import { LogService } from '../../services/log/log.service';
import { Screen } from '../../models/enums/screen.enum';
import * as jwt_decode from "jwt-decode";
import { DomSanitizer } from '@angular/platform-browser';
import { PowerBILogService } from '../../services/log/powerBILog.service';

declare var $: any;
@Component({
  selector: 'kt-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  @ViewChild('directionModal', { static: true }) directionModal: TemplateRef<any>;
  @ViewChild('companyModal', { static: true }) companyModal: TemplateRef<any>;
  @ViewChild('successModal', { static: true }) successModal: TemplateRef<any>;
  @ViewChild('empresaSuccess', { static: true }) empresaSuccess: TemplateRef<any>
  @ViewChild('directionSuccess', { static: true }) directionSuccess: TemplateRef<any>
  @ViewChild('updateSuccess', { static: true }) updateSuccess: TemplateRef<any>
  @ViewChild('userError', { static: true }) userError: TemplateRef<any>
  user$: Observable<User>;
  userForm: FormGroup;
  directionForm: FormGroup;
  companyForm: FormGroup;
  required: boolean = false;
  selDirPais = "Dominican Republic";
  selectedEmpresaPais = "Dominican Republic";
  selectedPais = 'Dominican Republic';
  selectedTitle = "Sr.";
  selectedRol = "Seleccionar.";
  paisFlag: boolean = true;
  RolEmpresa: any;
  address: any;
  public checkValidation: boolean = false;
  public checkDirection: boolean = false;
  public checkCompany: boolean = false;
  public currentStep = 0;
  public currentUserStep = 0;
  public responseList: any = [];
  public userList: any;
  public coutryList: any = [];
  public companyList: any = [];
  public addressList: any = [];
  public userId: any;
  public rnc: boolean = false;
  birhdate;
  public companyCode = '';
  public companyName;
  email = '';
  fullname = '';
  selectedCompany;
  companyRole: any;
  public url;
  public imagePath;
  imgURL: any;
  viewImage: any;
  viewImageSrc: any;
  public message: string;
  public fileName: any;
  public files;
  public dateTime;
  public payload$: Observable<any>;
  assignedcompany: any = [];
  public module: any;
  public objectType: any;
  public objectName: any;
  public clickEvent: any;
  public action: any;
  public screenId: any;
  public refNumber: any;


  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private clientService: ClientService,
    private cd: ChangeDetectorRef,
    private store: Store<AppState>,
    private logService: LogService,
    private sanitizer: DomSanitizer,
    private powerBIService: PowerBILogService
  ) { $('body').tooltip({ selector: '[data-toggle=tooltip]' }); }

  ngOnInit() {
    this.payload$ = this.store.pipe(select(selectAuthState));
    this.user$ = this.store.pipe(select(currentUser));
    this.user$.subscribe((user) => {
      this.email = user.email;
      this.fullname = user.fullname;
    })
    this.selectedCompany = JSON.parse(localStorage.getItem('Selectedcompany'));

    this.getCountries();
    this.generateUserForm();
    this.generateDirectionForm();
    this.generateCompanyForm();
    this.getUserProfile();
    this.getCompanies();
    this.getUserAddress();
    this.userForm.controls.Email.setValue(this.email);
    this.dateTime = +new Date();
    this.cd.detectChanges();

    this.screenId = Screen.MiPerfil;
    this.module = "MiPerfil";
    this.clickEvent = "Click";
    this.action = "Redirect";
    this.objectType = "Button";
    this.objectName = "Mi Perfil";
    this.integrateAuditApi();
  }

  generateDirectionForm() {
    this.directionForm = this.fb.group({
      DirTitle: ['', Validators.required],
      PaisDirection: ['', Validators.required],
      Estado: ['', Validators.required],
      Ciudad: ['', Validators.required],
      Cpostal: ['', Validators.required],
      Direccion: ['', Validators.required],
    });
  }
  generateCompanyForm() {
    this.companyForm = this.fb.group({
      Empresa: ['', Validators.required],
      PaisEmpresa: ['', Validators.required],
      RNC: ['', Validators.required],
      RolEmpresa: [''],
    });
  }
  generateUserForm() {
    this.userForm = this.fb.group({
      Title: ['', Validators.required],
      Nombre: ['', [Validators.required]],
      Apellido: ['', [Validators.required]],
      Fecha: ['', [Validators.required]],
      Email: [''],
      CelTelephone: ['', [Validators.required]],
      Pais: ['', [Validators.required]],
    });
  }
  openDirectionModel() {
    this.clickEvent = "Click";
    this.action = "Open";
    this.objectType = "Button";
    this.objectName = "Agregar Direcciones";
    this.integrateAuditApi();

    if (this.userList) {
      this.currentStep = 0;
      this.directionForm.reset();
      this.directionForm.controls.PaisDirection.setValue("Dominican Republic");
      this.modalService.open(this.directionModal, {
        size: 'lg', backdrop: 'static'
      });
    } else {
      this.toastr.info('Por favor, actualice su perfil primero');
    }

  }
  openCompanyModal() {
    this.clickEvent = "Click";
    this.action = "Open";
    this.objectType = "Button";
    this.objectName = "Asociar Empresa";
    this.integrateAuditApi();

    if (this.userList) {
      this.rnc = false;
      this.currentStep = 0;
      this.companyForm.reset();
      this.paisFlag = true;
      this.selectedEmpresaPais = "Dominican Republic";
      this.modalService.open(this.companyModal, {
        size: 'lg', backdrop: 'static'
      });
    } else {
      this.toastr.info('Por favor, actualice su perfil primero');

    }


  }
  openSuccessModal() {
    this.modalService.open(this.successModal, {
      size: 'sm', backdrop: 'static', centered: true
    });
    this.userForm.reset();
    this.userForm.controls.Email.setValue(this.email);
    this.userForm.controls.Fecha.setValue(this.birhdate);

  }
  empresaSuccessModal() {
    this.modalService.open(this.empresaSuccess, {
      size: 'sm', backdrop: 'static', centered: true
    });
  }
  directionSuccessModal() {
    this.modalService.open(this.directionSuccess, {
      size: 'sm', backdrop: 'static', centered: true
    });
  }
  updateSuccessModal() {
    this.modalService.open(this.updateSuccess, {
      size: 'sm', backdrop: 'static', centered: true
    });
  }
  closeModal() {
    this.modalService.dismissAll();
  }

  closeModalDirection() {
    this.modalService.dismissAll();
  }
  cerrar() {
    this.modalService.dismissAll();
  }
  guardar() {
    this.clickEvent = "Click";
    this.action = "Save";
    this.objectType = "Button";
    this.objectName = "Guardar";
    this.integrateAuditApi();

    this.currentStep++;
    if (this.currentStep == 1 && this.directionForm.invalid) {
      this.checkDirection = true
      this.currentStep--;
      this.toastr.info('Favor llenar todos los campos requeridos.', 'warning');
      return;
    } else {
      this.saveDirectionData();
      this.checkDirection = false;
    }
  }
  saveCompany() {
    this.currentStep++;
    if (this.currentStep == 1 && this.companyForm.invalid) {
      this.checkCompany = true
      this.currentStep--;
      this.toastr.info('Favor llenar todos los campos requeridos.', 'warning');
      return;
    } else {
      this.saveCompanyData();
      this.checkCompany = false;
    }
  }
  saveUserProfile() {
    this.clickEvent = "Click";
    this.action = "Save";
    this.objectType = "Button";
    this.objectName = "Guardar";
    this.integrateAuditApi();
    this.message = "";
    this.currentUserStep = 0;
    this.currentUserStep++;
    if (this.currentUserStep == 1 && this.userForm.invalid) {
      this.checkValidation = true
      this.currentUserStep--;
      this.toastr.info('Favor llenar todos los campos requeridos.', 'warning');
      return;
    } else {
      this.saveUserData();
      this.checkValidation = false;
    }
  }
  onSelectedValue(selected) {
    this.RolEmpresa = selected.value
  }
  saveCompanyData() {
    let payload = {
      // userId: this.userId,
      Country: this.companyForm.value.PaisEmpresa,
      RNC: this.companyForm.value.RNC,
      companyCode: this.companyCode,
      UserRole: this.RolEmpresa,
      // Company: this.companyName,
      Company: this.companyForm.value.Empresa,
      Status: "Solicitado",
    }
    this.clickEvent = "Click";
    this.action = "Save";
    this.objectType = "Button";
    this.objectName = "Guardar";

    this.clientService.addCompany(payload).subscribe(response => {
      this.modalService.dismissAll();
      this.getCompanies();
      this.empresaSuccessModal();
      this.sendMailToCompanyUser(payload);
      this.integrateAuditApi();
    }
      , (error) => {
        this.modalService.dismissAll();
        this.toastr.error('Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com');
      })
  }
  integrateAuditApi() {
    this.powerBIService.createPayload(this.screenId, this.module, this.clickEvent, this.action, this.objectType, this.objectName, this.refNumber, null, null);
  }
  sendMailToCompanyUser(Companydata) {
    let payload = {
      "useremailId": this.email,
      "adminemail": '',
      "role": Companydata.UserRole,
      "company": Companydata.Company,
      "UserName": this.fullname
    }

    this.clientService.sendMailToCompanyUser(payload).subscribe((response) => {
    }, (error) => {
      this.toastr.error('Ha ocurrido un error, favor intente de nuevo. ');
    })
  }
  saveUserData() {
    //  let  payload = {
    //     //  UserId: this.userId,
    //     Title: this.userForm.value.Title,
    //     Name: this.userForm.value.Nombre,
    //     LastName: this.userForm.value.Apellido,
    //     BirthdayDate: this.userForm.value.Fecha,
    //     CellPhone: this.userForm.value.CelTelephone,
    //     Country: this.userForm.value.Pais,
    //     Email: this.email,
    //     DocumentId: '',
    //     FileName: this.fileName,
    //     FilePath: '',
    //     DateTime: new Date()
    //   }
    let payload = new FormData();
    payload.append('Title', this.userForm.value.Title);
    payload.append('Name', this.userForm.value.Nombre);
    payload.append('LastName', this.userForm.value.Apellido);
    payload.append('BirthdayDate', this.userForm.value.Fecha);
    payload.append('CellPhone', this.userForm.value.CelTelephone);
    payload.append('Country', this.userForm.value.Pais);
    payload.append('Email', this.email);
    payload.append('DocumentId', '');
    payload.append('FileName', this.fileName);
    payload.append('FilePath', '');
    payload.append('DateTime', this.dateTime);
    payload.append('profilePhoto', this.files)

    this.clientService.saveUserDetails(payload).subscribe(response => {
      this.modalService.dismissAll();
      this.openSuccessModal();
      this.getUserProfile();
    }
      , (error) => {
        this.modalService.dismissAll();
        this.toastr.error('Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com');
      })
  }
  saveDirectionData() {
    if (this.address) {
      let payload = {
        //  UserId: this.userId,
        Id: this.address.Id,
        Title: this.directionForm.value.DirTitle,
        Country: this.directionForm.value.PaisDirection,
        Condition: this.directionForm.value.Estado,
        Town: this.directionForm.value.Ciudad,
        PostalCode: this.directionForm.value.Cpostal,
        Direction: this.directionForm.value.Direccion,
      }
      this.clientService.updateAddress(payload).subscribe(response => {
        this.modalService.dismissAll();
        this.updateSuccessModal();
        this.getUserAddress();
      }
        , (error) => {
          this.modalService.dismissAll();
          this.toastr.error('Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com');
        })
    } else {
      let payload = {
        //  UserId: this.userId,
        Title: this.directionForm.value.DirTitle,
        Country: this.directionForm.value.PaisDirection,
        Condition: this.directionForm.value.Estado,
        Town: this.directionForm.value.Ciudad,
        PostalCode: this.directionForm.value.Cpostal,
        Direction: this.directionForm.value.Direccion,
      }
      this.clientService.addAddress(payload).subscribe(response => {
        this.modalService.dismissAll();
        this.directionSuccessModal();
        this.getUserAddress();
      }
        , (error) => {
          this.modalService.dismissAll();
          this.toastr.error('Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com');
        })
    }
    this.address = '';

  }
  deleteAddress(id) {
    this.clientService.deleteAddress(id).subscribe(data => {
      this.toastr.success("Dirección eliminada con éxito")
      this.getUserAddress();
    }, (error) => {
      this.toastr.error('Ha ocurrido un error, favor intente de nuevo. ');
    })
  }
  editAddress(address: any) {
    this.address = address;
    this.openDirectionModel();
    this.patchAdddressData(address);
  }
  getCompanies() {
    this.clientService.getUserCompany().subscribe(response => {
      this.responseList = response;
      response = this.responseList.reverse();
      this.responseList = response;
      this.getusercompanies();
      this.cd.detectChanges();
    })
  }
  getUserProfile() {
    this.clientService.getUserProfile().subscribe((response: any) => {
      this.cd.detectChanges();
      this.userList = response.result;
      if (this.userList === null) {
        this.userForm.controls.Email.setValue(this.email);
        this.selectedPais = "Dominican Republic";
      } else if (this.userList !== null) {
        // this.userId = response[0].UserId;
        this.patchProfileData();
        this.getdocumentName(response);
        this.cd.detectChanges();
      }
      if (!response.ImageResult) {
        this.viewImageSrc = '../../../assets/img/avatar.png'
      }
    })
  }

  getdocumentName(response) {
    if (response.ImageResult) {
      this.viewImage = document.getElementById('imageIcon') as HTMLInputElement;
      let objectURL = 'data:image/png;base64,' + response.ImageResult;
      this.viewImageSrc = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      this.files = this.dataURLtoFile('data:image/png;base64,' + response.ImageResult, 'hello.txt');
    }
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }


  patchProfileData() {
    this.userForm.controls.Title.setValue(this.userList.Title);
    this.userForm.controls.Nombre.setValue(this.userList.Name);
    this.userForm.controls.Apellido.setValue(this.userList.LastName);
    this.userForm.controls.Email.setValue(this.userList.Email);
    this.userForm.controls.CelTelephone.setValue(this.userList.CellPhone);
    this.selectedPais = this.userList.Country;
    this.userForm.controls.Pais.setValue(this.userList.Country);
    this.fileName = this.userList.FileName;
    // this.birhdate = moment(this.userList.BirthdayDate).format("YYYY MMM DD");
    this.userForm.controls.Fecha.setValue(this.userList.BirthdayDate);
    this.cd.detectChanges();
  }
  getCountries() {
    this.clientService.getCountry().subscribe((response: any) => {
      //this.coutryList = response
      const List = response.map((data) => {
        return { id: data.Id, text: data.name, ...data }
      });
      this.coutryList = List;
      let index = this.coutryList.findIndex(item => item.name == "Dominican Republic")
      if (index >= 0) {
        this.coutryList.splice(index, 0);
      }
      this.sortByProp()
      this.cd.detectChanges();
    })
  }
  getUserAddress() {
    this.clientService.getUserAddress().subscribe(response => {
      this.addressList = response;
      response = this.addressList.reverse();
      this.addressList = response;
      this.cd.detectChanges();
    })
  }
  clearValue(rnc) {
    if (rnc && rnc.length == 0) {
      this.companyForm.controls.Empresa.setValue('');
    }
  }


  preview(files) {
    this.viewImage = document.getElementById('imageIcon') as HTMLInputElement;
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.toastr.error('Solo se admiten imágenes.');
      return;
    } else {
      this.message = "";
      var reader = new FileReader();
      this.imagePath = files;
      reader.readAsDataURL(files[0]);
      reader.onload = (_event) => {
        this.imgURL = reader.result;
        this.viewImage.src = this.imgURL;
      }
      this.fileName = files[0].name;
      this.files = files[0];
    }

    this.cd.detectChanges();

  }
  getSearchCompanies(rnc) {
    this.clickEvent = "Click";
    this.action = "Search";
    this.objectType = "Search Category";
    this.objectName = "Buscar";
    this.refNumber = rnc;
    this.integrateAuditApi();
    this.rnc = false;
    if (rnc.length == 0) {
      this.companyForm.controls.Empresa.setValue('');
    } else if (rnc.length >= 3) {
      this.clientService
        .getCompanySearch(rnc).subscribe((data) => {
          this.companyList = data;
          if (this.companyList.length > 0) {
            this.rnc = true;
          } else if (this.companyList.length === 0) {
            this.companyForm.controls.Empresa.setValue('');
          }
        },
          (error) => {
            this.rnc = false;
            this.companyForm.controls.RNC.setValue('');
            this.companyForm.controls.Empresa.setValue('');
          });
      this.cd.detectChanges();
    }
  }
  onClick(company: any) {
    this.rnc = false;
    this.companyForm.controls.Empresa.setValue(company.name);
    this.companyCode = company.id;
    // this.companyName = company.name;
    this.companyForm.controls.RNC.setValue(company.rnc);
  }
  selectedEmpresaCountry(name) {
    this.selectedEmpresaPais = name;
    const input = <HTMLInputElement>document.getElementById('empresa');
    if (this.selectedEmpresaPais == '26b5c993-bf78-4216-8eb5-4e377c6665ef' || this.selectedEmpresaPais == 'Dominican Republic') {
      this.paisFlag = true;
      input.disabled = true;
      this.companyForm.controls.Empresa.setValue('')
      this.companyForm.controls.RNC.setValue('')
    } else {
      this.paisFlag = false;
      input.disabled = false;
      this.companyForm.controls.Empresa.setValue('')
      this.companyForm.controls.RNC.setValue('')
      this.companyCode = '';
    }
  }
  selectedCountry(name) {
    this.selectedPais = name;
  }
  onPaisSelected(name) {
    this.selDirPais = name;
  }
  patchAdddressData(address) {
    this.directionForm.controls.DirTitle.setValue(address.Title);
    this.directionForm.controls.PaisDirection.setValue(address.Country);
    this.selDirPais = address.Country;
    this.directionForm.controls.Estado.setValue(address.Condition);
    this.directionForm.controls.Ciudad.setValue(address.Town);
    this.directionForm.controls.Cpostal.setValue(address.PostalCode);
    this.directionForm.controls.Direccion.setValue(address.Direction)
    this.cd.detectChanges();
  }

  getSortOrder1() {
    let prop = 'name';
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      }
      else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    }
  }

  sortByProp() {
    this.coutryList = this.coutryList.sort(this.getSortOrder1());
  }

  getusercompanies() {
    this.clientService.getAssignCompany().subscribe(response => {
      this.assignedcompany = response;
      this.assignedcompany.forEach(element => {
        let index = this.responseList.findIndex(item => item.RNC == element.rnc)
        if (index >= 0) {
          this.responseList.splice(index, 1);
        }
      });
      this.cd.detectChanges();
    })
  }


}
