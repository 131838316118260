import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';

@Injectable({
    providedIn: 'root'
})

export class orderInProgressService {
    
    private subject = new Subject<any>();

    sendData(message: {}) {
        this.subject.next(message);
    }
 
    getData(): Observable<any> {
        return this.subject.asObservable();
    }

}