import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { AuthService } from '../../../core/auth/_services/auth.service';
import { Router } from '@angular/router'
import Swal from 'sweetalert2'
import { LoginService } from '../../../services/login/login.service';
import { ToastrService } from 'ngx-toastr';
import { PowerBILogService } from '../../../services/log/powerBILog.service';
import { Screen } from '../../../models/enums/screen.enum';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.css',
    '../login/css/bootstrap.min.css',
    '../login/css/bootstrap.min.2.css',
    '../login/css/bootstrap.min.3.css',
    '../login/css/fonts.css',
    '../login/css/icons.css',
    '../login/css/media.css',
    '../login/css/root.css'],
})
export class PasswordRecoveryComponent implements OnInit {
  userFormGroup: FormGroup
  submitted = false
  errorMessage: string
  hasError: boolean
  hasMessage: boolean
  message: string
  isMobileClient = false
  ready = false;
  public showError: boolean = false;
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
  public module: any;
  public objectType: any;
  public objectName: any;
  public clickEvent: any;
  public action: any;
  public screenId: any;
  public refNumber: any;
  public refType: any;
  public subModule: any;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private route: Router,
    private loginService: LoginService,
    private toastr: ToastrService,
    private powerBIService: PowerBILogService
    // private platformService: PatformService
  ) { }

  private showErrorMessage(message: string) {
    this.errorMessage = message
    this.hasError = true
  }

  private async changePassword(email: string) {
    try {
      let message = await this.authService.changePassword(email)

      Swal.fire({
        title: '',
        text: message,
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok!',
      }).then((result) => {
        if (result.value) {
          this.route.navigateByUrl('/login', {
            skipLocationChange: true,
          })
        }
      })
    } catch (error) {
      this.showErrorMessage(error)
    }
  }

  checkEmailExit(email) {
    this.loginService.checkEmailExit(email).subscribe((res: any) => {
      if (res == 'True') {
        this.changePassword(email)
      } else {
        Swal.fire({
          title: '',
          text: 'No existe cuenta de MARDOM GO con el correo proporcionado. Favor ingresar un correo con cuenta existente',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        }).then((result) => {

        })
        //this.toastr.warning("Aviso: No existe cuenta de MARDOM GO con el correo proporcionado. Favor ingresar un correo con cuenta existente")
      }
    }, (error) => {

    }
    )
  }

  ngOnInit() {
    // this.isMobileClient =
    //   this.platformService.getPlatformType() === Platforms.Mobile
    this.userFormGroup = this.formBuilder.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.maxLength(120),
          Validators.pattern(this.emailPattern),
        ]),
      ],
    });

    this.screenId = Screen.Bienvenida;
    this.module = 'Bienvenida';
    this.clickEvent = "Click";
    this.action = "Redirect";
    this.objectType = "Button";
    this.objectName = "Olvidaste Tu Contrasena";
    this.subModule = "Olvidaste Tu Contrasena"
    this.integrateAuditApi();
  }

  integrateAuditApi() {
    this.powerBIService.createPayload(this.screenId, this.module, this.clickEvent, this.action, this.objectType, this.objectName, this.refNumber, this.refType, this.subModule);
  }


  get formControl() {
    return this.userFormGroup.controls
  }
  hideErrorMessage() {
    this.hasError = false
    this.showError = false
  }
  hideMessage() {
    this.hasMessage = false
  }

  onSubmit() {
    this.submitted = true

    if (this.userFormGroup.invalid) {
      // this.showError = true
      return
    } else {
      const userEmail = this.userFormGroup.value as { email }
      this.changePassword(userEmail.email);
      // this.checkEmailExit(userEmail.email)
    }
    this.screenId = Screen.Recuperarcontrasena;
    this.module = 'Recuperar Contrasena';
    this.clickEvent = "Click";
    this.action = "Save";
    this.objectType = "Button";
    this.objectName = "RecuperarContrasena";
    this.refNumber = this.userFormGroup.controls.email.value;
    this.refType = 'Email';
    this.subModule = null;
    this.integrateAuditApi();
  }


}
