import { Component, OnInit } from '@angular/core';
import { OutboxService } from '../../services/miscelaneos/outbox.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { OutboxModel } from '../../models/outbox';
import { LogService } from '../../services/log/log.service';
import { LogDto } from '../../models/dto/log.dto';
import { Action } from '../../models/enums/action.enum';
import { Screen } from '../../models/enums/screen.enum';
import { select, Store } from '@ngrx/store';
import { selectAuthState } from '../../core/auth/_selectors/auth.selectors';
import { AppState } from '../../core/reducers';
import * as jwt_decode from 'jwt-decode';
import { PowerBILogService } from '../../services/log/powerBILog.service';

@Component({
  selector: 'kt-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

  messageForm: FormGroup;
  outboxModel: OutboxModel;
  payload$: any;
  public module: any;
  public objectType: any;
  public objectName: any;
  public clickEvent: any;
  public action: any;
  public screenId: any;

  constructor(private outboxService: OutboxService, private toastr: ToastrService,
    private activeRoute: ActivatedRoute,
    private fb: FormBuilder,
    private logService: LogService,
    private store: Store<AppState>,
    private router: Router,
    private powerBIService: PowerBILogService) { }

  showSuccess(message) {
    this.toastr.success(message, 'Confirmación.');

    this.router.navigate(['/panel'])
  }

  showError(message) {
    //'Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com'
    this.toastr.error(message, 'Error en envio de correo.');
  }

  ngOnInit() {
    this.payload$ = this.store.pipe(select(selectAuthState));
    this.messageForm = this.fb.group({
      message_type: 0,
      service_type: 0,
      title: '',
      message: ''
    });
    this.screenId = Screen.EnvioMensaje;
    this.module = 'EnvioMensaje';
    this.clickEvent = "Click";
    this.action = "Redirect";
    this.objectType = "Button";
    this.objectName = "Envio Mensaje";
    this.integrateAuditApi();

  }

  integrateAuditApi() {
    this.powerBIService.createPayload(this.screenId, this.module, this.clickEvent, this.action, this.objectType, this.objectName, null, null, null);
  }

  sendMessage() {
    this.clickEvent = "Click";
    this.action = "Save";
    this.objectType = "Button";
    this.objectName = "Enviar Mensaje";
    this.integrateAuditApi();
    //this.searchForm.value.dateFrom,this.searchForm.value.dateTo,
    this.outboxModel = new OutboxModel();
    this.outboxModel.message_type = this.messageForm.value.message_type;
    this.outboxModel.service_type = this.messageForm.value.service_type;
    this.outboxModel.title = this.messageForm.value.title;
    this.outboxModel.message = this.messageForm.value.message;

    if (this.outboxModel.message_type == '0' || this.outboxModel.service_type == '0' || this.outboxModel.title == '' || this.outboxModel.message == '') {
      this.toastr.info('Debe completar los campos requeridos (*)')
      return;
    }

    this.outboxService.addMessage(this.outboxModel).subscribe(res => {

      this.showSuccess("Mensaje enviado satisfactoriamente!!");
    }, (error) => {
      this.showError('Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com');
    })
  }
}
